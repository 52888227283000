import { getUserByToken, useAuth } from "../../modules/auth";
import { createContext, useContext } from "react";
import api, { form_api } from "./index";

const ApiContext = createContext({
    // Valores padrão pro contexto. Não da pra jogar as funcionalidades aqui direto por causa do useAuth()
    GET: (url, logoutOnFail = true) => {},
    POST: (url, data, logoutOnFail = true) => {},
    DELETE: (url, logoutOnFail = true) => {},
    PUT: (url, data, logoutOnFail = true) => {},
    setUserDataOnContext: (apiToken, refreshToken = "") => {},
});

const useApi = () => {
    // Definição do Hook
    return useContext(ApiContext);
};

/**
 * Custom provider de API pra modularizar o comportamento padrão de:
 *   0. Inserir o id do cliente atual em todas as requisições, e o id do artista selecionado também (caso haja)
 *   1. Tentar fazer uma requisicao com o axios;
 *   2. Se falhar porque o token expirou, tenta refreshar o token;
 *   3. Se a acao de refreshar funfar, refazer a requisição. Caso contrário, redirecionar pra login.
 * Apesar de ser um provider só de Hook, ele é necessário porque precisamos do useAuth().
 */
const ApiProvider = ({ children }) => {
    const {
        auth,
        saveAuth,
        currentClient,
        currentArtist,
        setCurrentUser,
        setCurrentClient,
        setCurrentArtist,
    } = useAuth();

    const datalessRequest = async (requestFunc, url, logoutOnFail) => {
        let headers = {
            Authorization: `Bearer ${auth?.api_token}`,
        };
        if (currentClient) headers.Manager = currentClient.manager_id;
        if (currentArtist) headers.Artist = currentArtist.id;
        try {
            return await requestFunc(url, {
                headers: headers,
            });
        } catch (err) {
            if (err.response?.status === 401) {
                try {
                    let form = new FormData();
                    form.append("refresh", auth?.refreshToken);
                    let refreshResponse = await form_api.post(
                        "auth/login/refresh/",
                        form
                    );
                    saveAuth({
                        ...auth,
                        api_token: refreshResponse.data.access,
                    });
                    return await requestFunc(url, {
                        headers: {
                            Authorization: `Bearer ${auth?.api_token}`,
                        },
                    });
                } catch (err) {
                    console.error(err);
                    return logoutOnFail
                        ? window.location.replace("/logout")
                        : undefined;
                }
            } else {
                console.error(err);
                throw err;
            }
        }
    };

    const dataRequest = async (requestFunc, url, data, logoutOnFail) => {
        let headers = {
            Authorization: `Bearer ${auth?.api_token}`,
        };
        if (currentClient) headers.Manager = currentClient.manager_id;
        if (currentArtist) headers.Artist = currentArtist.id;
        try {
            return await requestFunc(url, data, {
                headers: headers,
            });
        } catch (err) {
            if (err.response.status === 401) {
                try {
                    let form = new FormData();
                    form.append("refresh", auth?.refreshToken);
                    let refreshResponse = await form_api.post(
                        "auth/login/refresh/",
                        form
                    );
                    saveAuth({
                        ...auth,
                        api_token: refreshResponse.data.access,
                    });
                    return await requestFunc(url, {
                        headers: {
                            Authorization: `Bearer ${auth?.api_token}`,
                        },
                    });
                } catch (err) {
                    console.error(err);
                    return logoutOnFail
                        ? window.location.replace("/logout")
                        : undefined;
                }
            } else {
                console.error(err);
                throw err;
            }
        }
    };

    const GET = async (url, logoutOnFail = true) => {
        return await datalessRequest(api.get, url, logoutOnFail);
    };

    const POST = async (url, data, logoutOnFail = true) => {
        return await dataRequest(form_api.post, url, data, logoutOnFail);
    };

    const DELETE = async (url, logoutOnFail = true) => {
        return await datalessRequest(api.delete, url, logoutOnFail);
    };

    const PUT = async (url, data, logoutOnFail = true) => {
        return await dataRequest(form_api.put, url, data, logoutOnFail);
    };

    /**
     * Coloca os dados referentes a usuário, cliente, parceiro e artista no contexto. Fazia mais sentido essa funcao
     * ficar no AuthProvider, mas deu dependencia circular, porque sao necessarias as funcoes setCurrentUser,
     * setCurrentClient e setCurrentArtist que estao definidas lá, e o GET que está definido cá. Foi onde deu pra por.
     * @param {String} apiToken token JWT pra pegar o usuário no servidor
     * @param {String} refreshToken token de refresh JWT pra obter novos tokens de acesso
     * @returns {Promise<void>}
     */
    const setUserDataOnContext = async (apiToken, refreshToken = "") => {
        // A função getUserByToken pega o usuário, parceiros e artistas disponíveis do usuário. Caso o perfil não
        // esteja completo, redireciona pra completar perfil.
        const userResponse = await getUserByToken(apiToken, refreshToken);
        setCurrentUser(userResponse.user);
        // setAvailableClients(userResponse.availableClients);
        setCurrentClient(userResponse.selectedPartner);
        let selectedArtist;
        if (!userResponse.selectedPartner.manager_is_recorder) {
            let headers = {
                Authorization: `Bearer ${apiToken}`,
                Manager: userResponse.selectedPartner.manager_id,
            };
            const artistsResponse = await api.get("/core/artists", {
                headers: headers,
            });
            const artist = artistsResponse.data[0];
            selectedArtist = {
                id: artist.id,
                name: artist.name,
                avatar: artist.profile_picture,
                type: artist.get_type_display,
                appOniHolderId: artist.app_oni_id,
                slug: artist.invitation_page_slug,
                spotifyId: artist.spotify_id,
                healthcheckCompletion: artist.healthcheck_completion,
                canCreateOnesheet: artist.can_create_onesheet,
                active: true,
                facebook_long_lived_token: artist.facebook_long_lived_token,
                google_refresh_token: artist.google_refresh_token,
            };
            setCurrentArtist(selectedArtist);
        } else {
            selectedArtist = JSON.parse(localStorage.getItem("currentArtist"));
        }
        if (selectedArtist) setCurrentArtist(selectedArtist);
    };

    return (
        <ApiContext.Provider
            value={{ GET, POST, DELETE, PUT, setUserDataOnContext }}
        >
            {children}
        </ApiContext.Provider>
    );
};

export { ApiProvider, useApi };
