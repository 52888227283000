import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "@nextui-org/react";
import CountUp from "react-countup";
import { TidalLogo } from "../../../../../components/TidalLogo";
import styles from "./FeaturedSocialNetwork.module.css";
import { SocialUtils } from "../../../../../helpers/utils/socialUtils";

/**
 * @description
 * Componente para exibir (ou não) informações de uma rede social.
 *
 * @param {String} featuredCode - Código identificador da DSP destacada.
 * @param {object} data - Dados das redes sociais que foram preenchidos no formulário.
 * @param {string[]} color - Um array de duas strings representando as cores dos ícones e do texto.
 * @returns {JSX.Element} O componente que exibe as informações do Spotify.
 */

export const FeaturedSocialNetwork = ({ featuredCode, data, color }) => {
    return (
        <div className={styles.featuredSocialNetworkWrapper}>
            <header className={styles.header}>
                {featuredCode === "TD" ? (
                    // Foi feito dessa forma pq não tem esse logo no fontawesome.
                    <TidalLogo color={color[1]} />
                ) : (
                    <FontAwesomeIcon
                        color={color[1]}
                        icon={data[0]?.icon}
                        size="2xl"
                    />
                )}
                <Text h1 className={styles.featured} css={{ color: color[1] }}>
                    {data[0]?.name}
                </Text>
            </header>
            <div className="flex gap-10">
                {data.map((number, index) => (
                    <section key={index} className={styles.section}>
                        <Text
                            className={styles.featured}
                            css={{ color: color[0], fontWeight: "bold" }}
                        >
                            {" "}
                            <CountUp
                                end={number.numbers}
                                separator="."
                                duration={2.75}
                            />{" "}
                        </Text>
                        <Text b css={{ color: color[0], m: 0 }}>
                            {number.label}
                        </Text>
                    </section>
                ))}
            </div>
        </div>
    );
};
