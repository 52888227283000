import { InviteFormFields } from "../../invites/inviteFormFields";
import { Container } from "@nextui-org/react";
import { useAuth } from "../../auth";

/**
 * Prévia para o elemento Hero do Onesheet
 * @param {object} data informações necessárias pra renderização da prévia
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetInviteFormPreview = ({ data }) => {
    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data.background,
            }}
        >
            <InviteFormFields
                title={data.title}
                subtitle={data.subtitle}
                color1={data.fontColor}
                color2={data.secondary_color}
                bgColor={data.background}
            />
        </Container>
    );
};

export { OnesheetInviteFormPreview };
