import { useEffect } from "react";
import SpotifyWebApi from "spotify-web-api-js";
import { getSpotifyTokenFromUrl } from "../../../helpers/utils/socialLoginUtils";
import { useAuth } from "../utils/AuthProvider";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { toast } from "react-toastify";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { customGetCookie } from "../../../helpers/usefulFunctions";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

/**
 * Handler de callback de conexão com spotify. Salva o id e token obtidos do usuário no BD e retorna pra página que fez
 * a chamada
 * @returns {JSX.Element}
 */
const SpotifyCallbackHandler = () => {
    const spotify = new SpotifyWebApi();
    const { currentUser } = useAuth();
    const { PUT } = useApi();
    const { t } = useTranslate();

    /**
     * Atualiza o objeto usuário no backend com os dados obtidos do spotify
     * @param {String} id id spotify do usuário
     * @returns {Promise<void>}
     */
    const updateUser = async (id) => {
        try {
            let formData = new FormData();
            formData.append("id", currentUser.id);
            formData.append("spotify_id", id);
            // Faz a chamada pra atualizar o usuário no BD
            await PUT(`core/user-spotify-data/${currentUser.id}`, formData);
            // Redireciona de volta pra página que iniciou o processo
            window.location.replace(
                customGetCookie("socialLoginSourceLocation")
            );
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ERROR-PAGE.TITLE"));
        }
    };

    /**
     * Ao carregar a página, tenta obter os dados do usuário do spotify
     */
    useEffect(() => {
        const _spotifyToken = getSpotifyTokenFromUrl().access_token;
        if (_spotifyToken) {
            // Limpa o hash
            window.location.hash = "";
            // Seta o token de acesso pra conseguirmos fazer as próximas chamadas
            spotify.setAccessToken(_spotifyToken);
            // Faz a chamada pra pegar dados do usuário
            spotify.getMe().then((user) => updateUser(user.id));
        }
    }, []);
    return <LoadingSpinner size="2xl" />;
};

export { SpotifyCallbackHandler };
