import { Text } from "@nextui-org/react"
/**
 * Componente de Título.
 *
 * Este componente exibe um título com a cor e o texto especificados.
 *
 * @param {string} color - A cor do título.
 * @param {string} text - O texto a ser exibido como título.
 * @returns {JSX.Element} - O componente de título.
 */
export const Title = ({color, text}) => {
    return (
        <Text
            h2
            color={color}
            css={{
                fs: '36px',
                fontWeight: 'bold'
            }}
        >
            {text}
        </Text>
    )
}