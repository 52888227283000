import React from "react";
import { FeaturedSocialNetwork } from "./FeaturedSocialNetwork";
import styles from "./Social.module.css";
import { SocialUtils } from "../../../../../helpers/utils/socialUtils";
import { SocialNumber } from "../../../../../components/SocialNumber";

/**
 * @description
 * componente que mostra os dados das redes sociais e quantidade de seguidores.
 *
 * @param {string[]} colors - Um array com as cores para personalizacao dos icones e texto.
 * @param {object} data - Dados das redes sociais que foram preenchidos no formulário.
 * @returns {JSX.Element} - componente JSX com as informações.
 */

export const Social = ({ colors, data }) => {
    const socialData = SocialUtils({ data: data, colors: colors });

    // rede social em destaque
    const featured = data?.featured_social;

    // Filtra as entradas do objeto socialData para remover o item com a chave igual a 'featured'.
    const updatedSocial = Object.entries(socialData).filter(
        ([key]) => key !== featured
    );

    // Converte as entradas filtradas de volta em um objeto.
    const updatedSocialObject = Object.fromEntries(updatedSocial);

    // adiciona as chaves em um array para fazer o map depois
    const updatedSocialArray = [].concat(...Object.values(updatedSocialObject));

    /**
     * Formata um número para ficar mais legível.
     *
     * @param {string|number} number - O número que vai ser formatado.
     * @returns {string} O número formatado, com abreviações como 'Mi' para milhões e 'K' para milhares.
     */
    function formatNumber(number) {
        const toNumber = +number; //transforma a string em número
        if (toNumber >= 1000000) {
            return (toNumber / 1000000).toFixed(2) + " Mi";
        } else if (toNumber >= 1000) {
            return (toNumber / 1000).toFixed(1) + "K";
        } else {
            try {
                return toNumber.toString();
            } catch {
                return number;
            }
        }
    }

    return (
        <>
            {/* Rede Social em Destaque */}
            {featured && featured !== "NN" ? (
                <FeaturedSocialNetwork
                    featuredCode={featured}
                    data={socialData[featured]}
                    color={colors}
                />
            ) : null}

            <div className={styles.socialWrapper}>
                {updatedSocialArray.map((social, index) => {
                    return (
                        <>
                            {social.show_numbers ? (
                                <SocialNumber
                                    key={index}
                                    title={social.name}
                                    className={styles.socialArea}
                                    name={social.name}
                                    iconColor={colors[1]}
                                    textColor={colors[0]}
                                    icon={social.icon}
                                    number={formatNumber(social.numbers)}
                                    label={social.label}
                                />
                            ) : null}
                        </>
                    );
                })}
            </div>
        </>
    );
};
