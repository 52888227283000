import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useLayout } from "../../../core/LayoutProvider";
import { usePageData } from "../../../core/PageData";
import { useTranslate } from "../../../../helpers/i18n/useTranslate";

const DefaultTitle: FC = () => {
    const {
        pageTitle,
        pageDescription,
        translateTitle,
        pageBreadcrumbs,
        userName,
    } = usePageData();
    const { config, attributes } = useLayout();
    const { t } = useTranslate();
    return (
        <div
            {...attributes.pageTitle}
            className="page-title d-flex flex-column"
        >
            {/* begin::Title */}
            {pageTitle && (
                <h1
                    className={
                        "page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-lg-2x gap-2"
                    }
                >
                    <span className="text-white ">
                        <span className="fw-light">
                            {String(translateTitle ? t(pageTitle) : pageTitle)}
                        </span>
                        {userName && <>, {userName}</>}
                    </span>
                    {pageDescription &&
                        config.pageTitle &&
                        config.pageTitle.description && (
                            <>
                                {/*<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>*/}
                                <span className="page-desc text-white fs-base fw-semibold">
                                    {String(
                                        translateTitle
                                            ? t(pageDescription)
                                            : pageDescription
                                    )}
                                </span>
                            </>
                        )}
                </h1>
            )}
            {/* end::Title */}

            {pageBreadcrumbs &&
                pageBreadcrumbs.length > 0 &&
                config.pageTitle &&
                config.pageTitle.breadCrumbs && (
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1">
                        {Array.from(pageBreadcrumbs).map((item, index) => (
                            <li
                                className={
                                    "breadcrumb-item text-white opacity-75"
                                }
                                key={`${item.path}${index}`}
                            >
                                {!item.isSeparator ? (
                                    <Link
                                        className="text-white text-hover-primary"
                                        to={item.path}
                                    >
                                        {String(
                                            translateTitle
                                                ? t(item.title)
                                                : item.title
                                        )}
                                    </Link>
                                ) : (
                                    <span className="bullet bg-white opacity-75 w-5px h-2px"></span>
                                )}
                            </li>
                        ))}
                        <li className="text-white text-hover-primary breadcrumb-item">
                            {String(
                                pageTitle && translateTitle
                                    ? t(pageTitle)
                                    : pageTitle
                            )}
                        </li>
                    </ul>
                )}
        </div>
    );
};

export { DefaultTitle };
