import { Container, Grid, Image } from "@nextui-org/react";
import { Title } from "../Components/Title";
import { Subtitle } from "../Components/Subtitle";
import styles from "./OnesheetBioPreview.module.css";

/**
 * Prévia para o elemento Bio do Onesheet
 * @param {object} data informações necessárias pra renderização da prévia
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetBioPreview = ({ data }) => {
    return (
        <div className={styles.wrapper}>
            <Container
                id={data.id}
                xl
                css={{
                    p: "9rem 6rem",
                    bc: data?.background,
                }}
            >
                <Grid.Container
                    gap={2}
                    css={{ mw: "1300px", m: "0 auto" }}
                    className={styles.bio}
                >
                    <Grid xs={12} direction="column" css={{ w: "100%" }}>
                        <Title color={data?.fontColor} text={data?.title} />
                        <Subtitle
                            color={data?.secondary_color}
                            text={data?.subtitle}
                        />

                        <div className={styles.bioContent}>
                            <div
                                className={styles.bioText}
                                dangerouslySetInnerHTML={{
                                    __html: data?.value,
                                }}
                            />

                            {data?.extraContent ? (
                                <Image
                                    showSkeleton
                                    width={"100%"}
                                    className={styles.bioImg}
                                    src={data?.extraContent}
                                />
                            ) : null}
                        </div>
                    </Grid>
                </Grid.Container>
            </Container>
        </div>
    );
};

export { OnesheetBioPreview };
