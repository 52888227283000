import React from 'react';

/**
 *@description
 * Um componente que renderiza o logotipo da Tidal em formato de SVG. 
 * Foi feito dessa forma pq não tem esse logo no fontawesome.
 * 
 * @param {string} color - A cor de preenchimento do logotipo em formato hexadecimal ou nome de cor CSS.
 * @returns {JSX.Element} O componente TidalLogo que renderiza o logotipo da Tidal com a cor especificada.
 */

export const TidalLogo = ({color}) => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="17"
          fill="none"
          viewBox="0 0 25 17"
        >
          <path
            fill={color}
            fillRule="evenodd"
            d="M20.9 0c0 .1.1.1.1.2L24.8 4c.1.1.1.1.2.1l-4.2 4.2-4.1-4.1-4.2 4.2-4.2-4.2-4.1 4.1L0 4.1.1 4 3.9.2C4 .1 4 .1 4 0c0 0 0 .1.1.1l4 4 .1.1.1-.1L12.2.2l.1-.1 4.2 4.2.2-.2L20.6.2c.2-.2.2-.2.3-.2z"
            clipRule="evenodd"
          ></path>
          <path
            fill={color}
            fillRule="evenodd"
            d="M8.4 12.5l4.1-4.1 4.1 4.1-4.1 4.1c-1.4-1.3-2.7-2.7-4.1-4.1z"
            clipRule="evenodd"
          ></path>
        </svg>
    )
}