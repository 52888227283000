import React, { useEffect, useState } from "react";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { observer } from "mobx-react-lite";
import { ProfilePhoto } from "../../../layout/components/ProfilePhoto";
import { useAuth } from "../../auth";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { isNotEmpty } from "../../../helpers/usefulFunctions";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

const ArtistPicker = observer(() => {
    const { GET } = useApi();
    const { t } = useTranslate();
    const [artists, setArtists] = useState([]);
    const [artistsLoading, setArtistsLoading] = useState(true);
    const [filteredArtists, setFilteredArtists] = useState([]);
    const { currentUser, currentClient, currentArtist, impersonateArtist } =
        useAuth();

    /**
     * Deixa o usuário (ou se não for gravadora, o primeiro artista) selecionado
     */

    useEffect(() => {
        if (currentClient.is_global) {
            if (!currentArtist) localStorage.setItem("recorderModeOn", "true");
            else localStorage.removeItem("recorderModeOn");
        } else if (filteredArtists.length > 0 && !currentArtist) {
            const firstArtist = filteredArtists[0];
            impersonateArtist(firstArtist);
        }
    }, [filteredArtists, currentArtist]);

    /**
     * Retorna o artista procurado na barra de pesquisa
     * @param {String} artistName nome do artista buscado
     */
    const searchArtist = (artistName) => {
        setArtistsLoading(true);
        let tempArtists = artists.filter(
            (artist) => !artist.name.search(new RegExp(artistName, "i"))
        );
        setFilteredArtists(tempArtists);
        setArtistsLoading(false);
    };

    /**
     * Carrega os artistas disponíveis para o filtro por artista
     * @returns {Promise<void>}
     */
    const getArtists = async () => {
        setArtistsLoading(true);
        try {
            const artistsResponse = await GET("/core/artists");
            let currentArtistId = currentArtist ? currentArtist.id : NaN;
            let tempArtists = artistsResponse.data.map((artist) => ({
                id: artist.id,
                name: artist.name,
                avatar: artist.profile_picture,
                type: artist.get_type_display,
                type_code: artist.type,
                appOniHolderId: artist.app_oni_id,
                slug: artist.invitation_page_slug,
                spotifyId: artist.spotify_id,
                healthcheckCompletion: artist.healthcheck_completion,
                canCreateOnesheet: artist.can_create_onesheet,
                active: parseInt(currentArtistId) === artist.id,
            }));
            tempArtists.sort((a, b) => (a.active ? -1 : 1));
            setArtists(tempArtists);
            setFilteredArtists(tempArtists);
        } catch (e) {
            toast.error(t("GENERAL.ERROR-PAGE.SUBTITLE"));
            console.error(e);
        } finally {
            setArtistsLoading(false);
        }
    };

    /**
     * Carrega os artistas no renderizar da tela
     */
    useEffect(() => {
        getArtists();
    }, []);

    return (
        <>
            <div className="timeline">
                <div className="timeline-item" style={{ display: "block" }}>
                    <div className="timeline-line w-40px"></div>
                    <div className="timeline-content mb-10 mt-n1 d-flex flex-column">
                        <div className="pe-3 mb-2">
                            <div
                                className="d-flex flex-row"
                                style={{ padding: "20px" }}
                            >
                                <div>
                                    <div className="overflow-auto me-5">
                                        {currentClient?.manager_name}
                                    </div>
                                    <div className="d-flex align-items-center mt-1 fs-6">
                                        <div className="text-muted me-2 fs-7">
                                            {t(
                                                currentClient?.manager_is_recorder
                                                    ? "GENERAL.RECORDER"
                                                    : "FORMS.CORE.PROJECT.ARTIST"
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <input
                                        type="text"
                                        className="search-input form-control form-control-solid ps-5 ms-12"
                                        name="search"
                                        placeholder={`${t(
                                            "GENERAL.SEARCH-BY.ARTIST-NAME"
                                        )}...`}
                                        data-kt-search-element="input"
                                        onChange={(ev) =>
                                            searchArtist(ev.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        {artistsLoading ? (
                            <LoadingSpinner />
                        ) : (
                            <div
                                className="overflow-auto pb-5"
                                style={{ width: "100%" }}
                            >
                                {currentClient.is_global ? (
                                    <div
                                        className={clsx(
                                            "d-flex align-items-center hover:transition-colors hover:bg-gray-600 py-5 mb-1",
                                            {
                                                "bg-gray-600":
                                                    localStorage.getItem(
                                                        "recorderModeOn"
                                                    ) === "true",
                                                "hover:cursor-pointer":
                                                    !localStorage.getItem(
                                                        "recorderModeOn"
                                                    ),
                                            }
                                        )}
                                        style={{
                                            margin: "10px 0",
                                            paddingLeft: "0.8rem",
                                        }}
                                        onClick={() => {
                                            // Liga o modo gravadora, mas apenas se ainda n estiver ligado
                                            if (
                                                !localStorage.getItem(
                                                    "recorderModeOn"
                                                )
                                            ) {
                                                impersonateArtist();
                                            }
                                        }}
                                    >
                                        <div className="symbol-group symbol-hover flex-nowrap min-w-100px ms-5">
                                            <div
                                                className="d-flex gap-3 align-items-center"
                                                style={{
                                                    marginRight: "40px",
                                                }}
                                            >
                                                <div className="symbol symbol-circle symbol-40px">
                                                    {/*Se tiver foto coloca a foto do usuário, se não tiver coloca um círculo com a primeira letra do nome*/}
                                                    {isNotEmpty(
                                                        currentUser.photo
                                                    ) ? (
                                                        <img
                                                            alt="User profile"
                                                            src={
                                                                currentUser.photo
                                                            }
                                                            style={{
                                                                objectFit:
                                                                    "cover",
                                                            }}
                                                        />
                                                    ) : (
                                                        <div className="symbol-label fs-2 fw-bold bg-info text-inverse-info">
                                                            {currentUser
                                                                ? currentUser[
                                                                      "first_name"
                                                                  ]
                                                                    ? currentUser[
                                                                          "first_name"
                                                                      ][0]
                                                                    : ""
                                                                : ""}
                                                        </div>
                                                    )}
                                                </div>

                                                <span
                                                    className={clsx(
                                                        "fs-5 text-dark w-250px",
                                                        {
                                                            "fw-semibold":
                                                                !currentUser.active,
                                                        },
                                                        {
                                                            "fw-bold":
                                                                currentUser.active,
                                                        }
                                                    )}
                                                    // Limita o nome do usuário com ellipsis caso seja muito grande
                                                    style={{
                                                        textOverflow:
                                                            "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        minWidth: "250px",
                                                        marginRight:
                                                            currentUser.active
                                                                ? "40px"
                                                                : "0",
                                                    }}
                                                >
                                                    {currentUser
                                                        ? currentUser[
                                                              "first_name"
                                                          ]
                                                        : ""}
                                                </span>
                                            </div>
                                        </div>
                                        <a
                                            href="#"
                                            className="symbol symbol-circle symbol-40px"
                                        >
                                            {/*Se o usuário tiver selecionado coloca o ícone do lado do nome*/}
                                            {localStorage.getItem(
                                                "recorderModeOn"
                                            ) ? (
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    size={"xl"}
                                                />
                                            ) : null}
                                        </a>
                                    </div>
                                ) : null}
                                {filteredArtists.map((artist, index) => {
                                    return (
                                        <div
                                            key={`ar-${index}`}
                                            className={clsx(
                                                "d-flex align-items-center hover:transition-colors hover:bg-gray-600 py-5 mb-1",
                                                {
                                                    "bg-gray-600":
                                                        currentArtist?.id ===
                                                        artist.id,
                                                    "hover:cursor-pointer":
                                                        currentArtist?.id !==
                                                        artist.id,
                                                }
                                            )}
                                            style={{
                                                margin: "10px 0",
                                                paddingLeft: "0.8rem",
                                            }}
                                        >
                                            <div
                                                className="symbol-group symbol-hover flex-nowrap min-w-100px ms-5"
                                                onClick={() => {
                                                    if (
                                                        currentArtist?.id !==
                                                        artist.id
                                                    )
                                                        impersonateArtist(
                                                            artist
                                                        );
                                                }}
                                            >
                                                <div className="d-flex gap-3 align-items-center">
                                                    {artist.avatar ? (
                                                        <div className="symbol symbol-circle symbol-40px">
                                                            <img
                                                                src={
                                                                    artist.avatar
                                                                }
                                                                alt="img"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="symbol symbol-circle symbol-40px">
                                                            <div className="symbol-label fs-8 fw-semibold bg-success text-inverse-primary">
                                                                {artist.name[0]}
                                                            </div>
                                                        </div>
                                                    )}

                                                    <span
                                                        className={clsx(
                                                            "fs-5 text-dark w-250px",
                                                            {
                                                                "fw-semibold":
                                                                    !artist.active,
                                                            },
                                                            {
                                                                "fw-bold":
                                                                    artist.active,
                                                            }
                                                        )}
                                                        // Limita o nome do artista com ellipsis caso ele seja muito grande
                                                        style={{
                                                            textOverflow:
                                                                "ellipsis",
                                                            whiteSpace:
                                                                "nowrap",
                                                            overflow: "hidden",
                                                            minWidth: "250px",
                                                            marginRight:
                                                                artist.active
                                                                    ? "40px"
                                                                    : "0",
                                                        }}
                                                    >
                                                        {artist.name}
                                                    </span>
                                                </div>
                                            </div>
                                            <a
                                                href="#"
                                                className="symbol symbol-circle symbol-40px"
                                            >
                                                {/*Se artista tiver selecionado coloca o ícone do lado do nome*/}
                                                {artist.active ? (
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        size={"xl"}
                                                    />
                                                ) : null}
                                            </a>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
});

export { ArtistPicker };
