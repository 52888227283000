import React from "react";
import { InfoForFields } from "../../InfoForFields";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import styles from "./InputDefault.module.css";
import { Checkbox, Link } from "next2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";

/**
 * @description
 * Componente que renderiza uma label e um input padrão.
 *
 * @param {string} label - label associada ao input.
 * @param {string} id - O ID do campo de entrada.
 * @param {string} infoForField - descrição sobre o campo.
 * @param {string} type - O tipo do campo de entrada (por exemplo, "text", "password").
 * @param {function} handleChange - Função de retorno de chamada para lidar com a alteração do valor do campo.
 * @param {string} value - O valor atual do campo de entrada.
 * @param {string} className - Classes css para estilização
 * @param {boolean} required - define se o campo é obrigatório.
 * @param {string} helpText - Texto descritivo para o campo (help text).
 * @param {boolean} readonly - define se o campo é obrigatório.
 * @param {boolean} isPassword - informa se o campo é de senha.
 * @param {string} idPassword - id para o checkbox de mostrar senha.
 * @param {boolean} showPassword - toggle para mostrar / ocultar senha.
 * @param {function} setShowPassword - state que lida com o toggle de mostrar / ocultar.
 * @param {string} placeholder - texto de placeholder para o campo.
 * @param {boolean} horizontal - define se o campo é horizontal.
 * @param {string} fieldError - array contendo os campos que estão com erro. pra aparecer o erro, o id do input tem que tá nesse array.
 * @param {string} error - mensagem de erro.
 * @param {number} maxLength - número máximo de caracteres permitidos.
 * @param {recoverPassword} - elemento que redireciona para tela de recuperar login
 * @returns {JSX.Element} O componente de campo de entrada de texto padrão.
 */

export const InputDefault = ({
    horizontal,
    label,
    id,
    infoForField,
    type,
    maxLength,
    handleChange,
    value,
    className,
    required,
    helpText,
    readOnly,
    fieldError,
    error,
    isPassword,
    idPassword,
    showPassword,
    setShowPassword,
    placeholder,
    recoverPassword
}) => {
    const { t } = useTranslate();
    const handlePassword = () => {
        setShowPassword(!showPassword);
    };

    return horizontal ? (
        <div className={`row ${className || ""}`}>
            <label
                htmlFor={id}
                className={`col-lg-4 col-form-label fs-6 ${`${
                    required ? "required" : null
                }`}`}
            >
                {label}
                {infoForField ? (
                    <InfoForFields id={`${id}`} text={infoForField} />
                ) : null}
            </label>
            <div className="col-lg-8 fv-row">
                <input
                    className={
                        "form-control form-control-lg form-control-solid " +
                        (fieldError?.includes(id) && "is-invalid")
                    }
                    required={required}
                    id={id}
                    type={type}
                    maxLength={maxLength}
                    name={id}
                    onChange={handleChange}
                    value={value}
                    readOnly={readOnly}
                    placeholder={placeholder}
                />
                {fieldError?.includes(id) ? (
                    <div className="text-red-500 mt-2">{error}</div>
                ) : null}
                {helpText ? (
                    <div id={`${id}-help`} className="form-text">
                        {helpText}
                    </div>
                ) : null}
                {isPassword ? (
                    <div className="form-check form-check-custom form-check-solid form-check-sm mt-3">
                        <input
                            id={idPassword}
                            className="form-check-input"
                            type="checkbox"
                            checked={showPassword}
                            onChange={handlePassword}
                            placeholder={placeholder}
                        />
                        <label
                            className="form-check-label"
                            htmlFor={idPassword}
                        >
                            {t("AUTH.SHOW-PASSWORD")}
                        </label>
                    </div>
                ) : null}
            </div>
        </div>
    ) : (
        <div className={className}>
            <label
                htmlFor={id}
                className={`${
                    required ? "required" : null
                } form-label flex items-center`}
            >
                {label}
                {infoForField ? (
                    <InfoForFields id={`${id}`} text={infoForField} />
                ) : null}
            </label>
            <input
                readOnly={readOnly}
                required={required}
                id={id}
                type={type}
                maxLength={maxLength}
                name={id}
                className={clsx(
                    "w-full p-3 py-4 rounded-xl bg-[#1B1B29] focus:outline-none focus:ring focus:border-cyan-500 border-slate-100 autofill:border-cyan-500",
                    {
                        "border-2 border-red-600": fieldError?.includes(id)
                    }
                )}
                // className={`w-full p-3 py-4 rounded-xl bg-[#1B1B29] focus:outline-none focus:ring focus:border-cyan-500 border-slate-100 autofill:border-cyan-500 ${
                //     fieldError?.includes(id) ? "border-red-500" : null
                // }`}
                onChange={handleChange}
                value={value}
                placeholder={placeholder}
                autoComplete="one-time-code"
            />
            {fieldError?.includes(id) ? (
                <div className="text-red-600 mt-2">{error}</div>
            ) : null}
            {helpText ? (
                <div id={`${id}-help`} className="form-text">
                    {helpText}
                </div>
            ) : null}
            {isPassword ? (
                <div className="flex flex-col gap-4 mt-5 md:flex-row md:gap-0 items-center justify-between ">
                    <Checkbox
                        id={idPassword}
                        icon={<FontAwesomeIcon icon={faCheck} />}
                        size="lg"
                        isSelected={showPassword}
                        onValueChange={() => setShowPassword(!showPassword)}
                    >
                        {t("AUTH.SHOW-PASSWORD")}
                    </Checkbox>

                    {recoverPassword ? recoverPassword : null}
                </div>
            ) : // <div className="form-check form-check-custom form-check-solid form-check-sm mt-3">
            //     <input
            //         id={idPassword}
            //         className="form-check-input"
            //         type="checkbox"
            //         checked={showPassword}
            //         onChange={() => setShowPassword(!showPassword)}
            //         placeholder={placeholder}
            //     />
            //     <label
            //         className="form-check-label"
            //         htmlFor={idPassword}
            //     >
            //         {t("AUTH.SHOW-PASSWORD")}
            //     </label>
            // </div>
            null}
        </div>
    );
};
