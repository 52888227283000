/**
 * @description
 * a doc desse componente foi escrita diferente pq tava quebrando em arquivo tsx
 * 
 * text - string - texto que será inserido como subtítulo
 * @returns 
 */
export const CardSubtitle = ({text}) => {
    return (
        <span className="text-lg text-gray-700">{text}</span>
    )
}