import React, { useEffect, useState } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { InputDefault } from "../../../components/Form/InputDefault";

/**
 * Componente de formulário para a seção "Video Gallery".
 *
 * @param {string} tabId - id da tab.
 * @param {Object} data - Os dados da seção.
 * @param {function} setData - Função para atualizar os dados da seção.
 * @param {Array} fieldErrors - Um array de erros de campo.
 * @returns {JSX.Element} Um elemento JSX que representa a seção "Video Gallery".
 */

const OneSheetVideoGallerySection = ({ tabId, data, setData }) => {
    const { t } = useTranslate();
    const [videos, setVideos] = useState(data.value);
    const [errors, setErrors] = useState([]);

    const updateVideoAtIndex = (e, index) => {
        const fieldValue = e.target.value;

        if (fieldValue.length > 50) {
            // Adicione o campo ao Set de erros se não estiver presente
            setErrors((prevErrors) => new Set(prevErrors).add(e.target.name));
        } else {
            // Remova o campo do Set de erros se ele já não atender aos critérios
            const errorsCopy = new Set(errors);
            errorsCopy.delete(e.target.name);
            setErrors(errorsCopy);
        }

        setVideos((prevState) => {
            // Crie uma cópia do array existente
            const newArray = [...prevState];

            // Atualize o valor na posição especificada (index)
            newArray[index] = fieldValue;

            // Retorne o novo array atualizado
            return newArray;
        });
    };

    useEffect(() => {
        setData(tabId, "value", videos);
    }, [videos]);

    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data.show}
                            onChange={(e) =>
                                setData(tabId, "show", e.target.checked)
                            }
                        />
                    </label>

                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>

            <hr className="my-10" />

            {/* Primeiro Vídeo */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.FIRST-VIDEO")}
                    id="first_video"
                    type="url"
                    handleChange={(e) => updateVideoAtIndex(e, 0)}
                    value={data.value[0]}
                />
            </div>
            {/* Primeiro Vídeo cta */}
            <div className="row mb-6">
                <InputDefault
                    maxlength="50"
                    label={t("FORMS.ONESHEET.FIRST-VIDEO-CTA")}
                    id="first_video_cta"
                    type="text"
                    handleChange={(e) => updateVideoAtIndex(e, 1)}
                    value={data.value[1]}
                    className={
                        Array.from(errors).includes("first_video_cta")
                            ? "is-invalid"
                            : ""
                    }
                />
                {Array.from(errors).includes("first_video_cta") ? (
                    <div
                        id="validationCta"
                        className="invalid-feedback"
                    >
                        {t('FORMS.ONESHEET.CTA-LIMIT-CHARACTERS')}
                    </div>
                ) : null}
            </div>

            <hr className="my-5" />

            {/* Segundo Vídeo */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.SECOND-VIDEO")}
                    id="second_video"
                    type="url"
                    handleChange={(e) => updateVideoAtIndex(e, 2)}
                    value={data.value[2]}
                />
            </div>
            {/* Segundo Vídeo Cta */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.SECOND-VIDEO-CTA")}
                    id="second_video_cta"
                    type="text"
                    handleChange={(e) => updateVideoAtIndex(e, 3)}
                    value={data.value[3]}
                    className={
                        Array.from(errors).includes("second_video_cta")
                            ? "is-invalid"
                            : ""
                    }
                />
                {Array.from(errors).includes("second_video_cta") ? (
                    <div
                        id="validationCta"
                        className="invalid-feedback"
                    >
                        {t('FORMS.ONESHEET.CTA-LIMIT-CHARACTERS')}
                    </div>
                ) : null}
            </div>

            <hr className="my-5" />

            {/* Terceiro Vídeo */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.THIRD-VIDEO")}
                    id="third_video"
                    type="url"
                    handleChange={(e) => updateVideoAtIndex(e, 4)}
                    value={data.value[4]}
                />
            </div>
            {/* Terceiro Vídeo cta */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.THIRD-VIDEO-CTA")}
                    id="third_video_cta"
                    type="text"
                    handleChange={(e) => updateVideoAtIndex(e, 5)}
                    value={data.value[5]}
                    className={
                        Array.from(errors).includes("third_video_cta")
                            ? "is-invalid"
                            : ""
                    }
                />
                {Array.from(errors).includes("third_video_cta") ? (
                    <div
                        id="validationCta"
                        className="invalid-feedback"
                    >
                        {t('FORMS.ONESHEET.CTA-LIMIT-CHARACTERS')}
                    </div>
                ) : null}
            </div>

            <hr className="my-5" />

            {/* Quarto Vídeo */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.FOURTH-VIDEO")}
                    id="fourth_video"
                    type="url"
                    handleChange={(e) => updateVideoAtIndex(e, 6)}
                    value={data.value[6]}
                />
            </div>
            {/* Quarto Vídeo cta */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.FOURTH-VIDEO-CTA")}
                    id="fourth_video_cta"
                    type="text"
                    handleChange={(e) => updateVideoAtIndex(e, 7)}
                    value={data.value[7]}
                    className={
                        Array.from(errors).includes("fourth_video_cta")
                            ? "is-invalid"
                            : ""
                    }
                />
                {Array.from(errors).includes("fourth_video_cta") ? (
                    <div
                        id="validationCta"
                        className="invalid-feedback"
                    >
                        {t('FORMS.ONESHEET.CTA-LIMIT-CHARACTERS')}
                    </div>
                ) : null}
            </div>

            <hr className="my-5" />

            {/* Quinto Vídeo */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.FIFTH-VIDEO")}
                    id="fifth_video"
                    type="url"
                    handleChange={(e) => updateVideoAtIndex(e, 8)}
                    value={data.value[8]}
                />
            </div>
            {/* Quinto Vídeo cta*/}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.FIFTH-VIDEO-CTA")}
                    id="fifth_video_cta"
                    type="text"
                    handleChange={(e) => updateVideoAtIndex(e, 9)}
                    value={data.value[9]}
                    className={
                        Array.from(errors).includes("fifth_video_cta")
                            ? "is-invalid"
                            : ""
                    }
                />
                {Array.from(errors).includes("fifth_video_cta") ? (
                    <div
                        id="validationCta"
                        className="invalid-feedback"
                    >
                        {t('FORMS.ONESHEET.CTA-LIMIT-CHARACTERS')}
                    </div>
                ) : null}
            </div>

            <hr className="my-5" />

            {/* Sexto Vídeo */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.SIXTH-VIDEO")}
                    id="sixth_video"
                    type="url"
                    handleChange={(e) => updateVideoAtIndex(e, 10)}
                    value={videos[10]}
                />
            </div>
            {/* Sexto Vídeo */}
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.SIXTH-VIDEO-CTA")}
                    id="sixth_video_cta"
                    type="text"
                    handleChange={(e) => updateVideoAtIndex(e, 11)}
                    value={videos[11]}
                    className={
                        Array.from(errors).includes("sixth_video_cta")
                            ? "is-invalid"
                            : ""
                    }
                />
                {Array.from(errors).includes("sixth_video_cta") ? (
                    <div
                        id="validationCta"
                        className="invalid-feedback"
                    >
                        {t('FORMS.ONESHEET.CTA-LIMIT-CHARACTERS')}
                    </div>
                ) : null}
            </div>
        </>
    );
};

export { OneSheetVideoGallerySection };
