import { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { Toolbar } from "./components/toolbar/Toolbar";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider, useLayout } from "./core";
import { useLocation } from "react-router-dom";
import {
    DrawerMessenger,
    RightToolbar,
    ActivityDrawer,
    InviteUsers,
    UpgradePlan,
} from "../../_metronic/partials";
import { MenuComponent } from "../../_metronic/assets/ts/components";
import clsx from "clsx";
import {
    themeModeSwitchHelper,
    useThemeMode,
} from "./theme-mode/ThemeModeProvider";
import { ToastContainer } from "react-toastify";
import { RecentProjectChanges } from "./components/RecentProjectChanges";
import { ChangeArtistOrClient } from "../modules/core/artist/ChangeArtistOrClient";

const MasterLayout = ({ children }) => {
    const { classes } = useLayout();
    const { mode } = useThemeMode();
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization();
        }, 500);
    }, [location.key]);

    useEffect(() => {
        themeModeSwitchHelper(mode);
    }, [mode]);

    return (
        <PageDataProvider>
            <div className="page d-flex flex-row flex-column-fluid">
                <div
                    className="wrapper d-flex flex-column min-h-[100vh] overflow-y-auto flex-row-fluid"
                    id="kt_wrapper"
                >
                    <ToastContainer className="Toastify__toast--dark" />
                    <HeaderWrapper />

                    <div
                        id="kt_content"
                        className="content d-flex flex-column flex-column-fluid"
                    >
                        <Toolbar />
                        <div
                            className={clsx(
                                "d-flex flex-column-fluid align-items-start",
                                classes.contentContainer.join(" ")
                            )}
                            id="kt_post"
                        >
                            {/*<AsideDefault />*/}
                            <Content>
                                <Outlet />
                            </Content>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            {/* begin:: Drawers */}
            <ChangeArtistOrClient />
            <RecentProjectChanges />
            {/*<RightToolbar />*/}
            <DrawerMessenger />
            {/* end:: Drawers */}

            {/* begin:: Modals */}
            <InviteUsers />
            <UpgradePlan />
            {/* end:: Modals */}
            <ScrollTop />
        </PageDataProvider>
    );
};

export { MasterLayout };
