import React from "react";
import styles from "./ColorPicker.module.css";
import { TwitterPicker } from "react-color";

/**

Componente de seletor de cores.

@component
@param {string} label - O rótulo exibido acima do seletor de cores.
@param {string} idShow - O ID do elemento que exibe o seletor de cores quando clicado.
@param {string} color - A cor atual selecionada.
@param {string} idHide - O ID do elemento que oculta o seletor de cores quando clicado.
@param {function} handleShowColorPicker - Função chamada ao exibir o seletor de cores.
@param {object} displayColorSelection - Objeto contendo o estado de exibição do seletor de cores.
@param {function} handleChange - Função chamada ao alterar a cor selecionada.
@param {function} handleHideColorPicker - Função chamada ao ocultar o seletor de cores.
@param {Array} fieldErrors - Array contendo os IDs dos elementos com erros de campo.
@param {string} error - A mensagem de erro a ser exibida quando houver erro no campo.
@param {string} helpText - O texto de ajuda exibido abaixo do campo.
@returns {JSX.Element} Componente ColorPicker.

@description
Este componente representa um seletor de cores. Ele recebe várias propriedades
que controlam o comportamento e a aparência do seletor. Quando o usuário clica
na área de cor, o seletor de cores é exibido, permitindo a seleção de uma nova
cor. A cor selecionada é refletida na área de cor e pode ser alterada usando o
seletor de cores. Além disso, o componente exibe um rótulo, mensagens de erro
e texto de ajuda conforme necessário.
*/

export const ColorPicker = ({
    label,
    idShow,
    color,
    idHide,
    handleShowColorPicker,
    displayColorSelection,
    handleChange,
    handleHideColorPicker,
    fieldErrors,
    error,
    helpText,
}) => {
    return (
        <>
            <label className="form-label">{label}</label>
            <div
                id={idShow}
                onClick={handleShowColorPicker}
                style={{
                    backgroundColor: `${color}`,
                    width: 100,
                    height: 50,
                    border: "2px solid white",
                    marginBottom: "4px",
                }}
            />
            <div
                className={styles.colorPicker}
                style={{
                    display: displayColorSelection[idShow],
                }}
            >
                <TwitterPicker
                    color={color}
                    onChange={(color) => {
                        handleChange({
                            target: {
                                name: `${idShow}`,
                                value: color.hex,
                            },
                        });
                    }}
                    // colors={["#fff", "#000"]}
                />
                <div
                    id={idHide}
                    className={[
                        styles.hideColorPicker,
                        "bg-danger rounded-circle",
                    ].join(" ")}
                    onClick={(e) => handleHideColorPicker(e)}
                >
                    X
                </div>
            </div>

            {fieldErrors?.includes({ idShow }) ? (
                <div className="text-danger mt-2">{error}</div>
            ) : null}

            <div className="form-text text-muted">{helpText}</div>
        </>
    );
};
