import React from "react";

const PlaceholderImage = ({ width, height, text, className }) => (
  <img
    className={className}
    src={`https://via.placeholder.com/${width}x${height}/cccccc/696969?text=${text}`}
    alt={`Placeholder ${width} x ${height}: "${text}"`}
  />
);

export default PlaceholderImage;
