import React from "react";
import { faChevronDown, faFilters } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger } from "react-bootstrap";
import { useTranslate } from "../../helpers/i18n/useTranslate";

/**
 * Componente de filtro.
 *
 * @param {boolean} showPopover - Indica se o popover está visível.
 * @param {function} setShowPopover - Função para definir a visibilidade do popover.
 * @param {ReactNode} popover - Conteúdo do popover.
 * @returns {JSX.Element} O componente de filtro.
 */
export const Filter = ({ showPopover, setShowPopover, popover }) => {
    const { t } = useTranslate();
    return (
        <div className="flex justify-content-start gap-5">
            <OverlayTrigger
                trigger="click"
                show={showPopover}
                rootClose
                placement="bottom"
                onToggle={setShowPopover}
                overlay={popover}
            >
                <button
                    type="button"
                    className="btn btn-sm btn-color-primary btn-light-primary flex items-center gap-3 px-4"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="top-end"
                >
                    <div className="flex items-center gap-2">
                        <span>{t("FORMS.GENERAL.FILTERS")}</span>
                    </div>

                    <FontAwesomeIcon icon={faChevronDown} />
                </button>
            </OverlayTrigger>
        </div>
    );
};
