import React from "react";
import { Container, Grid } from "@nextui-org/react";
import { Title } from "../Components/Title";
import styles from "./OnesheetVideoGalleryPreview.module.css";
import ReactPlayer from "react-player";
import { ScrollTo } from "../../../components/ScrollTo";

/**
 * Componente de visualização para a seção "Last Tour" de uma página.
 *
 * @param {Object} data - Os dados da seção.
 * @returns {JSX.Element} Um elemento JSX que representa a visualização da seção "Galeria de vídeos".
 */

const OnesheetVideoGalleryPreview = ({ data }) => {
    // Filtra os valores no array "data?.value" para manter apenas os elementos que não são strings vazias.
    const videosNotEmpty = data?.value.filter((video) => video !== "");

    // Filtra os valores no array "data?.value" para manter apenas vídeos.
    const onlyVideos = data?.value.filter((video) => video.startsWith("https"));

    // Filtra os valores no array "data?.value" para manter apenas os ctas.
    const onlyCta = data?.value.filter((video) => !video.startsWith("https"));

    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container
                direction="column"
                justify="flex-start"
                css={{ mw: "1300px", m: "0 auto" }}
            >
                <Grid>
                    <Title color={data?.fontColor} text={"Galeria de Vídeos"} />
                </Grid>

                {videosNotEmpty.length > 0 && onlyVideos ? (
                    <div className={styles.gridVideos}>
                        {onlyVideos.map((video, index) => {
                            const ctaText = onlyCta[index]; // Acessa o texto correspondente em onlyCta
                            return (
                                <>
                                    {data?.[video] !== null ? (
                                        <div
                                            key={index}
                                            className={styles.video}
                                        >
                                            <div className={styles.videoNcta}>
                                                <ReactPlayer
                                                    width="100%"
                                                    url={video}
                                                    className="mb-3"
                                                />
                                                {ctaText ? ( // Verifica se há um texto de CTA correspondente
                                                    <a
                                                        href={video}
                                                        className={styles.cta}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            color: data?.fontColor,
                                                        }}
                                                    >
                                                        {ctaText}
                                                    </a>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            );
                        })}
                    </div>
                ) : null}
            </Grid.Container>
        </Container>
    );
};

export { OnesheetVideoGalleryPreview };
