import {
    Button,
    Container,
    Grid,
    Image,
    Modal,
    Tooltip,
} from "@nextui-org/react";
import { Title } from "../Components/Title";
import { Subtitle } from "../Components/Subtitle";
import styles from "./OnesheetLatestAlbums.module.css";
import { useEffect, useState } from "react";
import { isNotEmpty } from "../../../helpers/usefulFunctions";

/**
 * Prévia para o elemento Lançamentos Recentes do Onesheet
 * @param {object} data informações necessárias pra renderização da prévia
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetLatestAlbumsPreview = ({ data }) => {
    const [album, setAlbum] = useState({});
    const [albums, setAlbums] = useState([]);

    useEffect(() => {
        setAlbums(isNotEmpty(data?.value) ? data.value : []);
    }, [data]);

    // parte que lida com modal
    const [visible, setVisible] = useState(false);
    const closeHandler = () => {
        setVisible(false);
    };

    const handler = (ModalAlbum) => {
        setAlbum(ModalAlbum);
        setVisible(true);
    };

    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container
                direction="column"
                css={{ mw: "1300px", m: "0 auto" }}
            >
                <Grid css={{ mb: "1.5rem" }}>
                    <Title color={data?.fontColor} text={data?.title} />
                    <Subtitle
                        color={data?.secondary_color}
                        text={data?.subtitle}
                    />
                </Grid>
                <Grid.Container gap={3}>
                    {albums.map((album, index) => (
                        <Grid md={4} key={index}>
                            <Tooltip
                                hideArrow
                                className={styles.tooltip}
                                content={album.title}
                                placement="bottom"
                                css={{
                                    bc: data?.fontColor,
                                    color: data?.background,
                                    fs: "16px",
                                }}
                            >
                                <Image
                                    showSkeleton
                                    src={
                                        album.image
                                            ? album.image
                                            : "https://placehold.co/380x380@2x.png"
                                    }
                                    width={"100%"}
                                    height={"100%"}
                                    objectFit="cover"
                                    onClick={() => handler(album)}
                                    css={{
                                        w: "100%",
                                        h: "100%",
                                        mw: "380px",
                                        mh: "380px",
                                        borderRadius: "0.75rem",
                                        cursor: "pointer",
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                    ))}

                    <ModalButtons
                        fontColor={data?.fontColor}
                        bgColor={data?.background}
                        visible={visible}
                        closeHandler={closeHandler}
                        handler={handler}
                        album={album}
                    />
                </Grid.Container>
            </Grid.Container>
        </Container>
    );
};

/**
 * Renderiza os links para a música nas dsps
 * @param {String} links url do Spotify todo mudar esse parametro pra receber a lista de urls das dsps
 * @param {String} fontColor cor da fonte
 * @param {String} bgColor cor do bg
 * @returns {JSX.Element}
 * @constructor
 */
const ReleaseButtons = ({ links, fontColor, bgColor }) => {
    const buttons = Object.keys(links).filter((link) =>
        link.startsWith("dsp_")
    );

    const platformLinks = {
        dsp_deezer_id: "https://www.deezer.com/track/",
        dsp_itunes_id: "https://music.apple.com/album/",
        dsp_spotify_id: "https://open.spotify.com/album/",
        dsp_youtube_id: "https://www.youtube.com/watch?v=",
        dsp_youtube_music_id: "https://music.youtube.com/watch?v=",
    };

    /**
     * função que lida com o click no botão da plataforma
     * tem o mesmo comportamento de uma tag "a" do html com atributo "target _blank"
     * foi criado para ter visual funcionando da forma como está
     *
     * recebe uma string que é a url do link
     * @param {string} linkUrl
     */
    const handlePlatformClick = (linkUrl) => {
        // Abre o link em uma nova aba
        window.open(linkUrl, "_blank", "noopener,noreferrer");
    };

    return (
        <Button.Group size="lg" vertical flat>
            {buttons.map((button, index) => {
                const name = button;
                const parts = name.split("_");
                const result = name.includes("music")
                    ? `${parts[1]} ${parts[2]}`
                    : parts[1];

                return links[name] ? (
                    <Button
                        key={index}
                        className={styles.buttonGroup}
                        onClick={() =>
                            handlePlatformClick(
                                `${platformLinks[button]}${links[button]}`
                            )
                        }
                        css={{
                            tt: "capitalize",
                            w: "100%",
                            bg: bgColor,
                            color: fontColor,
                        }}
                    >
                        {result}
                    </Button>
                ) : null;
            })}
        </Button.Group>
    );
};

/**
 *
 * @param {Boolean} visible moda visível ou nao
 * @param {function} closeHandler handler de fechar o modal
 * @param {object} album dados do lancamento
 * @param {String} fontColor cor da fonte
 * @param {String} bgColor cor do bg
 * @returns {JSX.Element}
 * @constructor
 */
const ModalButtons = ({ visible, closeHandler, album, fontColor, bgColor }) => {
    return (
        <Modal
            closeButton
            aria-labelledby="modal-title"
            open={visible}
            onClose={closeHandler}
        >
            <Modal.Header>
                <Container display="flex" direction="column">
                    <Title text={album.title} />
                    {/*<Subtitle text={album.release_year} />*/}
                </Container>
            </Modal.Header>
            <Modal.Body>
                <ReleaseButtons
                    links={album}
                    fontColor={fontColor}
                    bgColor={bgColor}
                />
            </Modal.Body>
        </Modal>
    );
};

export { OnesheetLatestAlbumsPreview };
