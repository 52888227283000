import api from "../../../helpers/Api";

const AUTH_LOCAL_STORAGE_KEY = "tooltor-user-access-tkn"; // Guarda o token do usr pra login por sessão
const getAuth = () => {
    if (!localStorage) {
        return;
    }

    const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (!lsValue) {
        return;
    }

    try {
        const auth = JSON.parse(lsValue);
        if (auth) {
            // You can easily check auth_token expiration also
            return auth;
        }
    } catch (error) {
        console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
    }
};

const setAuth = (auth) => {
    if (!localStorage) {
        return;
    }

    try {
        const lsValue = JSON.stringify(auth);
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
    } catch (error) {
        console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
    }
};

const removeAuth = () => {
    if (!localStorage) {
        return;
    }

    try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    } catch (error) {
        console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
    }
};

const getCreatePermissions = () => ["ADM", "EDT"];
const getReadPermissions = () => ["ADM", "EDT", "VIE"];
const geUpdatePermissions = () => ["ADM", "EDT"];
const geDeletePermissions = () => ["ADM"];

/**
 * Essa funcao vai pegar o user, listar seus parceiros disponíveis (e redirecionar p tela de completar perfil se não
 * houver nenhum), pegar o primeiro por default pra guardar como currentClient e pegar as permissoes de ação desse
 * parceiro nesse manager, guardando essa info também
 * @param {String} token
 * @returns {Promise<{partnerships: *[], selectedPartner: {manager_name: string, allowed_actions: string, artists: string, manager_id: string, name: string, get_allowed_actions_display: string, is_global: string, manager_owner: string, manager_get_available_features: string, id: string, email: string, manager_is_recorder: string}, user: {last_name: string, id: string, first_name: string, email: string}}>}
 */
async function getUserByToken(token, refreshToken = "") {
    const returnData = {
        user: {
            id: "",
            email: "",
            first_name: "",
            last_name: "",
            spotifyId: "",
            googleRefreshToken: "",
            facebookToken: "",
            hasAcceptedTerms: true,
        },
        partnerships: [],
        selectedPartner: {
            id: "",
            name: "",
            email: "",
            artists: "",
            is_global: "",
            canCreate: false,
            canUpdate: false,
            canRead: false,
            canDelete: false,
            allowed_actions: "",
            get_allowed_actions_display: "",
            manager_id: "",
            manager_logo: "",
            manager_name: "",
            manager_owner: "",
            manager_owner_email: "",
            manager_is_recorder: "",
            manager_get_available_features_display: "",
            manager_available_features: "",
            manager_listening_party_guests_limit: "",
        },
    };
    // Nao faz try catch. deixa quem chama a funcao fazer, pra cada um fazer o handle de erro do jeito que couber
    const userResponse = await api.get("/core/users", {
        // Pega o user relacionado ao token passado por parametro
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const user = userResponse.data[0];
    returnData.user = {
        id: user.id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.first_name,
        spotifyId: user.spotify_id,
        googleRefreshToken: user.google_refresh_token,
        facebookToken: user.facebook_long_lived_token,
        hasAcceptedTerms: user.has_accepted_terms,
    };
    let partnersResponse;
    try {
        partnersResponse = await api.get(`/core/partnerships`, {
            // Pega os partners disponíveis pro user logado
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    } catch (err) {
        // Se entrar no if abaixo tem que redirecionar o usuário pra tela de completar perfil
        if (err.response?.status === 404) {
            document.cookie = `at=${token}; path=/`;
            document.cookie = `rt=${refreshToken}; path=/`;
            window.location.replace("/auth/complete-signup");
        }
        // Se não entrar no if, raisa o erro pra quem chamou a função poder gerenciar do jeito que quiser
        throw err;
    }
    let partnerClient = partnersResponse.data;
    // let partnership = {
    //     id: partnerClient.id,
    //     name: partnerClient.name,
    //     email: partnerClient.email,
    //     artists: partnerClient.related_artists,
    //     is_global: partnerClient.is_global,
    //     canCreate: getCreatePermissions().includes(
    //         partnerClient.allowed_actions
    //     ),
    //     canRead: getReadPermissions().includes(partnerClient.allowed_actions),
    //     canUpdate: geUpdatePermissions().includes(
    //         partnerClient.allowed_actions
    //     ),
    //     canDelete: geDeletePermissions().includes(
    //         partnerClient.allowed_actions
    //     ),
    //     allowed_actions: partnerClient.allowed_actions,
    //     available_features: partnerClient.available_features,
    //     get_allowed_actions_display: partnerClient.get_allowed_actions_display,
    //     manager_id: partnerClient.manager_data.id,
    //     manager_logo: partnerClient.manager_data.logo,
    //     manager_name: partnerClient.manager_data.name,
    //     manager_owner: partnerClient.manager_data.owner,
    //     manager_owner_email: partnerClient.manager_data.owner_email,
    //     manager_is_recorder: partnerClient.manager_data.is_recorder,
    //     manager_get_available_features_display:
    //         partnerClient.manager_data.get_available_features_display,
    //     manager_available_features:
    //         partnerClient.manager_data.available_features,
    //     manager_listening_party_guests_limit:
    //         partnerClient.manager_data.listening_party_guests_limit,
    // };
    // returnData.partnerships = [partnership];
    returnData.selectedPartner = partnerClient;
    return returnData;
}

export { getAuth, setAuth, removeAuth, getUserByToken, AUTH_LOCAL_STORAGE_KEY };
