import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { toast } from "react-toastify";

export function ForgotPassword() {
    const { POST } = useApi();
    const { t } = useTranslate();
    const [loading, setLoading] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let data = new FormData();
        data.append("email", email);
        POST("/core/u/change-password/", data)
            .then(() => setRequestSent(true))
            .catch((err) => {
                console.error(err);
                toast.error(t("GENERAL.CONNECTION-ERROR"));
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <form
                className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                noValidate
                id="kt_login_password_reset_form"
                onSubmit={handleSubmit}
            >
                <div className="text-center mb-10">
                    <h1 className="text-dark mb-3">
                        {t("AUTH.GENERAL.FORGOT-TITLE")}
                    </h1>
                    <div className="text-gray-400 fw-bold fs-4">
                        {t("AUTH.GENERAL.FORGOT-DESCRIPTION")}
                    </div>
                </div>

                {requestSent && (
                    <div className="mb-lg-15 alert alert-success">
                        <div className="alert-text font-weight-bold">
                            {t("AUTH.GENERAL.FORGOT-CONFIRM")}
                        </div>
                    </div>
                )}
                <div className="fv-row mb-10">
                    <label className="form-label fw-bolder text-gray-900 fs-6">
                        {t("AUTH.INPUT.USERNAME")}
                    </label>
                    <div
                        className={clsx("d-flex align-items-center py-0", {
                            "is-valid": requestSent,
                        })}
                    >
                        <input
                            type="email"
                            className={clsx(
                                "form-control form-control-lg form-control-solid py-0",
                                {
                                    "is-valid": requestSent,
                                }
                            )}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        {loading && (
                            <div className="spinner-border spinner-border-sm align-middle ms-2"></div>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                    <button
                        type="submit"
                        id="kt_password_reset_submit"
                        className="btn btn-lg btn-primary fw-bolder me-4"
                        disabled={loading}
                    >
                        <span className="indicator-label">
                            {t("AUTH.GENERAL.SUBMIT_BUTTON")}
                        </span>
                    </button>
                    <Link to="/auth/login">
                        <button
                            type="button"
                            id="kt_login_password_reset_form_cancel_button"
                            className="btn btn-lg btn-light-primary fw-bolder"
                            disabled={loading}
                        >
                            {t("GENERAL.CANCEL")}
                        </button>
                    </Link>{" "}
                </div>
            </form>
        </>
    );
}
