import React from "react";
import { Button, Grid } from "@nextui-org/react";

const AppDownloadButtons = () => {
    return (
        <Grid.Container gap={2} justify="center">
            <Grid>
                <Button
                    auto
                    as="a"
                    href="https://apps.apple.com/app/id1234567890" // Substitua pelo link da App Store
                >
                    <img
                        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                        alt="Baixe agora na App Store"
                        style={{ height: "30px" }}
                    />
                </Button>
            </Grid>
            <Grid>
                <Button
                    auto
                    as="a"
                    href="https://play.google.com/store/apps/details?id=com.example.app" // Substitua pelo link do Google Play
                >
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                        alt="Baixe agora no Google Play"
                        style={{ height: "30px" }}
                    />
                </Button>
            </Grid>
        </Grid.Container>
    );
};

export default AppDownloadButtons;
