import { observer } from "mobx-react-lite";
import { useTranslate } from "../../../../../helpers/i18n/useTranslate";
import clsx from "clsx";
import React from "react";

const RecorderForm = observer(({ form, handleChange }) => {
  const { t } = useTranslate();
  return (
    <>
      <div className="mb-10">
        <h2>{t("FORMS.CORE.MANAGER.NAME")}</h2>
      </div>
      <input
        className={clsx("form-control form-control-lg form-control-solid", {
          "is-invalid": form.fieldErrors.clientName,
        })}
        type="name"
        name="clientName"
        value={form.data.clientName}
        onChange={(e) => handleChange(e)}
      />
      {form.fieldErrors.clientName ? (
        <div className="text-danger mt-2">{form.fieldErrors.clientName}</div>
      ) : null}
    </>
  );
});

export default RecorderForm;
