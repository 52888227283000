import { Text } from "@nextui-org/react"
/**
 * Componente de Subtítulo.
 *
 * Este componente exibe um subtítulo com a cor e o texto especificados.
 *
 * @param {string} color - A cor do subtítulo.
 * @param {string} text - O texto a ser exibido como subtítulo.
 * @returns {JSX.Element} - O componente de subtítulo.
 */
export const Subtitle = ({color, text}) => {
    return (
        <Text
            h3
            color={color}
            css={{
                tt: 'uppercase',
                fs: '0.875rem',
                letterSpacing: '5px',
                lineHeight: '24px'
            }}
        >
            {text}
        </Text>
    )
}