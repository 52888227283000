import { useParams } from "react-router-dom";
import { useEffect, useState, cloneElement } from "react";
import "react-quill/dist/quill.snow.css";
import styles from "./OnesheetForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretLeft,
    faDesktop,
    faEnvelope,
    faMobile,
    faPencil,
    faShareNodes,
    faTablet,
    faAnglesLeft,
} from "@fortawesome/pro-light-svg-icons";
import { useAuth } from "../auth";
import useDebounce from "../../helpers/utils/useDebounce";
import { useApi } from "../../helpers/Api/ApiWrapper";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import { useAlert } from "../../helpers/utils/CustomAlert";
import { useCreateButton } from "../../helpers/subHeaderButtons/createButtonProvider";
import { toast } from "react-toastify";
import { makeFormDataBody } from "../../helpers/utils/formUtils";
import { PageTitle } from "../../layout/core";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import clsx from "clsx";
import { HeaderBackInvSection } from "../../components/HeaderBackInvSection";
import { InfoDescription } from "../../components/InfoDescription";
import { OnesheetInviteFormSection } from "./formSections/OnesheetInviteFormSection";
import { OnesheetHeroSection } from "./formSections/OnesheetHeroSection";
import { CustomBackButton } from "../../components/CustomBackButton";
import { CustomSaveButton } from "../../components/CustomSaveButton";
import { OnesheetInviteFormPreview } from "./previewSections/OnesheetInviteFormPreview";
import { OnesheetHeroPreview } from "./previewSections/OnesheetHeroPreview";
import { OnesheetMetadataSection } from "./formSections/OnesheetMainSection";
import { OnesheetLatestReleasePreview } from "./previewSections/OnesheetLatestReleasePreview";
import { OnesheetLatestReleaseSection } from "./formSections/OnesheetLatestReleaseSection";
import { OnesheetVideoGalleryPreview } from "./previewSections/OnesheetVideoGalleryPreview";
import { OneSheetVideoGallerySection } from "./formSections/OneSheetVideoGallerySection";
import { OnesheetLatestAlbumsSection } from "./formSections/OnesheetLatestAlbumsSection";
import { OnesheetLatestAlbumsPreview } from "./previewSections/OnesheetLatestAlbumsPreview";
import { OnesheetBioSection } from "./formSections/OnesheetBioSection";
import { OnesheetBioPreview } from "./previewSections/OnesheetBioPreview";
import { OnesheetLastTourSection } from "./formSections/OnesheetLastTourSection";
import { OnesheetLastTourPreview } from "./previewSections/OnesheetLastTourPreview";
import { OnesheetImageGalleryPreview } from "./previewSections/OnesheetImageGalleryPreview";
import { OneSheetImageGallerySection } from "./formSections/OneSheetImageGallerySection";
import { OnesheetLinksPreview, OnesheetNewsPreview } from "./previewSections/OnesheetNewsPreview";
import { OnesheetLinksSection } from "./formSections/OnesheetLinksSection";
import { ScrollTo } from "../../components/ScrollTo";
import { OnesheetFeaturedNumbersSection } from "./formSections/OnesheetFeaturedNumbersSection";
import { OnesheetFeaturedNumbersPreview } from "./previewSections/OnesheetFeaturedNumbersPreview";
import { ShareOnesheetModal } from "./ShareOnesheetModal";

import {
    elementConfiguration,
    transformElements,
    translateElementToBackendAttributes,
    makeId,
} from "onipkg";
import { OnesheetExternalLinksPreview } from "./previewSections/OnesheetExternalLinksPreview";
import { OnesheetExternalLinksSection } from "./formSections/OnesheetExternalLinksSection";
import { OnesheetSpotifyEmbedPreview } from "./previewSections/OnesheetSpotifyEmbedPreview";
import { OnesheetSpotifyEmbedSection } from "./formSections/OnesheetSpotifyEmbedSection";
import { OnesheetFilesSection } from "./formSections/OnesheetFilesSection";
import { OnesheetFilesPreview } from "./previewSections/OnesheetFilesPreview";
import { OnesheetInstagramSection } from "./formSections/OnesheetInstagramSection";
import { OnesheetInstagramPreview } from "./previewSections/OnesheetInstagramPreview";

/**
 * # TUTORIAL DE COMO CRIAR NOVOS ELEMENTOS NA ONESHEET
 *
 * Para adicionar um novo elemento na Onesheet, vc vai precisar de:
 * 1. Criar a lógica de renderização dele no Onilnk, definindo nessa etapa, como os valores dele ficarão salvos no BD do Onilnk (isso interfere na forma como o front lê e guarda essas informações);
 * 2. Criar o elemento do formulário dele aqui e colocar na pasta formSections;
 * 3. Criar o elemento de preview dele aqui e colocar na pasta previewSections;
 * 4. Conferir que os componentes criados de form e preview estão guardando e lendo o elemento da forma correta, definida pelo bdType dele;
 * 5. Colocar o "bdType" que vc definiu nas variáveis SectionsFormsJsx (renderizando o componente do form criado), PreviewsFormsJsx (renderizando o componente do preview criado) e CONSTS (sob o grupo correspondente para o tipo de processamento do elemento);
 */

let SectionsFormsJsx = {
    MAIN: <OnesheetMetadataSection />,
    onipages_music: <></>,
    onipages_video: <></>,
    onipages_link: <></>,
    "onipages_external-links": <OnesheetExternalLinksSection />,
    onipages_files: <OnesheetFilesSection />,
    onipages_spotify: <OnesheetSpotifyEmbedSection/>,
    onipages_deezer: <></>,
    onipages_instagram: <OnesheetInstagramSection />,
    onipages_tiktok: <></>,
    onipages_youtube: <></>,
    onipages_twitter: <></>,
    onipages_qrcode: <></>,
    onipages_calendar: <></>,
    onipages_lnk: <></>,
    "onipages_social-media": <></>,
    "onipages_images-grid": <OneSheetImageGallerySection />,
    "onipages_latest-release": <OnesheetLatestReleaseSection />,
    "onipages_latest-albums": <OnesheetLatestAlbumsSection />,
    onipages_news: <OnesheetLinksSection />,
    onipages_bio: <OnesheetBioSection />,
    onipages_spacer: <></>,
    onipages_emphasis: <OnesheetHeroSection />,
    "onipages_profile-photo": <></>,
    "onipages_capture-user": <></>,
    onipages_footer: <></>,
    onipages_invite: <OnesheetInviteFormSection />,
    "onipages_video-gallery": <OneSheetVideoGallerySection />,
    "onipages_last-tour": <OnesheetLastTourSection />,
    "onipages_featured-numbers": <OnesheetFeaturedNumbersSection />,
};

let PreviewsFormsJsx = {
    onipages_music: <></>,
    onipages_video: <></>,
    onipages_link: <></>,
    "onipages_external-links": <OnesheetExternalLinksPreview/>,
    onipages_files: <OnesheetFilesPreview /> ,
    onipages_spotify: <OnesheetSpotifyEmbedPreview />,
    onipages_deezer: <></>,
    onipages_instagram: <OnesheetInstagramPreview />,
    onipages_tiktok: <></>,
    onipages_youtube: <></>,
    onipages_twitter: <></>,
    onipages_qrcode: <></>,
    onipages_calendar: <></>,
    onipages_lnk: <></>,
    "onipages_social-media": <></>,
    "onipages_images-grid": <OnesheetImageGalleryPreview />,
    "onipages_latest-release": <OnesheetLatestReleasePreview />,
    "onipages_latest-albums": <OnesheetLatestAlbumsPreview />,
    onipages_news: <OnesheetNewsPreview />,
    onipages_bio: <OnesheetBioPreview />,
    onipages_spacer: <></>,
    onipages_emphasis: <OnesheetHeroPreview />,
    "onipages_profile-photo": <></>,
    "onipages_capture-user": <></>,
    onipages_footer: <></>,
    onipages_invite: <OnesheetInviteFormPreview />,
    "onipages_video-gallery": <OnesheetVideoGalleryPreview />,
    "onipages_last-tour": <OnesheetLastTourPreview />,
    "onipages_featured-numbers": <OnesheetFeaturedNumbersPreview />,
};

const CONSTS = elementConfiguration;

/**
 * Tela para customização de uma onesheet
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetForm = () => {
    const { onesheetId } = useParams();
    const [sectionsTabs, setSectionsTabs] = useState([]);
    const [activeSectionTab, setActiveSectionTab] = useState({});
    const [loading, setLoading] = useState(true);
    const [elements, setElements] = useState({});
    const [metadata, setMetadata] = useState({});
    const [showMenu, setShowMenu] = useState(true);
    const [menuOpened, setMenuOpened] = useState(true);
    const [responsive, setResponsive] = useState("desktop");
    const [fieldErrors, setFieldErrors] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [formSending, setFormSending] = useState(false);
    const [showInviteButton, setShowInviteButton] = useState(true);
    const [newBgElements, setNewBgElements] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [showPreviewModes, setShowPreviewModes] = useState(false);

    const { GET, PUT } = useApi();
    const { t } = useTranslate();
    const { dialogs } = useAlert();
    const { setHref } = useCreateButton();
    setHref("");

    const breadCrumbs = [
        {
            title: t("APPS.ONESHEETS.TITLE"),
            path: "/onesheet",
            isSeparator: false,
            isActive: false,
        },
        {
            title: t("APPS.ONESHEETS.FORM.TITLE"),
            path: "/onesheet",
            isSeparator: true,
            isActive: true,
        },
    ];

    /**
     * Constrói o state elements no formato que precisaremos pro resto do código funcionar. Precisamos que cada elemento
     * esteja num objeto cuja chave é um id unique. Esse id será usado nas tabs laterais, pro código identificar qual
     * elemento o usuário está alterando. O onilnk salva cada o conteúdo de cada elemento de forma única, como se fosse
     * um JSONField. Fica quase tudo tuxado no campo 'value'. Essa funcao aqui é quem sabe como pegar cada informação
     * de cada elemento do jeito que precisa pro algoritmo.
     * Basicamente faz o contrário da translateElementToBackendAttributes
     * @param {array} elems Elementos vindos do onilnk, em formato de lista
     * @param {object} meta Metadados da onesheet
     * @returns {{}}
     */
    const elementsStateBuilder = (elems, meta) => {
        let mappedElements = {
            MAIN: {
                fontColor: meta.color_1,
                secondary_color: meta.secondary_color,
                background: meta.background_color,
                bdType: "MAIN",
            },
        };
        elems.forEach((element) => {
            let uniqueId = makeId(7);
            let tempElement = {
                id: uniqueId,
                bdType: element.type,
            };
            // !!! Pra ver explicações do pq cada valor é extraído de cada jeito, veja comentários no objeto CONSTS. !!!
            transformElements(
                tempElement,
                element,
                uniqueId,
                [],
                false,
                mappedElements
            );
        });

        return mappedElements;
    };

    /**
     * Pega os dados da inviteLandingPage e define os states necessários pro funcionamento da tela
     * @returns {Promise<void>}
     */
    const getData = async () => {
        try {
            // Pega os metadados da page no onilnk e coloca no state
            const metadataResponse = await GET(
                `/marketing/onesheets/${onesheetId}`
            );
            const amplifyUsDataResponse = await GET(
                `/marketing/onesheets-s/${onesheetId}`
            );
            let data = {
                ...amplifyUsDataResponse.data,
                ...metadataResponse.data,
            };
            setMetadata({
                ...data,
                artistName: amplifyUsDataResponse.data.artist_name,
                background: data.background_color,
                fontColor: data.color_1,
                secondary_color: data.secondary_color,
            });

            // Pega os elementos do onilnk e coloca no state
            const elementsResponse = await GET(
                `/marketing/onesheets/el/${onesheetId}`
            );
            let mappedElements = elementsStateBuilder(
                elementsResponse.data,
                data
            );
            setElements(mappedElements);

            // Define as tabs laterais com base nos elementos existentes. Precisamos do tabType pra saber qual form será
            // renderizado pra aquele elemento, e precisamos do tabId pra preencher esse form com os dados do elemento
            // específico (porque pode ter 2 elementos do mesmo tipo, por exemplo)
            let sectionButtons = Object.entries(mappedElements).map(
                ([id, el]) => ({
                    tabType: el.bdType,
                    tabId: id,
                })
            );
            setSectionsTabs(sectionButtons);
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.CONNECTION-ERROR"));
        } finally {
            setLoading(false);
        }
    };

    /**
     * Carrega dados da sheet ao carregar a página
     */
    useEffect(() => {
        if (!onesheetId)
            dialogs.fail(() => window.location.replace("/onesheet"));
        getData();
    }, [onesheetId]);

    /**
     * @description
     * array com os campos de números de redes sociais
     */
    const valuesSocialMedia = [
        "spotify_streams",
        "spotify_fanbase",
        "spotify_playlist_reach",
        "youtube_fanbase",
        "youtube_views",
        "tiktok_fanbase",
        "tiktok_likes",
        "instagram_fanbase",
        "instagram_likes",
        "twitter_fanbase",
        "twitter_likes",
        "facebook_numbers",
        "deezer_numbers",
        "amazon_numbers",
        "apple_numbers",
        "tidal_numbers",
        "soundcloud_numbers",
    ];

    /**
     * Remove caracteres não numéricos de uma string.
     *
     * Esta função recebe uma string contendo caracteres alfanuméricos e não numéricos
     * e retorna uma nova string contendo apenas os caracteres numéricos, removendo
     * qualquer caractere não numérico encontrado.
     *
     * @param {string} value - A string que será tratada, contendo caracteres numéricos e não numéricos.
     * @returns {string} Uma nova string contendo apenas os caracteres numéricos.
     */
    function removeNotNumbers(value) {
        return value.replace(/\D/g, ""); // Expressão regular que substitui todos os caracteres não numéricos por vazio
    }

    /**
     * Quando os metadados são alterados, todos os elementos têm que acompanhar
     * @param {String} field nome do campo que foi alterado
     * @param {*} value novo valor
     */
    const handleMetadataChanged = (field, value) => {
        let updatedElements = { ...elements };
        setMetadata({ ...metadata, [field]: value });
        Object.values(updatedElements).forEach((element) => {
            // Ao selecionar uma cor de background, nãpo pode sobrescrever elementos que tem uma imagem como bg!
            if (element[field]?.charAt(0) === "#") element[field] = value;
        });
        setElements(updatedElements);
    };

    /**
     * Handler de edição de dados. Podem haver campos com nomes iguais em secoes diferentes, e por isso, precisamos
     * especificar qual campo de qual secao estamos editando. Fazemos isso atraves do tabId
     * @param {String} tabId identificador da secao ativa
     * @param {String} field nome do campo
     * @param {*} value valor
     */
    const handleChange = (tabId, field, value) => {
        // Se tiverem sido os metadados que foram alterados, a lógica de execução é outra
        if (tabId === "MAIN") {
            handleMetadataChanged(field, value);
        }
        // Remove caracteres não-numéricos se for algum campo que aceita apenas números
        value = valuesSocialMedia.includes(field)
            ? removeNotNumbers(value)
            : value;
        let updatedElements = { ...elements };
        elements[tabId][field] = value;

        setElements(updatedElements);

        // Se o campo tava marcado de vermelho (pq tinha erro), retira o vermelho ao editá-lo
        if (fieldErrors[tabId]?.includes(field)) {
            setFieldErrors({
                ...fieldErrors,
                [tabId]: fieldErrors[tabId]?.filter((item) => item !== field),
            });
        }
    };

    /**
     * Handler de submissão de formulário. Tem que montar o formulário manualmente nesse caso por causa dos campos de
     * imagem que estavam indo duplicados para o backend ao usar a funcao makeFormDataBody
     * @param e
     */
    async function handleSubmit(e) {
        e.preventDefault();
        setFormSending(true);
        saveAllElements().finally(() => {
            setFormSending(false);
        });
    }

    /**
     * Transforma um elemento do state elements no formato necessário para que o DRF do onilnk consiga salvá-lo,
     * considerando as particularidades de cada elemento para que sejam salvos no formato certo, pra que possam ser
     * carregados do jeito certo quando forem puxados com um GET de lá
     * @param {object} elem elemento em questão
     * Basicamente faz o contrário da elementsStateBuilder
     */
    // const translateElementToBackendAttributes = (elem) => {
    //     let formattedValue;
    //     if (CONSTS.pipeSeparatedElementTypes.includes(elem.bdType)) {
    //         formattedValue = `${elem.typeSelected}|${elem.value}`;
    //     } else if (
    //         CONSTS.objectPipeStringPipeArrayElementTypes.includes(elem.bdType)
    //     ) {
    //         formattedValue = `${JSON.stringify(elem.extraContent)}|${
    //             elem.featuredImage
    //         }|${JSON.stringify(elem.value)}`;
    //     } else if (CONSTS.listValueElementTypes.includes(elem.bdType)) {
    //         formattedValue = JSON.stringify(elem.value);
    //     } else if (
    //         CONSTS.doublePipeSeparatedElementTypes.includes(elem.bdType)
    //     ) {
    //         formattedValue = `${elem.extraContent}|${elem.typeSelected}|${elem.value}`;
    //     } else if (
    //         CONSTS.stringAndJsonValueElementTypes.includes(elem.bdType)
    //     ) {
    //         formattedValue = `${elem.value}|${JSON.stringify(
    //             elem.typeSelected
    //         )}`;
    //     } else if (CONSTS.objectElementTypes.includes(elem.bdType)) {
    //         formattedValue = JSON.stringify(elem.value);
    //     } else {
    //         formattedValue = elem.value;
    //         if (typeof formattedValue === "object") {
    //             formattedValue = "";
    //         }
    //     }
    //     return {
    //         id: elem.bdId || "",
    //         secondary_color: elem.secondary_color || "#fff",
    //         extraContent: elem.extraContent || "",
    //         title: elem.title || "",
    //         subtitle: elem.subtitle || "",
    //         type: elem.bdType,
    //         value: formattedValue || "",
    //         background: elem.background || "",
    //         effect: elem.effect || "",
    //         font_color: elem.fontColor || "#000",
    //         show: elem.show,
    //         page: elem.page,
    //         order: elem.order,
    //     };
    // };

    /**
     * Salva todos os elementos
     * @returns {Promise<void>}
     */
    const saveAllElements = async () => {
        await Promise.all(
            Object.keys(elements).map((el) => handleSaveElement(el))
        );
        let formData = new FormData();
        formData.append("artist", metadata.amplifyus_artist_id);
        formData.append("name", metadata.name);
        formData.append("color_1", metadata.fontColor);
        formData.append("secondary_color", metadata.secondary_color);
        formData.append("background_color", metadata.background);
        try {
            await PUT(`/marketing/onesheets-s/${onesheetId}`, formData);
        } catch (e) {
            console.error(e);
            toast.error("APPS.ONESHEETS.SAVE.ERROR");
        }
        toast.success(t("APPS.ONESHEET.SAVED"));
    };

    /**
     * Handler que salva elemenentos individualmente
     * @param {String} elementUniqueId id atribuído ao elemento no carregamento da página, que permite localizá-lo no state elements
     * @returns {Promise<void>}
     */
    const handleSaveElement = async (elementUniqueId) => {
        if (elementUniqueId === "MAIN") return; // O elemento de metadados não é um objeto PageContent
        // Põe o elemento no formato em que o DRF do Onilnk precisa receber
        let element = translateElementToBackendAttributes(
            elements[elementUniqueId]
        );
        let formData = makeFormDataBody(element);
        try {
            return await PUT(
                `marketing/onesheets/el/${onesheetId}/${element.id}`,
                formData
            );
        } catch (e) {
            console.error(e);
            toast.error(t("APPS.ONESHEETS.SAVE.ERROR"));
        }
    };

    /**
     * Esse useEffect é responsável pela parte de convites
     * se o elemento convite estiver com show=true
     * o botão ancora para o convite será mostrado
     */
    useEffect(() => {
        const allElements = Object.entries(elements);

        allElements.map((element) => {
            if (element[1].bdType === "onipages_invite") {
                setShowInviteButton(element[1].show);
            }
        });
    }, [elements]);

    /**
     * Alterna e atualiza o objeto de estado removendo seções que não estão sendo exibidas
     * e ajusta as cores de fundo com base nos índices alternados das seções.
     *
     * @param {object} state - O objeto de estado atual contendo dados das seções.
     * @param {function} setState - A função de atualização de estado usada para atualizar o estado.
     * @returns {void}
     */
    const toggleBg = (state, setState) => {
        if (!Object.keys(state).length) return;
        let newData = { ...state };
        const keys = Object.keys(newData);
        keys.map((key, index) => {
            if (!newData[key]?.show) {
                delete newData[key];
            }
        });
        // Agora, iteramos sobre as seções que serão mostradas para alternar o has_bg_color
        Object.keys(newData).forEach((section, index) => {
            const sectionName = newData[section].bdType;
            if (
                sectionName !== "onipages_footer" &&
                sectionName !== "MAIN" &&
                sectionName !== "onipages_emphasis"
            ) {
                // As cores do rodapé são fixas
                let sectionHasBgColor = index % 2 !== 0; // Promove a alternância de cores
                newData[section].background = sectionHasBgColor // Se a secao tiver cor de fundo
                    ? state["MAIN"].background // A cor dos títulos é a principal
                    : "#fff"; //newData[section].background; // Senão, fica sendo a cor do fundo
                newData[section].fontColor = sectionHasBgColor
                    ? state["MAIN"].fontColor
                    : state["MAIN"].background;
            }
        });

        return setState(newData);
    };

    useEffect(() => {
        toggleBg(elements, setNewBgElements);
    }, [elements]);

    return (
        <>
            {loading ? (
                <LoadingSpinner size="2xl" />
            ) : (
                <div className={styles.osWrapper}>
                    <ShareOnesheetModal
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        slug={metadata.slug}
                        artistName={metadata.artistName}
                        description={t("APPS.ONESHEET.SHARE-DESC")}
                        descriptionId={"os"}
                    />
                    {/* BOTAO DE ABRIR O MENU LATERAL */}
                    {!menuOpened && (
                        <button
                            title={t("FORMS.ONESHEET.EDIT")}
                            className={`btn rounded ms-3 ${
                                menuOpened
                                    ? styles.editingLandingPage
                                    : styles.editLandingPage
                            }`}
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasScrolling"
                            aria-controls="offcanvasScrolling"
                            onClick={() => setMenuOpened(!menuOpened)}
                        >
                            {t("GENERAL.EDIT")}
                            <FontAwesomeIcon icon={faPencil} className="ms-3" />
                        </button>
                    )}
                    {/* BOTAO DE VOLTAR A PÁGINA */}
                    <button
                        title={t("FORMS.ONESHEET.EDIT")}
                        className={`btn rounded me-3 ${styles.backButton}`}
                        type="button"
                        onClick={() => window.location.replace("/onesheet")}
                    >
                        <FontAwesomeIcon icon={faCaretLeft} className="me-2" />
                        {t("GENERAL.BACK")}
                    </button>
                    <div className="card card-custom">
                        {/* MENU LATERAL */}
                        <div
                            className={`offcanvas offcanvas-start ${
                                menuOpened && "show"
                            }`}
                            data-bs-scroll="true"
                            data-bs-backdrop="false"
                            tabIndex="-1"
                            id="offcanvasScrolling"
                            aria-labelledby="offcanvasScrollingLabel"
                        >
                            <div className="offcanvas-header">
                                <h5
                                    className="offcanvas-title"
                                    id="offcanvasScrollingLabel"
                                >
                                    {`${t("APPS.ONESHEETS.FORM.TITLE")} ${
                                        metadata.name
                                    }`}
                                </h5>
                                <button
                                    className="transition-all hover:bg-[#35354A] p-2 aspect-square hover:rounded-full"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                    onClick={() => setMenuOpened(false)}
                                >
                                    <FontAwesomeIcon icon={faAnglesLeft} />
                                </button>
                            </div>
                            <div className="offcanvas-body">
                                {/* OPCOES DE RESPONSIVIDADE DA PREVIEW */}
                                {showPreviewModes && (
                                    <div
                                        className={`d-flex gap-5 justify-content-center mb-5`}
                                    >
                                        <FontAwesomeIcon
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setResponsive("mobile")
                                            }
                                            icon={faMobile}
                                        />
                                        <FontAwesomeIcon
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setResponsive("tablet")
                                            }
                                            icon={faTablet}
                                        />
                                        <FontAwesomeIcon
                                            className="cursor-pointer"
                                            onClick={() =>
                                                setResponsive("desktop")
                                            }
                                            icon={faDesktop}
                                        />
                                    </div>
                                )}
                                {/* SEÇÕES */}
                                <ul
                                    className={`nav nav-tabs btn-group-vertical w-100 ${
                                        styles.showMenu
                                    } ${
                                        showMenu
                                            ? styles.showMenutrue
                                            : styles.showMenufalse
                                    }`}
                                >
                                    {sectionsTabs.map((section, index) => {
                                        // A seção de metadados e paleta de cores n tem prévia. Então colocaremos pra
                                        // preview jogar pro comeco da página quando o usuário clicar nesse elemento
                                        let tabHref =
                                            section.tabId === "MAIN"
                                                ? sectionsTabs[1].tabId
                                                : section.tabId;
                                        return (
                                            <li
                                                key={index}
                                                className="nav-item w-100"
                                            >
                                                <a
                                                    href={`#${tabHref}`}
                                                    className={clsx(
                                                        `nav-link cursor-pointer btn btn-light-primary mb-2 w-100`,
                                                        {
                                                            active:
                                                                activeSectionTab.id ===
                                                                section.tabId,
                                                        }
                                                    )}
                                                    onClick={() => {
                                                        setShowMenu(false);
                                                        setActiveSectionTab(
                                                            section
                                                        );
                                                    }}
                                                >
                                                    {t(
                                                        `FORMS.ONESHEET.SECTIONS.${section.tabType}`
                                                    )}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <form className="form">
                                    <div className="tab-content pt-3">
                                        {Object.keys(activeSectionTab).length >
                                            0 && (
                                            <>
                                                <HeaderBackInvSection
                                                    onClick={() => {
                                                        setShowMenu(true);
                                                        setActiveSectionTab({});
                                                        if (
                                                            activeSectionTab.tabId ===
                                                            "MAIN"
                                                        )
                                                            saveAllElements();
                                                        else
                                                            handleSaveElement(
                                                                activeSectionTab.tabId
                                                            );
                                                    }}
                                                    title={t(
                                                        `FORMS.ONESHEET.SECTIONS.${activeSectionTab.tabType}`
                                                    )}
                                                />
                                                <InfoDescription
                                                    text={t(
                                                        `FORMS.ONESHEET.SECTIONS.${activeSectionTab.tabType}.HELP`
                                                    )}
                                                />

                                                <div className="tab-pane active">
                                                    {/* Invoca o elemento contido na relação "tipo-elemento", passando as props com base no id da seção */}
                                                    {cloneElement(
                                                        SectionsFormsJsx[
                                                            activeSectionTab
                                                                .tabType
                                                        ],
                                                        {
                                                            tabId: activeSectionTab.tabId,
                                                            data: elements[
                                                                activeSectionTab
                                                                    .tabId
                                                            ],
                                                            setData:
                                                                handleChange,
                                                            fieldErrors:
                                                                fieldErrors[
                                                                    activeSectionTab
                                                                        .tabId
                                                                ],
                                                        }
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {/* FOOTER COM BOTOES DE ACAO */}
                                    {Object.keys(activeSectionTab).length ===
                                        0 && (
                                        <div className="card-footer py-6">
                                            <div className="d-flex justify-content-between">
                                                <CustomSaveButton
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setModalOpen(true);
                                                    }}
                                                    className="btn btn-primary"
                                                    buttonText={""}
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={faShareNodes}
                                                        />
                                                    }
                                                />
                                                <CustomSaveButton
                                                    setHasError={setHasError}
                                                    hasError={hasError}
                                                    type="submit"
                                                    className="btn btn-success"
                                                    onClick={(evt) =>
                                                        handleSubmit(evt)
                                                    }
                                                    formSending={formSending}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                        {/* PREVIEW RESPONSIVA */}
                        <div
                            className={`${
                                menuOpened
                                    ? styles.previewEditing
                                    : styles.wrapper
                            } ${styles[responsive]}`}
                        >
                            {Object.entries(elements).map(([id, el]) => {
                                if (el.show) {
                                    let additionalProps;
                                    
                                    if (el.bdType === "onipages_emphasis") {
                                        additionalProps = {
                                            ...el,
                                            handleChange,
                                            edit: true,
                                            handleSaveElement
                                        }
                                    } else {
                                        additionalProps = el
                                    }

                                    return cloneElement(
                                        PreviewsFormsJsx[el.bdType],
                                        {
                                            data: additionalProps,
                                        }
                                    );
                                }
                            })}
                            {showInviteButton ? (
                                <ScrollTo
                                    showHeight={50}
                                    icon={faEnvelope}
                                    fixed
                                    to="#invite-form"
                                    bg_color={metadata.background}
                                    icon_color={metadata.fontColor}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export { OnesheetForm };
