import { Container, Grid, Image, Link, Text } from "@nextui-org/react";
import styles from "./OnesheetLatestRelease.module.css";
import { Title } from "../Components/Title";
import { Subtitle } from "../Components/Subtitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDeezer,
    faItunesNote,
    faSpotify,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faCirclePlay } from "@fortawesome/pro-light-svg-icons";

/**
 * Prévia para o elemento Último Lançamento do Onesheet
 * @param {object} data informações necessárias pra renderização da prévia
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetLatestReleasePreview = ({ data }) => {
    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container
                className={styles.wrapper}
                justify="center"
                css={{ mw: "1300px", m: "0 auto" }}
            >
                <Grid className={styles.flex1} direction="column">
                    <Image
                        className={styles.featuredImage}
                        width="100%"
                        height="100%"
                        showSkeleton
                        objectFit="cover"
                        alt="Image"
                        src={data?.featuredImage}
                    />
                    <Text
                        color={data?.fontColor}
                        size="16px"
                        className={styles.albumDescription}
                    >
                        {data?.value}
                    </Text>
                    <DspsButtons
                        latestRelease={data.extraContent}
                        bgButtons={data?.fontColor}
                        fontColor={data?.background}
                    />
                </Grid>
                <Grid
                    className={`${styles.flex1} ${styles.latestRelease}`}
                    direction="column"
                >
                    <div className={styles.wrapperTitle}>
                        <Title color={data?.fontColor} text={data?.title} />
                        <Subtitle
                            color={data?.secondary_color}
                            text={data?.subtitle}
                        />
                    </div>
                    {data?.extraContent?.dsp_spotify_id && (
                        <iframe
                            className={`${styles.spotifyIframe}`}
                            src={`https://open.spotify.com/embed/album/${data?.extraContent?.dsp_spotify_id}`}
                            allowFullScreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                            title={data?.title}
                        />
                    )}
                </Grid>
            </Grid.Container>
        </Container>
    );
};

export { OnesheetLatestReleasePreview };

const DspsButtons = ({ latestRelease, bgButtons, fontColor }) => {
    const buttons = Object.keys(latestRelease).filter((link) =>
        link.startsWith("dsp_")
    );

    const platformLinks = {
        dsp_deezer_id: "https://www.deezer.com/track/",
        dsp_itunes_id: "https://music.apple.com/album/",
        dsp_spotify_id: "https://open.spotify.com/album/",
        dsp_youtube_id: "https://www.youtube.com/watch?v=",
        dsp_youtube_music_id: "https://music.youtube.com/watch?v=",
    };

    return (
        <Grid.Container gap={1}>
            {buttons
                ? buttons.map((button, index) => {
                      let icon;
                      const name = button;
                      const parts = name.split("_");
                      const result = name.includes("music")
                          ? `${parts[1]} ${parts[2]}`
                          : parts[1];
                      const capitalizedString =
                          result.charAt(0).toUpperCase() +
                          result.slice(1).toLowerCase();

                      if (result === "itunes") {
                          icon = faItunesNote;
                      } else if (result === "spotify") {
                          icon = faSpotify;
                      } else if (result === "youtube") {
                          icon = faYoutube;
                      } else if (result === "youtube music") {
                          icon = faCirclePlay;
                      } else if (result === "deezer") {
                          icon = faDeezer;
                      }

                      return latestRelease[name] ? (
                          <Grid key={index}>
                              <Link
                                  href={`${platformLinks[button]}${latestRelease[button]}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                  <div
                                      auto
                                      style={{
                                          backgroundColor: bgButtons,
                                          padding: 16,
                                          borderRadius: "50%",
                                          width: 50,
                                          height: 50,
                                      }}
                                      title={capitalizedString}
                                  >
                                      <FontAwesomeIcon
                                          size="xl"
                                          color={fontColor}
                                          icon={icon}
                                      />
                                  </div>
                              </Link>
                          </Grid>
                      ) : null;
                  })
                : null}
        </Grid.Container>
    );
};
