import { useCreateButton } from "../../helpers/subHeaderButtons/createButtonProvider";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import React, { useEffect, useState } from "react";
import { useAuth } from "../auth";
import { PageTitle } from "../../layout/core";
import "./DashboardPage.css";
import { Spinner } from "next2";
import axios from "axios";
import { useAlert } from "../../helpers/utils/CustomAlert";
import Swal from "sweetalert2/src/sweetalert2";
import { loadStripe } from "@stripe/stripe-js";
import { useApi } from "../../helpers/Api/ApiWrapper";
import { useLocation } from "react-router-dom";
import AppDownloadButtons from "../../components/AppDownloadButtons";

const Dashboard = () => {
    const { currentUser } = useAuth();

    if (!currentUser) return null;
    else
        return (
            <>
                <PageTitle
                    translateTitle={false}
                    userName={currentUser.first_name}
                    description={
                        "Veja e gerencie preferências da sua assinatura"
                    }
                >
                    Olá
                </PageTitle>
                <SubscriptionDetails />
            </>
        );
};

const SubscriptionDetails = () => {
    const { auth, currentClient } = useAuth();
    const { alert } = useAlert();
    const [loading, setLoading] = useState(true);
    const [signature, setSignature] = useState();
    const [clickLoading, setClickLoading] = useState(false);
    const { t } = useTranslate();
    const { POST } = useApi();

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get("subscribed")) {
            alert.fire(
                "Seja bem-vindo ao AmplifyUs Premium!",
                "Você já pode aproveitar tudo que o AmplifyUs app tem a oferecer!",
                "success"
            );
        }
    }, [location]);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

    const handleCheckout = async () => {
        if (clickLoading) return;
        setClickLoading(true);
        const stripe = await stripePromise;

        try {
            const response = await POST(
                `core/create-stripe-checkout-session/${process.env.REACT_APP_AMPLIFYUS_PREMIUM_STRIPE_PRODUCT_ID}`
            );

            const session = response.data;

            const { error } = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (error) {
                console.error(error);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setClickLoading(false);
        }
    };

    function replaceSubstring(
        originalString,
        substringToReplace,
        newSubstring
    ) {
        return originalString.split(substringToReplace).join(newSubstring);
    }

    const getSignature = async () => {
        try {
            const response = await axios.get(
                `${replaceSubstring(
                    process.env.REACT_APP_API_URL,
                    "v1",
                    "v2"
                )}core/subscription-details`,
                {
                    headers: {
                        Authorization: `Bearer ${auth?.api_token}`,
                    },
                }
            );
            setSignature(response.data);
        } catch (err) {
            if (err.response.status === 404)
                setLoading(!Boolean(currentClient));
            console.error(err);
        }
    };

    useEffect(
        () => setLoading(!(currentClient && signature)),
        [currentClient, signature]
    );

    useEffect(() => {
        if (!currentClient) return;
        getSignature();
    }, [currentClient]);

    const cancelPlan = async () => {
        if (clickLoading) return;
        alert
            .fire({
                title: "Tem certeza de que quer cancelar o seu plano atual?",
                text: "A assinatura será cancelada ao final do período de faturamento atual",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Manter assinatura",
                cancelButtonText: "Cancelar assinatura",
                reverseButtons: true,
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading(),
            })
            .then((result) => {
                if (!result.isConfirmed) {
                    setClickLoading(true);
                    axios
                        .delete(
                            `${replaceSubstring(
                                process.env.REACT_APP_API_URL,
                                "v1",
                                "v2"
                            )}core/subscription-details`,
                            {
                                headers: {
                                    Authorization: `Bearer ${auth?.api_token}`,
                                },
                            }
                        )
                        .then((response) => {
                            alert
                                .fire(
                                    "Assinatura cancelada",
                                    `Sua assinatura será cancelada automaticamente em ${
                                        signature.status === "active"
                                            ? signature.current_period_end
                                            : signature.status === "trialing"
                                            ? signature.trial_end
                                            : "-"
                                    }.`,
                                    "success"
                                )
                                .then(() => window.location.reload());
                        })
                        .catch(() =>
                            alert.fire(
                                t("GENERAL.OOPS"),
                                t("GENERAL.ACTION-FAILED"),
                                "error"
                            )
                        )
                        .finally(() => setClickLoading(false));
                }
            });
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                currentClient && (
                    <div className="flex flex-col gap-5">
                        <span className="text-gray-800 fs-2 fw-bolder me-1">
                            Meu plano
                        </span>
                        <div className="flex flex-row justify-between">
                            <span>
                                {
                                    currentClient.manager_data.signature
                                        .description
                                }
                            </span>{" "}
                            {!currentClient.manager_data.signature
                                .is_premium_user && (
                                <>
                                    {clickLoading ? (
                                        <Spinner />
                                    ) : (
                                        <span onClick={() => handleCheckout()}>
                                            Upgrade
                                        </span>
                                    )}
                                </>
                            )}
                        </div>
                        {signature && (
                            <>
                                <span>
                                    {signature.status === "active"
                                        ? "Você faz parte do"
                                        : signature.status === "trialing"
                                        ? "Você está no período de avaliação gratuita do"
                                        : "Você faz parte do"}{" "}
                                    {signature.plan_name}
                                    {" desde "}
                                    {signature.start_date}
                                </span>
                                <hr />
                                <span>
                                    Valor do plano: {signature.plan_amount}/
                                    {signature.interval}
                                </span>
                                <span>
                                    {signature.will_renew
                                        ? `Renovação agendada para ${
                                              signature.status === "active"
                                                  ? signature.current_period_end
                                                  : signature.status ===
                                                    "trialing"
                                                  ? signature.trial_end
                                                  : "-"
                                          }`
                                        : `Cancelamento agendado para ${
                                              signature.status === "active"
                                                  ? signature.current_period_end
                                                  : signature.status ===
                                                    "trialing"
                                                  ? signature.trial_end
                                                  : "-"
                                          }`}
                                </span>
                                {signature.next_invoice_value &&
                                    signature.next_invoice_date && (
                                        <span>
                                            Próxima fatura:{" "}
                                            {signature.next_invoice_value} (
                                            {signature.next_invoice_date})
                                        </span>
                                    )}
                                {signature.will_renew && (
                                    <>
                                        {clickLoading ? (
                                            <Spinner />
                                        ) : (
                                            <span onClick={() => cancelPlan()}>
                                                Cancelar plano
                                            </span>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )
            )}
            <AppDownloadButtons />
        </>
    );
};

export {};

const DashboardPage = () => {
    const { setHref } = useCreateButton();
    const [loading, setLoading] = useState(true);
    setHref("");

    useEffect(() => {
        setLoading(false);
    }, []);

    return <>{loading ? <Spinner /> : <Dashboard />}</>;
};

export { DashboardPage };
