import { createContext, useContext } from "react";
import { useApi } from "./ApiWrapper";

const UploadOnesheetFileToBucketContext = createContext({
    uploadOnesheetFileToBucket: (file, fileName, filePath, isUrl = false) => {},
});

const useOnesheetFileUploader = () => {
    return useContext(UploadOnesheetFileToBucketContext);
};

const UploadOnesheetFileToBucketProvider = ({ children }) => {
    const { POST } = useApi();

    /**
     * Faz upload de arquivos do OneSheet para o bucket do OniLnk. O bucket do OniLnk é público, e como as OneSheets
     * serão hosteadas lá, faz sentido que os arquivos estejam lá.
     * @param {File} file arquivo em memória para ser submetido
     * @param {String} fileName nome do arquivo
     * @param {String} filePath path para upload do arquivo. Enviar no formato '[dev/strging/prod]/onesheet/[onesheetId]/[tabId]/[fieldName]'.
     * @param {Boolean} isUrl indica se o arquivo está em formato de blob ou de url. Default false
     * @returns {Promise<*>} path do arquivo upado
     */
    const uploadOnesheetFileToBucket = async (
        file,
        fileName,
        filePath,
        isUrl = false
    ) => {
        try {
            let formData = new FormData();
            formData.append("content", file);
            formData.append("fileName", fileName);
            formData.append("filePath", filePath);
            const response = await POST(
                `upload-onesheet-file-to-bucket${isUrl ? "?mode=url" : ""}`,
                formData
            );
            return response.data;
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <UploadOnesheetFileToBucketContext.Provider
            value={{ uploadOnesheetFileToBucket }}
        >
            {children}
        </UploadOnesheetFileToBucketContext.Provider>
    );
};

export { UploadOnesheetFileToBucketProvider, useOnesheetFileUploader };
