import React, { createContext, useContext, useState } from "react";

const CreateButtonContext = createContext({
    href: "",
    setHref: () => {},
});

const useCreateButton = () => {
    // Definição do Hook
    return useContext(CreateButtonContext);
};

const CreateButtonProvider = ({ children }) => {
    const [href, setHref] = useState("");

    return (
        <CreateButtonContext.Provider value={{ href, setHref }}>
            {children}
        </CreateButtonContext.Provider>
    );
};

export { CreateButtonProvider, useCreateButton };
