import { createContext, useContext } from "react";
import Swal from "sweetalert2/src/sweetalert2";
import "/node_modules/@sweetalert2/theme-dark/dark.scss";
import { useTranslate } from "../i18n/useTranslate";
import { useApi } from "../Api/ApiWrapper";
import { toast } from "react-toastify";
import "./CustomAlert.css";

const AlertContext = createContext({
    /* Valores default do contexto */
    alert: Swal.mixin({
        customClass: {
            title: "titleGray",
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
    }),
    dialogs: {
        deleteConfirm: (deleteEndpoint, deletedMessage = "", callback) => {},
        success: (callback) => {},
        fail: (callback) => {},
        forms: {
            validationErrors: (callback) => {},
        },
    },
});

const useAlert = () => {
    /* Hook customizado pra não precisar ficar importando o Swal e o css com o dark theme e criando o mixin toda vez que
          for usar alerta */
    return useContext(AlertContext);
};

const AlertsProvider = ({ children }) => {
    /*
          Provider pra componentizar alguns diálogos padrão
           */
    const { t } = useTranslate();
    const { DELETE } = useApi();
    const { alert } = useAlert();

    const dialogs = {
        deleteConfirm: (deleteEndpoint, deletedMessage = "", callback) => {
            /* Diálogo de confirmar deleção de um objeto */
            alert
                .fire({
                    title: t("GENERAL.ARE-YOU-SURE"),
                    text: t("GENERAL.CANNOT-UNDO"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: t("GENERAL.DELETE"),
                    cancelButtonText: t("GENERAL.CANCEL"),
                    reverseButtons: true,
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading(),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        DELETE(deleteEndpoint)
                            .then((response) => {
                                if (response.status === 204) {
                                    alert
                                        .fire(
                                            t("GENERAL.DELETED"),
                                            deletedMessage,
                                            "success"
                                        )
                                        .then((res) => callback(res));
                                } else {
                                    alert.fire(
                                        t("GENERAL.OOPS"),
                                        t("GENERAL.ACTION-FAILED"),
                                        "danger"
                                    );
                                }
                            })
                            .catch(() =>
                                alert.fire(
                                    t("GENERAL.OOPS"),
                                    t("GENERAL.ACTION-FAILED"),
                                    "danger"
                                )
                            );
                    }
                });
        },
        deleteDoubleConfirm: (
            deleteEndpoint,
            confirmString = t("GENERAL.CONFIRM"),
            deletedMessage = "",
            callback
        ) => {
            /* Diálogo de confirmar deleção de um objeto */
            alert.fire({
                title: t("GENERAL.ARE-YOU-SURE"),
                text: t("GENERAL.SUPER-DELETE-CONFIRM").replace(
                    "{confirmString}",
                    confirmString
                ),
                input: "text",
                inputAttributes: {
                    autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonColor: "#f00",
                cancelButtonColor: "#c4c4c4",
                showLoaderOnConfirm: true,
                confirmButtonText: t("GENERAL.DELETE"),
                cancelButtonText: t("GENERAL.CANCEL"),
                allowOutsideClick: () => !Swal.isLoading(),
                preConfirm: (confirmText) => {
                    if (confirmText !== confirmString || confirmText === "") {
                        Swal.showValidationMessage(
                            t("GENERAL.SUPER-DELETE-CONFIRM.FAILED")
                        );
                    } else {
                        return DELETE(deleteEndpoint)
                            .then((response) => {
                                if (response.status === 204) {
                                    alert
                                        .fire(
                                            t("GENERAL.DELETED"),
                                            deletedMessage,
                                            "success"
                                        )
                                        .then((res) => callback(res));
                                } else {
                                    alert.fire(
                                        t("GENERAL.OOPS"),
                                        t("GENERAL.ACTION-FAILED"),
                                        "danger"
                                    );
                                }
                            })
                            .catch((err) => {
                                Swal.showValidationMessage(
                                    `Erro: ${err.response.data}`
                                );
                            });
                    }
                },
            });
            // .then((result) => {
            //   if (result.isConfirmed) {
            //   }
            // });
        },
        success: (callback) => {
            alert.fire(t("GENERAL.SUCCESS")).then((response) => {
                if (callback) callback(response);
                else console.log(response);
            });
        },
        fail: (callback) => {
            alert.fire(t("GENERAL.ACTION-FAILED")).then((response) => {
                if (callback) callback(response);
                else console.log(response);
            });
        },
        fatalError: (callback) => {
            alert.fire(t("GENERAL.FATAL-ERROR")).then((response) => {
                if (callback) callback(response);
                else console.log(response);
            });
        },
        forms: {
            validationErrors: () => {
                toast.error(t("FORMS.GENERAL.VAL-ERRORS"));
            },
        },
    };
    return (
        <AlertContext.Provider value={{ alert, dialogs }}>
            {children}
        </AlertContext.Provider>
    );
};

export { AlertsProvider, useAlert };
