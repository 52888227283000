import { createContext, useContext, useState } from "react";
import * as authHelper from "./AuthHelpers";

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => {},
    currentUser: undefined,
    currentClient: {
        manager_available_features: [],
    },
    currentArtist: {},
    setCurrentUser: () => {},
    setCurrentClient: () => {},
    setCurrentArtist: () => {},
    impersonateArtist: () => {},
    logout: () => {},
};

const AuthContext = createContext(initAuthContextPropsState);

const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(authHelper.getAuth());
    const [currentUser, setCurrentUser] = useState();
    const [currentClient, setCurrentClient] = useState();
    const [currentArtist, setCurrentArtist] = useState();
    const saveAuth = (auth) => {
        // setAuth(auth);
        if (auth) {
            authHelper.setAuth(auth);
            setAuth(auth);
        } else {
            authHelper.removeAuth();
        }
    };

    const logout = () => {
        saveAuth(undefined);
        setCurrentUser(undefined);
        setCurrentArtist(undefined);
        localStorage.removeItem("currentArtist");
        setCurrentClient(undefined);
    };

    /**
     * "Impersona" um artista para gravadoras
     * @param {obj} artist
     */
    function impersonateArtist(artist) {
        if (artist) {
            // Add id do artista selecionado no localStorage
            localStorage.setItem("currentArtist", JSON.stringify(artist));
            // Desliga modo gravadora
            localStorage.removeItem("recorderModeOn");
            setCurrentArtist(artist);
        } else {
            // Remove o id do artista selecionado do localStorage
            localStorage.removeItem("currentArtist");
            // Liga o modo gravadora (se nao tem nenhum artista selecionado, é modo gravadora)
            localStorage.setItem("recorderModeOn", "true");
            setCurrentArtist(null);
        }
        window.location.reload();
    }

    return (
        <AuthContext.Provider
            value={{
                auth,
                saveAuth,
                currentUser,
                setCurrentUser,
                currentClient,
                setCurrentArtist,
                impersonateArtist,
                currentArtist,
                setCurrentClient,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, useAuth };
