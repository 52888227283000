import React, { useEffect, useState } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { InputDefault } from "../../../components/Form/InputDefault";
import { useCreateButton } from "../../../helpers/subHeaderButtons/createButtonProvider";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { useAuth } from "../../auth";
import { Button } from "next2";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";

/**
 * Componente de seção de incorporação do Spotify para a página do Onesheet.
 *
 * @param {string} tabId - O ID da seção.
 * @param {Object} data - Os dados da seção.
 * @param {Function} setData - Função para atualizar os dados da seção.
 * @returns {JSX.Element} O elemento JSX da seção de incorporação do Spotify.
 */
export const OnesheetInstagramSection = ({ tabId, data, setData }) => {
    const { setHref } = useCreateButton();
    const { GET } = useApi();
    const { currentUser } = useAuth();
    const { t } = useTranslate();

    const [feed, setFeed] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    /**
     * Pega os posts
     * @returns {Promise<void>}
     */
    const fetchFeed = async () => {
        setLoading(true);
        const response = await GET("/ds/instagram-posts");
        let data = response.data;
        let apiFeed = data.map((item) => {
            return {
                type: item.type,
                data: item,
            };
        });

        if (data.length > 0) setDisabled(true);

        setFeed(apiFeed);
        setLoading(false);
    };

    useEffect(() => {
        if (!feed) return;
        if (feed.length > 0) setDisabled(true);
        const lastPosts = feed.slice(0, 9);
        setData(tabId, "value", lastPosts);
    }, [feed]);

    /**
     * Roda a funcao de pegar os posts quando a página carrega
     */
    useEffect(() => {
        setHref("");
        if (data.value.length > 0) setDisabled(true);
    }, []);

    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data?.show}
                            onChange={(evt) =>
                                setData(tabId, "show", evt.target.checked)
                            }
                        />
                    </label>
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>

            {!currentUser.facebookToken ? (
                <>
                    {t(
                        "FORMS.ONESHEET.SECTIONS.onipages_instagram.NOT_CONNECT_ACCOUNT"
                    )}

                    <div className="flex gap-1 items-center">
                        <span>
                            {t(
                                "FORMS.ONESHEET.SECTIONS.onipages_instagram.NOT_CONNECT_ACCOUNT_STEPS"
                            )}
                        </span>
                        <span className="font-bold">
                            {t(
                                "FORMS.ONESHEET.SECTIONS.onipages_instagram.NOT_CONNECT_ACCOUNT_STEP_ONE"
                            )}
                        </span>
                        <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
                        <span className="font-bold">
                            {t(
                                "FORMS.ONESHEET.SECTIONS.onipages_instagram.NOT_CONNECT_ACCOUNT_STEP_TWO"
                            )}
                        </span>
                    </div>

                    <Link
                        to="/core/profile"
                        state={{
                            selectedTab: "settings",
                            expandedAccordion: "connected-accounts",
                        }}
                        className="flex justify-center items-center mt-3 block btn btn-sm bg-[#0095E8] hover:bg-[#0097e8de] text-white"
                    >
                        {t(
                            "FORMS.ONESHEET.SECTIONS.onipages_instagram.CONNECT_ACCOUNT"
                        )}

                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                    </Link>
                </>
            ) : (
                <Button
                    color="primary"
                    variant="solid"
                    isLoading={loading}
                    isDisabled={disabled}
                    onPress={() => fetchFeed()}
                >
                    {t("FORMS.ONESHEET.SECTIONS.onipages_instagram.GET_POSTS")}
                </Button>
            )}
        </>
    );
};
