import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { PagedListComponent } from "../../../components/PagedListComponent";
import { CardCourse } from "../../../components/Cards/CardCourse";

/**
 * Lista de cursos
 * @type {React.FunctionComponent<object>}
 */
const Courses = observer(() => {
    const [courses, setCourses] = useState([]);

    return (
        <>
            <PagedListComponent
                data={courses}
                setData={setCourses}
                filters={{}}
                baseUrl={"/academy/courses"}
            >
                <div className="row flex-row g-6 g-xl-9">
                    {courses.map((course, index) => {
                        return (
                            <div
                                className="col-md-6 col-xl-4"
                                key={`pr-4${index}`}
                            >
                                <CardCourse
                                    thumbnail={course.thumbnail}
                                    badgeColor="primary"
                                    status={course.get_categories_display}
                                    statusColor="primary"
                                    name={course.name}
                                    description={course.short_description}
                                    redirectTo={`/academy/course/${course.id}`}
                                />
                            </div>
                        );
                    })}
                </div>
            </PagedListComponent>
        </>
    );
});

export { Courses };