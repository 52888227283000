import { isNotEmpty } from "../usefulFunctions";

const inspectFormData = (formData) => {
    /*
    Imprime no console os dados do formulário passados como parâmetro. Função criada para facilitar a inspeção de forms
    no JS. Link para o autor do código: https://stackoverflow.com/questions/17066875/how-to-inspect-formdata
     */
    for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
    }
};

/**
 * Função que gera o FormData dos dados de formulário passados como parâmetro.
 * @param {Object} data objeto que contém os dados do form
 * @param {Array[str]} excludedFields lista de campos que devem ser ignorados, provavelmente porque vao ser tratados depois
 * @returns {FormData}
 */
const makeFormDataBody = (data, excludedFields = []) => {
    /*
    Função que gera o FormData dos dados de formulário passados como parâmetro.
    Args:
        data: objeto FormData com os campos e dados do form
     */
    let formDataBody = new FormData();
    // Iterando sobre o dicionário do objeto principal
    for (let [field, value] of Object.entries(data)) {
        if (!excludedFields.includes(field)) {
            // Só adiciona o campo no formData se nao tiver nos campos exluidos
            if (!isNotEmpty(value)) value = "";
            // Evita de mandar 'null' pro backend, mandando string vazia no lugar
            else if (value.constructor === Object) value = value.value; // Se for um dict, queremos na verdade o valor dele
            formDataBody.append(field, value);
        }
    }
    return formDataBody;
};

/**
 * @description
 * Atualiza o objeto FormData com um arquivo.
 *
 * @param {String} field - Nome do campo pra adicionar o arquivo
 * @param {FormData} formData - O objeto FormData a ser atualizado com o arquivo.
 * @param {Array} file - Um array contendo informações do arquivo. Deve conter pelo menos uma entrada com a chave 'file'.
 */
const addFileToFormData = (field, formData, file) => {
    // Verifica se o estado "file" está definido e contém alguma imagem
    if (file && file.length > 0) {
        // Adiciona a imagem de capa ao FormData
        formData.append(field, file[0].file);
    }
};

export { inspectFormData, makeFormDataBody, addFileToFormData };
