import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { CompleteSignupFormContext } from "../CompleteSignupFormContext";
import YoutubeAccountConfirm from "../formComponents/YoutubeAccountConfirm";

/**
 * Passo de confirmação de conta em dsps secundárias
 * @type {React.FunctionComponent<object>}
 */
const ConfirmDspAccountsStep = observer(() => {
  const form = useContext(CompleteSignupFormContext);

  return (
    <>
      <div className="w-100">
        <YoutubeAccountConfirm form={form.data} handleChange={form.setForm} />
      </div>
    </>
  );
});

export { ConfirmDspAccountsStep };
