import React, { useEffect, useState } from "react";
import { Container, Grid } from "@nextui-org/react";
import { Title } from "../Components/Title";
import { useTranslate } from "../../../helpers/i18n/useTranslate";

/**
 * Componente de visualização do Spotify Embed para a seção de pré-visualização do Onesheet.
 * @module OnesheetSpotifyEmbedPreview
 * @param {Object} data - Dados do elemento.
 * @param {string} data.id - ID do elemento Container.
 * @param {string} data.background - Cor de fundo do Container.
 * @param {string} data.fontColor - Cor da fonte do título.
 * @returns {JSX.Element} Componente de visualização do Spotify Embed.
 */
export const OnesheetInstagramPreview = ({ data }) => {
    const { t } = useTranslate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!data) return;
        if (data)
        setLoading(true);
        setLoading(false);
    }, []);

    const lastPosts = data.value.slice(0, 9);

    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container gap={2} css={{ mw: "1300px", m: "0 auto" }}>
                <Grid xs={12} direction="column" css={{ w: "100%" }}>
                    <Title
                        color={data?.fontColor}
                        text={t("FORMS.ONESHEET.SECTIONS.onipages_instagram")}
                    />
                    
                    <div className="grid grid-cols-3 gap-4">
                        {lastPosts.length > 0 && lastPosts.map((post, index) => {
                            return (
                                <div key={index} className="relative">
                                    <img
                                        className="w-full h-full aspect-square object-cover rounded-lg"
                                        src={post.data.image}
                                        alt={post.data.caption}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Grid>
            </Grid.Container>
        </Container>
    );
};
