import Select from "react-select";
import React, { useEffect, useState } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import makeAnimated from "react-select/animated";
import { useThemeMode } from "../../../layout/theme-mode/ThemeModeProvider";

const animatedComponents = makeAnimated();

const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
};
const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
};

/**
 * Componente para evitar duplicação de código nos elementos "select" na aplicacao
 * @param {{label, value}} defaultValue opção pré-selecionada no formato {label, value}
 * @param {{label, value}} value valor definido no formato {label, value}
 * @param {[{label, value}]} options lista de opções no formato [{label, value}]
 * @param {function(any): void} onChange acao que vai ser executada quando selecionar uma opcao
 * @param {string:null} placeholder texto padrão que aparece no input
 * @param {boolean} isClearable indica se o select pode ter opcao nula
 * @param {boolean} isSearchable indica se o select é buscável
 * @param {boolean} isMultiple indica se o select é multiple choice
 * @param {boolean} isDisabled indica se o select está desabilitado
 * @param {boolean} grouped indica se as opções devem ser agrupadas. Para agrupar, envie as options no seguinte formato: [{label:'groupName', options:[{label:'label', value:val},...]},...]
 * @param {string} className nome personalizado pra classe
 * @param {string} className nome personalizado pra classe
 * @returns {JSX.Element}
 * @constructor
 */
const CustomSelect = ({
    defaultValue,
    value,
    options,
    onChange,
    placeholder = null,
    isClearable = false,
    isSearchable = false,
    isMultiple = false,
    isDisabled = false,
    grouped = false,
    className,
}) => {
    const { t } = useTranslate();
    const { mode } = useThemeMode();

    const colourStyles = {
        menu: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: mode === "dark" ? "#1B1B29" : "#F1F3F5",
            padding: 0,
            margin: 0,
            color: isSelected ? "green" : "black",
        }),
        control: (styles, { isSelected }) => ({
            ...styles,
            backgroundColor: mode === "dark" ? "#1B1B29" : "#F1F3F5",
            color: isSelected ? "red" : "#929292",
            margin: 0,
            padding: ".5rem",
            borderRadius: "1rem",
            height: "auto", // isso que faz o input ficar maior caso seja isMultiple
            border: "none",
        }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                backgroundColor: mode === "dark" ? "#1B1B29" : "#F1F3F5",
                marginBottom: "1rem",
                padding: ".5rem",
                margin: 0,
                fontSize: "12px",
                color: isFocused ? "#0095E8" : "#929292",
                fontWeight: isFocused ? "bold" : "normal",
                cursor: isFocused ? "pointer" : "default",
                ":active": {
                    ...styles[":active"],
                    backgroundColor: isSelected ? "white" : "#929292",
                },
            };
        },
        input: (styles) => ({
            ...styles,
            padding: 0,
            margin: 0,
        }),
        placeholder: (styles) => ({
            ...styles,
            color: "#7E7E8B",
        }),
        singleValue: (styles, { isSelected }) => ({
            ...styles,
            color: "#929292",
            fontWeight: "bold",
        }),
    };

    const [placeHolderText, setPlaceHolderText] = useState();

    useEffect(() => {
        if (!placeholder)
            setPlaceHolderText(
                t(`FORMS.GENERAL.PICK-ONE${isMultiple ? ".OR.MORE" : ""}`)
            );
        else setPlaceHolderText(placeholder);
    }, [placeholder]);

    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    return (
        <Select
            className={className}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            options={options}
            placeholder={placeHolderText}
            isClearable={isClearable}
            isSearchable={isSearchable}
            components={animatedComponents}
            styles={colourStyles}
            isMulti={isMultiple}
            isDisabled={isDisabled}
            formatGroupLabel={grouped ? formatGroupLabel : null}
        />
    );
};

export { CustomSelect };
