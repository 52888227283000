import React, { useEffect, useState } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { makeId } from "onipkg";
import { InputDefault } from "../../../components/Form/InputDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import getBase64 from "../../../helpers/utils/getBase64";
import { Text } from "@nextui-org/react";

/**
 * Componente responsável por renderizar a seção de arquivos do Onesheet.
 *
 * @param {string} tabId - O ID da aba atual.
 * @param {Object} data - Os dados da seção de arquivos.
 * @param {Function} setData - Função para atualizar os dados da seção de arquivos.
 * @returns {JSX.Element} O elemento JSX que representa a seção de arquivos do Onesheet.
 */
export const OnesheetFilesSection = ({ tabId, data, setData }) => {
    const { t } = useTranslate();
    const initialLinksValues = {
        id: `file-${makeId(7)}`,
        file: "",
        title: "",
        fileName: "",
        size: "",
    };
    const [files, setFiles] = useState(data.value);

    /**
     * Manipula a adição de um novo arquivo à lista de arquivos.
     *
     * @param {Event} e - O evento de submissão que acionou a função.
     * @returns {void}
     */
    const handleAddNewFile = (e) => {
        e.preventDefault();
        setFiles((prevState) => [...prevState, initialLinksValues]);
    };

    /**
     * Manipula a remoção de um arquivo específico da lista de arquivos.
     *
     * @param {Event} e - O evento de submissão que acionou a função.
     * @param {number} index - O índice do arquivo a ser removido da lista.
     * @returns {void}
     */
    const removeFile = (e, index) => {
        e.preventDefault();
        let allFiles = [...files];
        allFiles.splice(index, 1);
        setFiles(allFiles);
    };

    /**
     * Manipula a alteração de um campo no array de arquivos.
     *
     * @param {Event} e - O evento de alteração.
     * @param {number} index - O índice do arquivo no array.
     * @param {string} fieldName - O nome do campo a ser alterado.
     * @returns {Promise<void>} - Uma promessa vazia.
     */
    const handleChange = async (e, index, fieldName) => {
        const { value } = e.target;

        // Cria uma cópia do array de files
        const updatedFiles = [...files];

        // Verifica se o file atual já existe ou se é um novo
        const isExistingFile = updatedFiles[index];

        if (isExistingFile) {
            updatedFiles[index] = {
                ...updatedFiles[index],
                [fieldName]: value,
            };
            if (fieldName === "file") {
                const selectedFile = e.target.files[0];
                if (updatedFiles[index]) {
                    updatedFiles[index] = {
                        ...updatedFiles[index],
                        file: await getBase64(selectedFile),
                        fileName: selectedFile.name,
                        size: selectedFile.size,
                    };
                }
            } else {
                // Atualiza o valor do campo específico no objeto de índice 'index'
                updatedFiles[index] = {
                    ...updatedFiles[index],
                    [fieldName]: value,
                };
            }
        } else {
            // Cria um novo file com os valores padrão
            const newFile = {
                id: `file-${makeId(7)}`,
                file: "",
                title: "",
                fileName: "",
                size: "",
            };

            // Atualiza o valor do campo específico do novo file
            newFile[fieldName] = value;

            // Adiciona o novo file ao array
            updatedFiles.push(newFile);
        }

        // Atualiza o estado 'files' com o array modificado
        setFiles(updatedFiles);
    };

    //"seta" os arquivos na propriedade value para serem lidos no preview
    useEffect(() => {
        setData(tabId, "value", files);
    }, [files]);

    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data?.show}
                            onChange={(evt) =>
                                setData(tabId, "show", evt.target.checked)
                            }
                        />
                    </label>
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>

            {files.map((file, index) => {
                const isFileTooLarge = file.size > 5242880;
                return (
                    <div className="row mb-6" key={index}>
                        <div className="mb-6 mx-0">
                            <InputDefault
                                type="text"
                                label={`Título #${index + 1}`}
                                id={`title${index}`}
                                handleChange={(e) =>
                                    handleChange(e, index, "title")
                                }
                                value={file.title}
                            />
                        </div>
                        <div className="mb-6 mx-0">
                            <InputDefault
                                type="file"
                                label={`Arquivo #${index + 1}`}
                                id="file"
                                handleChange={(e) =>
                                    handleChange(e, index, "file")
                                }
                            />
                            {/* se o arquivo for muito grande, será mostrado uma mensagem, senão, mostra o nome do arquivo */}
                            <Text color="white">
                                {t(
                                    "FORMS.ONESHEET.SECTION.onipages_files.FILE-SIZE"
                                )}
                            </Text>
                            {isFileTooLarge ? (
                                <p className="text-danger mt-3">
                                    {t(
                                        "FORMS.ONESHEET.SECTIONS.onipages_files.ERROR"
                                    )}{" "}
                                    <strong>5mb</strong>
                                </p>
                            ) : (
                                <>
                                    {file.fileName ? (
                                        <div className="d-flex align-items-center mt-3">
                                            <label className="form-label mb-0 mr-3">
                                                {t(
                                                    "FORMS.ONESHEET.SECTIONS.onipages_files.CURRENT-FILE"
                                                )}{" "}
                                            </label>{" "}
                                            <p className="fw-bold">
                                                {file.fileName}
                                            </p>
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                        <button
                            className="btn btn-sm bg-danger-500 hover:bg-danger-600 ow mb-6 mx-0 font-bold"
                            onClick={(e) => removeFile(e, index)}
                        >
                            <FontAwesomeIcon
                                icon={faTrashAlt}
                                className="mr-3"
                            />
                            {t(
                                "FORMS.ONESHEET.SECTIONS.onipages_files.REMOVE-FILE"
                            )}
                        </button>
                    </div>
                );
            })}

            <button
                className="btn btn-primary"
                onClick={(e) => handleAddNewFile(e)}
            >
                {" "}
                <FontAwesomeIcon icon={faPlus} className="mr-3" />
                {t("FORMS.ONESHEET.SECTIONS.onipages_files.NEW")}
            </button>
        </>
    );
};
