/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./screens/registration/Registration";
import { CompleteSignup } from "./screens/registration/CompleteSignup";
import { ForgotPassword } from "./screens/ForgotPassword";
import { Login } from "./screens/Login";
import { ResetPassword } from "./screens/ResetPassword";
import { toAbsoluteUrl } from "../../helpers/utils/toAbsoluteUrl";
import { Footer } from "../../layout/components/Footer";

const AuthLayout = () => {
    useEffect(() => {
        document.body.style.backgroundImage = "none";
        return () => {};
    }, []);

    return (
        <div
            className={`flex flex-col bg-fixed flex-[1_0_auto] bg-cover bg-bottom bg-no-repeat min-h-screen h-full`}
            style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    "/media/illustrations/gradient/fullhd2.jpg"
                )})`,
            }}
        >
            {/* begin::Content */}
            <div className="flex flex-col justify-center items-center flex-[1_0_auto] p-10 lg:pb-14">
                {/* begin::Logo */}
                <Link to="/auth">
                    <div className="mb-12">
                        <img
                            alt="Logo"
                            src={toAbsoluteUrl("/media/logos/default-dark.svg")}
                            className="theme-dark-show h-45px"
                        />
                        <img
                            alt="Logo"
                            src={toAbsoluteUrl("/media/logos/default-dark.svg")}
                            className="theme-light-show h-45px"
                        ></img>
                    </div>
                </Link>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className="lg:w-[700px] bg-[#1F1E2C] rounded shadow-sm p-10 lg:p-14 mx-auto">
                    <Outlet />
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <Footer />
            {/* end::Footer */}
        </div>
    );
};

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="registration" element={<Registration />} />
            <Route path="complete-signup" element={<CompleteSignup />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            {/*<Route path="reset-password" element={<ResetPassword />} />*/}
            <Route index element={<Login />} />
        </Route>
    </Routes>
);

export { AuthPage };
