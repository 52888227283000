import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import { useAuth } from "../auth";
import { PagedListComponent } from "../../components/PagedListComponent";
import { useCreateButton } from "../../helpers/subHeaderButtons/createButtonProvider";
import { PageTitle } from "../../layout/core";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsis,
    faShareNodes,
    faTrash,
    faCircleInfo,
} from "@fortawesome/pro-light-svg-icons";
import { useAlert } from "../../helpers/utils/CustomAlert";
import { Card, CardHeader } from "next2";
import { CardTitle } from "../../components/Cards/CardTitle";
import { CardSubtitle } from "../../components/Cards/CardSubtitle";
import { ShareModal } from "../../components/ShareModal";
import { Tooltip } from "@nextui-org/react";

/**
 * Listagem de onesheets do artista (aqui ainda não chama o onilnk. As informações exibidas aqui estão guardadas no
 * nosso bd)
 * @type {React.FunctionComponent<object>}
 */
const OnesheetList = observer(() => {
    const { setHref } = useCreateButton();
    const [onesheets, setOnesheets] = useState([]);
    const [filters, setFilters] = useState({});
    const [filteredOnesheets, setFilteredOnesheets] = useState([]);

    /**
     * Define a rota para o botão de novo
     */
    useEffect(() => {
        setHref("/onesheet/wizard");
    }, []);

    /**
     * Mantém o estado de onesheets inalterado, usa setOnesheets pra renderizar os resultados filtrados
     */
    useEffect(() => {
        setOnesheets(onesheets);
    }, [onesheets]);

    return (
        <>
            <PagedListComponent
                data={filteredOnesheets}
                setData={setFilteredOnesheets}
                filters={filters}
                baseUrl="/marketing/onesheets-s?projectsnull=true"
            >
                <div className="row g-6 g-xl-9">
                    {filteredOnesheets.map((sheet, index) => (
                        <div className="col-md-6 col-xl-4" key={`o-${index}`}>
                            <OnesheetCardWidget
                                id={sheet.id}
                                name={sheet.name}
                                type={sheet.get_type_display}
                                typeCode={sheet.type}
                                slug={sheet.slug}
                                artistName={sheet.artist_name}
                            />
                        </div>
                    ))}
                </div>
            </PagedListComponent>
        </>
    );
});

/**
 * Card de item de onesheet para listagem
 * @param {String} id id do elemento da onesheet
 * @param {String} name name da onesheet
 * @param {String} type tipo da onesheet
 * @param {String} typeCode código do bd do tipo da onesheet
 * @param {String} slug slug para acesso à onesheet
 * @param {String} artistName nome do artista da onesheet
 * @constructor
 */
const OnesheetCardWidget = ({ id, name, type, typeCode, slug, artistName }) => {
    const { t } = useTranslate();
    const { dialogs } = useAlert();
    const { currentClient, currentArtist } = useAuth();
    const [modalOpen, setModalOpen] = useState(false);

    /**
     * Função para apagar uma onesheet
     */
    const deleteSheetHandler = async () => {
        dialogs.deleteConfirm(`/marketing/onesheets/${id}`, "", () =>
            window.location.reload()
        );
    };

    return (
        <>
            <Card className="p-8 bg-body">
                <CardHeader className="p-0 flex justify-between items-start">
                    <Link to={typeCode === "DISC" ? "#" : `/onesheet/${id}`}>
                        <div className="flex flex-col md:mb-4">
                            {typeCode === "DISC" && (
                                <div className={"flex justify-start mb-2"}>
                                    <Tooltip
                                        content={t(
                                            "APPS.ONESHEETS.DISCOGRAPHY.TOOLTIP"
                                        )}
                                        placement={"right"}
                                    >
                                        <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            size={"lg"}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                            <CardTitle
                                className="hover:text-sky-400"
                                text={name}
                            />
                            {!currentArtist && (
                                <CardSubtitle text={artistName} />
                            )}
                            <CardSubtitle
                                text={
                                    typeCode === "DISC"
                                        ? t("APPS.ONESHEETS.DISCOGRAPHY")
                                        : type
                                }
                            />
                        </div>
                    </Link>
                    {/* TODO CONSERTAR BOTÃO */}
                    <div className="me-0">
                        <button
                            className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                            data-kt-menu-flip="top-end"
                        >
                            <FontAwesomeIcon
                                icon={faEllipsis}
                                className="fa-lg"
                            />
                        </button>
                        <div
                            className="menu menu-sub menu-sub-dropdown"
                            data-kt-menu="true"
                        >
                            <div className="d-flex flex-column">
                                <button
                                    onClick={() => setModalOpen(true)}
                                    data-kt-menu-dismiss="true"
                                    className="btn btn-sm btn-light btn-active-light-primary mx-2 my-2"
                                >
                                    {t("GENERAL.SHARE")}{" "}
                                    <FontAwesomeIcon
                                        icon={faShareNodes}
                                        className="ms-2"
                                    />
                                </button>
                                {/* todo funcionalidade de duplicar sheet */}
                                {/*{currentClient?.canCreate && (*/}
                                {/*    <button*/}
                                {/*        onClick={() => duplicateSheetHandler()}*/}
                                {/*        data-kt-menu-dismiss="true"*/}
                                {/*        className="btn btn-sm btn-light btn-active-light-danger"*/}
                                {/*    >*/}
                                {/*        {t("GENERAL.DUPLICATE")}*/}
                                {/*    </button>*/}
                                {/*)}*/}
                                {currentClient?.canDelete &&
                                    typeCode !== "DISC" && (
                                        <button
                                            onClick={() => deleteSheetHandler()}
                                            data-kt-menu-dismiss="true"
                                            className="btn btn-sm btn-light btn-active-light-danger mx-2 mb-2"
                                        >
                                            {t("GENERAL.DELETE")}
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="ms-2"
                                            />
                                        </button>
                                    )}
                            </div>
                        </div>
                    </div>
                </CardHeader>
            </Card>
            <ShareModal
                externalLink
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                slug={`${process.env.REACT_APP_ONESHEET_URL_PREFIX}${slug}`}
                artistName={artistName}
                description={t("APPS.ONESHEET.SHARE-DESC")}
                descriptionId={"os"}
                title={t("APPS.ONESHEET.SHARE")}
                buttonText={t("APPS.CATALOG.PROJECT.LINKFIRE.ACCESS")}
                shareText={t(
                    "APPS.CATALOG.PROJECT.LINKFIRE.SHARE-DEFAULT-TEXT"
                )}
            />
        </>
    );
};

const OnesheetListWrapper = observer(() => {
    const { t } = useTranslate();
    return (
        <>
            <PageTitle description={t("INTRO.ONESHEETS")} breadcrumbs={[]}>
                APPS.ONESHEETS.TITLE
            </PageTitle>
            <OnesheetList />
        </>
    );
});

export { OnesheetListWrapper };
