import { Container, Grid } from "@nextui-org/react";
import React from "react";
import { Title } from "../Components/Title";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { Social } from "../Components/FeaturedNumbers/Social";

const OnesheetFeaturedNumbersPreview = ({ data }) => {
    const { t } = useTranslate();
    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container
                justify="flex-start"
                css={{ mw: "1300px", m: "0 auto" }}
            >
                <Grid>
                    <Title
                        color={data?.fontColor}
                        text={t(
                            "FORMS.ONESHEET.SECTIONS.onipages_featured-numbers"
                        )}
                    />
                    <Social
                        colors={[data?.fontColor, data?.secondary_color]}
                        data={data.value[0]}
                    />
                </Grid>
            </Grid.Container>

            {/* Descomentar quando tiver dados para mostrar em gráficos */}

            {/* <Spacer y={4} /> */}

            {/* <Grid.Container
                    css={{ mw: "1300px", m: "0 auto" }}
                    className={styles.dataWrapper}
                >
                    <div className={styles.data}>
                        <Title color={data?.color_1} text={"Top 5 Países"} />
                        <TopCities
                            colors={[data?.color_1, data?.color_2]}
                            data={[96.88, 75, 60, 33, 10]}
                            categories={[
                                "Brasil",
                                "Estados Unidos",
                                "Japão",
                                "França",
                                "Austrália",
                            ]}
                        />
                    </div>
                    <div className={styles.data}>
                        <Title color={data?.color_1} text={"Top 5 Cidades"} />
                        <TopCities
                            colors={[data?.color_1, data?.color_2]}
                            data={[90.8, 79.58, 44.56, 22.48, 11.96]}
                            categories={[
                                "Rio de Janeiro",
                                "São Paulo",
                                "Brasília",
                                "Salvador",
                                "Recife",
                            ]}
                        />
                    </div>
                </Grid.Container> */}

            {/* <Spacer y={5} />

                <Grid.Container
                    alignItems="center"
                    justify="center"
                    css={{ mw: "1300px", m: "0 auto" }}
                    className={styles.dataWrapper}
                >
                    <div className={styles.data}>
                        <Title
                            color={data?.color_1}
                            text={"Gênero por idade"}
                        />
                        <GenderByAge
                            color_1={data?.color_1}
                            color_2={data?.color_2}
                        />
                    </div>
                    <div className={styles.data}>
                        <Title color={data?.color_1} text={"Gênero"} />
                        <Gender
                            color_1={data?.color_1}
                            color_2={data?.color_2}
                        />
                    </div>
                </Grid.Container> */}
        </Container>
    );
};

export { OnesheetFeaturedNumbersPreview };
