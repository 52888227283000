import { useEffect } from "react";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import styles from "./CustomButton.module.css";

/**
 * Botão customizado para submissão de formulários
 * @param {boolean} hasError state que indica se houve algum erro, pra chacoalhar o botao
 * @param {String} type tipo do botao. ex, em botoes de submissao de formulário, o tipo é "submit"
 * @param {String} className classes customizadas
 * @param {function} onClick callback de acao a ser executada no clique do botao
 * @param {JSX.Element} children elementos a serem renderizados dentro do botao
 * @param {object} style estilos customizados
 * @param {boolean} formSending indica se o formulário está sendo enviado, pra desabilitar o botao e impedir clickspam
 * @param {function} setHasError setter do state que indica se houve algum erro, pra chacoalhar o botao
 * @param {boolean} disabled indica se o botao está habiltiado ou nao
 * @param {String} form nome do elemento dado ao form relacionado para submetê-lo especificamente (caso haja)
 * @param {JSX.Element} icon ícone a ser renderizado antes do texto
 * @param {JSX.Element} afterIcon ícone a ser renderizado após o texto
 * @param {String} buttonText texto do botao (SEM TAG DE TRADUCAO!!)
 * @returns {JSX.Element}
 * @constructor
 */
const CustomSaveButton = ({
    hasError,
    type,
    className,
    onClick,
    children,
    style,
    formSending,
    setHasError = () => {},
    disabled = false,
    form = null,
    icon = null,
    afterIcon = null,
    buttonText = "GENERAL.SAVE",
}) => {
    const { t } = useTranslate();

    const removeClassShake = () => {
        setTimeout(() => {
            setHasError(false);
        }, 1000);
    };

    useEffect(() => {
        if (hasError) {
            setHasError(true);
        }

        removeClassShake();
    }, [hasError]);

    return (
        <button
            type={type}
            className={`p-3 rounded ${className} ${hasError ? styles.hasError : ""}`}
            style={style || {}}
            onClick={onClick}
            disabled={disabled || formSending}
            form={form}
        >
            {!formSending && (
                <div className="flex items-center justify-center w-full font-bold">
                    {" "}
                    {icon}{" "}
                    {buttonText && (
                        <span className="mx-2"> {t(buttonText)} </span>
                    )}{" "}
                    {afterIcon}{" "}
                </div>
            )}
            {formSending && (
                <span
                    className="indicator-progress font-bold"
                    style={{ display: "block" }}
                >
                    {t("GENERAL.LOADING")}...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            )}
            {children}
        </button>
    );
};

export { CustomSaveButton };
