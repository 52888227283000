// Import FilePond
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useTranslate } from "../../../helpers/i18n/useTranslate";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

/**
 * Input customizado para upload de arquivos
 * @param {Boolean} showImage mostra a imagem atual, se houver
 * @param {String} fieldName nome do input pro html
 * @param {String} fieldLabel label do input. envolver em tag t()
 * @param {String} inputClassName nome da classe para o input
 * @param {String} uploadedFileSrc path do arquivo upado
 * @param {object} files state de arquivos
 * @param {function} setFiles setter dos arquivos
 * @param {Boolean} allowMultiple permite upload de mais de um arquivo? padrao: false
 * @param {string} maxHeight tamanho do preview. padrao: 15vh
 * @param {function} removable callback para remover o arquivo atual. opcional
 * @param {string} buttonText texto que vai no botão
 * @returns {JSX.Element}
 * @constructor
 */
const CustomFileInput = ({
    showImage,
    fieldName,
    fieldLabel,
    inputClassName,
    uploadedFileSrc,
    files,
    setFiles,
    allowMultiple = false,
    maxHeight = "15vh",
    removable = null,
    buttonText,
    wrapperClassName
}) => {
    const { t } = useTranslate();
    const [showImagePreview, setShowImagePreview] = useState(false); // State pra indicar se a prévia deve aparecer
    /**
     * Altera o state showImagePreview. Ele controla se a prévia da imagem que veio do backend deve aparecer. Ela deve
     * aparecer somente se houver imagem carregada no backend (uploadedFileSrc existe) e se não houver nada pra ser
     * uploadado no filepond (não há arquivos no state files).
     */
    useEffect(() => {
        setShowImagePreview(Boolean(uploadedFileSrc && files.length === 0));
    }, [uploadedFileSrc, files]);
    return (
        <>
            {fieldLabel ? (
                <label htmlFor={fieldName} className="form-label">
                    {fieldLabel}
                </label>
            ) : null}
            <div className={`row align-items-center ${wrapperClassName}`}>
                <div className={inputClassName}>
                    {showImagePreview && ( // Se ja houver algum arquivo upado, mostra a preview dele
                        <div className="d-flex align-items-center justify-center">
                            {showImage && (
                                <img
                                    className="rounded aspect-video w-100 mb-3"
                                    src={uploadedFileSrc}
                                    style={{
                                        maxHeight: maxHeight,
                                        objectFit: "cover",
                                    }}
                                    alt="Thumbnail"
                                />
                            )}
                            {removable && ( // Se for passada uma callback para remocao de arquivo, renderiza o X vermelho
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    color="red"
                                    size="xl"
                                    className="ms-3"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        // Se clicar no X
                                        removable(); // Chama a callback de remover o arquivo no state designado
                                        setShowImagePreview(false); // Fala que nao tem arquivo atual pra ocultar a prévia
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div className={`col-lg-12 col-md-9 col-sm-12 cursor-pointer`}>
                    <FilePond
                        files={files}
                        allowMultiple={allowMultiple}
                        onupdatefiles={setFiles}
                        labelIdle={buttonText || t("FORMS.FILEPOND.PLACEHOLDER")}
                    ></FilePond>
                </div>
            </div>
        </>
    );
};

export { CustomFileInput };
