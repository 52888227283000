import React from "react";
import {
    EmailShareButton,
    FacebookShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    RedditIcon,
    TelegramIcon,
    WhatsappIcon,
} from "react-share";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXTwitter} from "@fortawesome/free-brands-svg-icons";

/**
 * Renderiza os botões de compartilhamento de link
 * @param {String} text texto que acompanha o link em algumas plataformas
 * @param {String} url link que será compartilhado
 * @param {String} title título que vai com o link compartilhado
 * @returns {JSX.Element}
 * @constructor
 */
const ShareButtons = ({text, url, title}) => {
    const blackCircleIcon = (
        <div
            style={{
                backgroundColor: "black",
                width: "33px",
                height: "33px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <FontAwesomeIcon icon={faXTwitter} color={"white"} size={"xl"}/>
        </div>
    );

    return (
        <div className="d-flex gap-5 justify-text-center justify-content-xl-start">
            <EmailShareButton url={url} subject={title}>
                <EmailIcon size={32} round/>
            </EmailShareButton>
            <FacebookShareButton url={url}>
                <FacebookIcon size={32} round/>
            </FacebookShareButton>
            <RedditShareButton url={url} title={title}>
                <RedditIcon size={32} round/>
            </RedditShareButton>
            <TelegramShareButton url={url}>
                <TelegramIcon size={32} round/>
            </TelegramShareButton>
            <TwitterShareButton url={url} title={title}>
                {blackCircleIcon}
            </TwitterShareButton>
            <WhatsappShareButton url={url}>
                <WhatsappIcon size={32} round/>
            </WhatsappShareButton>
        </div>
    );
};

export {ShareButtons};

