import React from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { InputDefault } from "../../../components/Form/InputDefault";

/**
 * Componente de seção de incorporação do Spotify para a página do Onesheet.
 * 
 * @param {string} tabId - O ID da seção.
 * @param {Object} data - Os dados da seção.
 * @param {Function} setData - Função para atualizar os dados da seção.
 * @returns {JSX.Element} O elemento JSX da seção de incorporação do Spotify.
 */
export const OnesheetSpotifyEmbedSection = ({ tabId, data, setData }) => {
    const { t } = useTranslate();
    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data?.show}
                            onChange={(evt) =>
                                setData(tabId, "show", evt.target.checked)
                            }
                        />
                    </label>
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>
            <InputDefault 
                label={t('FORMS.ONESHEET.SECTIONS.onipages_spotify.URL')}
                id="spotify_url"
                type="url"
                handleChange={(e) => setData(tabId, "value", e.target.value)}
                value={data.value}
            />
        </>
    );
};
