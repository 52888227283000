/* eslint-disable react-hooks/exhaustive-deps */
import { FC, createContext, useContext, useEffect, useState } from "react";
import { WithChildren } from "../../../_metronic/helpers";

export interface PageLink {
    title: string;
    path: string;
    isActive: boolean;
    isSeparator?: boolean;
}

export interface PageDataContextModel {
    pageTitle?: string;
    setPageTitle: (_title: string) => void;
    pageDescription?: string;
    setPageDescription: (_description: string) => void;
    pageBreadcrumbs?: Array<PageLink>;
    setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void;
    translateTitle?: Boolean;
    setTranslateTitle: (_value: Boolean) => void;
    userName?: string;
    setUserName: (_value: string) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
    setPageTitle: (_title: string) => {},
    setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
    setPageDescription: (_description: string) => {},
    setTranslateTitle: (_value: Boolean) => {},
    setUserName: (_value: string) => {},
});

const PageDataProvider: FC<WithChildren> = ({ children }) => {
    const [pageTitle, setPageTitle] = useState<string>("");
    const [pageDescription, setPageDescription] = useState<string>("");
    const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
    const [translateTitle, setTranslateTitle] = useState<Boolean>(true);
    const [userName, setUserName] = useState<string>("");

    const value: PageDataContextModel = {
        pageTitle,
        setPageTitle,
        pageDescription,
        setPageDescription,
        pageBreadcrumbs,
        setPageBreadcrumbs,
        translateTitle,
        setTranslateTitle,
        userName,
        setUserName,
    };
    return (
        <PageDataContext.Provider value={value}>
            {children}
        </PageDataContext.Provider>
    );
};

function usePageData() {
    return useContext(PageDataContext);
}

type Props = {
    description?: string;
    breadcrumbs?: Array<PageLink>;
    translateTitle?: Boolean;
    userName?: string;
};

const PageTitle: FC<Props & WithChildren> = ({
    children,
    description,
    breadcrumbs,
    translateTitle,
    userName,
}) => {
    const {
        setPageTitle,
        setPageDescription,
        setPageBreadcrumbs,
        setTranslateTitle,
        setUserName,
    } = usePageData();
    useEffect(() => {
        if (children) {
            setPageTitle(children.toString());
        }
        return () => {
            setPageTitle("");
        };
    }, [children]);

    useEffect(() => {
        if (description) {
            setPageDescription(description);
        }
        return () => {
            setPageDescription("");
        };
    }, [description]);

    useEffect(() => {
        if (breadcrumbs) {
            setPageBreadcrumbs(breadcrumbs);
        }
        return () => {
            setPageBreadcrumbs([]);
        };
    }, [breadcrumbs]);

    useEffect(() => {
        if (typeof translateTitle == "boolean") {
            setTranslateTitle(translateTitle);
        }
        return () => {
            setTranslateTitle(true);
        };
    }, [translateTitle]);

    useEffect(() => {
        if (userName) {
            setUserName(userName);
        }
        return () => {
            setUserName("");
        };
    }, [userName]);

    return <></>;
};

const PageDescription: FC<WithChildren> = ({ children }) => {
    const { setPageDescription } = usePageData();
    useEffect(() => {
        if (children) {
            setPageDescription(children.toString());
        }
        return () => {
            setPageDescription("");
        };
    }, [children]);
    return <></>;
};

export { PageDescription, PageTitle, PageDataProvider, usePageData };
