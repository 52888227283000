import React, { useEffect, useState } from "react";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import { useApi } from "../../helpers/Api/ApiWrapper";
import getUrlParams from "../../helpers/utils/getUrlParams";
import clsx from "clsx";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../LoadingSpinner";

const PagedListComponent = ({
    children,
    data,
    setData,
    filters,
    baseUrl,
    searchTerm,
}) => {
    const { t } = useTranslate();
    const { GET } = useApi();
    const [totalResults, setTotalResults] = useState(0);
    const [perPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [next, setNext] = useState(null);
    const [previous, setPrevious] = useState(null);
    const [loading, setLoading] = useState(true);
    const [apiUrl, setApiUrl] = useState(baseUrl);

    /**
     * Faz a chamada de API pra pegar os resultados que tem que ser mostrados e define as páginas de next e previous pro
     * gerenciamento de paginação
     * @param {String} url
     * @returns {Promise<void>}
     */
    const getResults = async (url) => {
        try {
            let response = await GET(url);
            let data = response.data;
            let results = data.results;
            setTotalResults(data.count);
            setNext(data.next);
            setPrevious(data.previous);
            setCurrentPage(parseInt(getUrlParams("page", url)) || 1); // Se n tem o parametro page, é pq ta na primeira
            setData(results || []);
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ACTION-FAILED"));
        } finally {
            setLoading(false);
        }
    };

    /**
     * Faz a primeira chamada de api pra pegar os resultados iniciais quando a tela carrega
     */
    useEffect(() => {
        let params = "";
        let isFirstFilter = true;
        for (const [field, value] of Object.entries(filters)) {
            if (value || typeof value === "boolean") {
                params += isFirstFilter ? "?" : "&";
                params += `${field}=${value}`;
                isFirstFilter = false;
            }
        }

        setApiUrl(baseUrl + params);
    }, [filters, searchTerm]);

    useEffect(() => {
        getResults(apiUrl);
    }, [apiUrl]);

    /**
     * Faz a chamada de API pra pegar resultados com base em uma página específica
     * @param {Integer} page
     */

    const setPage = (page) => {
        let pageParamChar = Object.keys(filters).length > 0 ? "&" : "?";
        getResults(`${apiUrl}${pageParamChar}page=${page}`);
    };

    /**
     * Renderiza os quadradinhos com os números das páginas
     * @returns {*[]}
     */
    const pages = () => {
        let pageButtons = [];

        // Adiciona botão de página anterior
        if (previous) {
            pageButtons.push(
                <li className="page-item" key={`p-previous`}>
                    <button
                        className="page-link"
                        onClick={() => setPage(currentPage - 1)}
                    >
                        {"<"}
                    </button>
                </li>
            );
        }

        // Adiciona botões de páginas numeradas
        for (let page = 1; page <= Math.ceil(totalResults / perPage); page++) {
            if (
                page === 1 ||
                page === currentPage - 1 ||
                page === currentPage ||
                page === currentPage + 1 ||
                page === Math.ceil(totalResults / perPage)
            ) {
                pageButtons.push(
                    <li
                        className={clsx("page-item", {
                            active: page === currentPage,
                        })}
                        key={`p-${page}`}
                    >
                        <button
                            className="page-link"
                            onClick={() => setPage(page)}
                        >
                            {page}
                        </button>
                    </li>
                );
            } else if (
                (page === currentPage - 2 && currentPage > 3) ||
                (page === currentPage + 2 &&
                    currentPage < Math.ceil(totalResults / perPage) - 2)
            ) {
                // Adiciona botões de "..."
                pageButtons.push(
                    <li className="page-item" key={`p-ellipsis-${page}`}>
                        <span className="page-link">...</span>
                    </li>
                );
            }
        }

        // Adiciona botão de página próxima
        if (next) {
            pageButtons.push(
                <li className="page-item" key={`p-next`}>
                    <button
                        className="page-link"
                        onClick={() => setPage(currentPage + 1)}
                    >
                        {">"}
                    </button>
                </li>
            );
        }

        return pageButtons;
    };

    return (
        <>
            {/* COMPONENTES ESPECÍFICOS DE CADA TELA */}
            <div className="mt-3">
                {loading ? <LoadingSpinner size={"2xl"}/> : children}
            </div>
            {/* COMPONENTES GERAIS A TODAS AS TELAS QUE PRECISAM DE PAGINAÇÃO */}
            <div className="d-flex flex-stack flex-wrap pt-10">
                <div className="fs-6 fw-bold text-gray-700">
                    {/* TEXTO: "Mostrando x de y registros" */}
                    {data.length > 0 ? (
                        <>
                            {t("GENERAL.LIST-PAGINATION")
                                .replace("$len$", data.length)
                                .replace("$total$", totalResults)}
                        </>
                    ) : (
                        <>{t("GENERAL.NO-RECORDS-FOUND")}</>
                    )}
                </div>

                <ul className="pagination">
                    {pages()}
                </ul>
            </div>
        </>
    );
};

export { PagedListComponent };
