import React, { useState } from "react";
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import { useTranslate } from "../../../../../../helpers/i18n/useTranslate";
import { faCaretRight, faForward } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toAbsoluteUrl } from "../../../../../../helpers/utils/toAbsoluteUrl";

function StepByStepFacebookLoginModal({ isOpen, toggle, loginFacebook }) {
    const { t } = useTranslate();
    const [step, setStep] = useState(0);

    const nextStep = () => {
        if (step < 6) {
            setStep(step + 1);
        }
    };

    const lastStep = () => {
        setStep(6);
    };

    const close = () => {
        setStep(0);
        toggle();
    };

    const tutorialStepText = {
        0: (
            <span>
                Para conectar suas contas do Instagram e Facebook ao AmplifyUs é
                necessário possui uma conta Business Meta. Siga os próximos
                passos.
            </span>
        ),
        1: (
            <div className="flex flex-col gap-5">
                <span>
                    Vá até o seu perfil no Instagram e pressione o ícone Menu.
                </span>
                <img src={toAbsoluteUrl("/media/tutorials/meta/1.png")} />
            </div>
        ),
        2: (
            <div className="flex flex-col gap-5">
                <span>
                    Em “Configurações e atividade”, selecione “Tipo e
                    ferramentas de conta”, na aba “Para Profissionais”.
                </span>
                <img src={toAbsoluteUrl("/media/tutorials/meta/2.png")} />
            </div>
        ),
        3: (
            <div className="flex flex-col gap-5">
                <span>
                    Selecione “Mudar para conta profissional” na aba Tipo de
                    Conta e preencher o formulário na sequência.
                </span>
                <img src={toAbsoluteUrl("/media/tutorials/meta/3.png")} />
            </div>
        ),
        4: (
            <div className="flex flex-col gap-5">
                <span>
                    Agora que seu perfil se tornou “Criador de Conteúdo”, acesse
                    a função “Ferramentas e controles para criadores de
                    conteúdo”.
                </span>
                <img src={toAbsoluteUrl("/media/tutorials/meta/4.png")} />
            </div>
        ),
        5: (
            <div className="flex flex-col gap-5">
                <span>
                    Conecte sua conta do Instagram a uma Página do Facebook
                    associada ao seu perfil.
                </span>
                <img src={toAbsoluteUrl("/media/tutorials/meta/5.png")} />
            </div>
        ),
        6: (
            <span>
                Tudo pronto!! Agora você já pode conectar seu perfil Meta ao
                AmplifyUs!
            </span>
        ),
    };

    return (
        <>
            <Transition show={isOpen}>
                <Dialog className="relative z-10" onClose={close}>
                    <TransitionChild
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </TransitionChild>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                            <TransitionChild
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <DialogPanel className="flex flex-col justify-between h-500px relative transform overflow-hidden rounded-lg bg-[#121212] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="mt-3 text-center sm:mt-5">
                                        <DialogTitle
                                            as="h2"
                                            className="text-3xl font-semibold text-white"
                                        >
                                            Conectar minha conta Meta
                                        </DialogTitle>
                                        {step > 0 && step < 6 && (
                                            <DialogTitle
                                                as="h1"
                                                className="text-2xl font-bold text-gray-700"
                                            >
                                                {step}º Passo
                                            </DialogTitle>
                                        )}
                                        <div className="mt-2">
                                            <p className="text-xl text-white">
                                                {tutorialStepText[step]}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-6 mb-3 flex flex-col gap-5"
                                        aria-hidden="true"
                                    >
                                        <button
                                            className="inline-flex h-57px items-center w-full justify-center rounded-md bg-white px-3 py-2 text-lg font-semibold text-[#30034F] shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#30034F] sm:col-start-2"
                                            onClick={() => {
                                                if (step < 6) {
                                                    nextStep();
                                                } else {
                                                    loginFacebook();
                                                    close();
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCaretRight}
                                                className="mr-3"
                                            />
                                            {step === 0
                                                ? "Seguir passo-a-passo"
                                                : step < 6
                                                ? t("INTRO.NEXT")
                                                : t("INTRO.CONNECT")}
                                        </button>
                                        {step === 0 && (
                                            <span
                                                className="inline-flex h-57px items-center w-full justify-center px-3 py-2 text-lg font-semibold text-[#0AC4F9] shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#0AC4F9] sm:col-start-2 cursor-pointer"
                                                onClick={lastStep}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faForward}
                                                    className="mr-3"
                                                />{" "}
                                                Pular
                                            </span>
                                        )}
                                        <div className="overflow-hidden rounded-full bg-white">
                                            <div
                                                className="h-2 rounded-full bg-[#0C09DB]"
                                                style={{
                                                    width: `${
                                                        (step / 6) * 100
                                                    }%`,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

export default StepByStepFacebookLoginModal;
