import React from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-duotone-svg-icons";
import { ShareButtons } from "../../components/ShareButtons";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import { InfoForFields } from "../InfoForFields";

/**
 * Modal para compartilhamento de OneSheet
 * @param {Boolean} modalOpen indica se o modal está aberto ou fechado
 * @param {function} setModalOpen setter para abrir/fechar o modal
 * @param {String} slug slug da onesheet
 * @param {String} artistName nome do artista
 * @param {string} title - título do modal
 * @param {string} buttonText - texto que vai no botão
 * @param {boolean} externalLink - se for preciso um botão redirecionador essa prop define isso
 * @param {string} shareText - texto de compartilhamento em redes sociais
 * @param {string} description texto de descrição do modal
 * @param {string} descriptionId identificador para o tooltip
 * @returns {JSX.Element}
 * @constructor
 */
const ShareModal = ({
    modalOpen,
    setModalOpen,
    slug,
    artistName,
    title,
    buttonText,
    externalLink,
    shareText,
    description,
    descriptionId,
}) => {
    const { t } = useTranslate();
    return (
        <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                {description ? (
                    <>
                        <InfoForFields
                            id={descriptionId}
                            text={description}
                            place="bottom"
                        />
                    </>
                ) : null}
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex mb-5">
                    <input
                        type="text"
                        className="w-100 me-5 form-control form-control-lg form-control-solid"
                        disabled
                        value={slug}
                        onChange={(e) => e.preventDefault()}
                    />
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                            navigator.clipboard.writeText(slug);
                            toast.info(t("GENERAL.LINK.COPIED"));
                        }}
                    >
                        {" "}
                        <FontAwesomeIcon icon={faCopy} />{" "}
                    </button>
                </div>
                <div className="flex justify-center mb-5">
                    <ShareButtons
                        url={shareText
                            .replace("$ARTIST$", artistName)
                            .replace("$LINK$", slug)}
                        title={artistName}
                    />
                </div>
                {externalLink ? (
                    <div className="flex flex-col items-center mb-5">
                        <span className="mb-5">{t("GENERAL.OR")}</span>
                        <a
                            className="btn btn-primary "
                            href={slug}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {buttonText}
                        </a>
                    </div>
                ) : null}
            </Modal.Body>
        </Modal>
    );
};

export { ShareModal };
