import { useEffect } from "react";
import form_api from "../../../helpers/Api";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useAlert } from "../../../helpers/utils/CustomAlert";

/**
 * Função que lida com a callback do Google Adwords. Essa função é chamada após o usuário autorizar a conexão entre a
 * conta dele e a conta do Google. Renderiza um loading enquanto faz a requisição para a API para obter o refresh token
 * e depois outra requisição para salvar esse token no usuário, redirecionando-o para a tela em que estava anteriormente
 * logo em seguida.
 * @returns {JSX.Element}
 */
const GoogleAdsCallbackHandler = () => {
    const { PUT } = useApi();
    const artistId = localStorage.getItem("aid");
    const { alert } = useAlert();

    /**
     * Pega o token de refresh do google
     * @returns {Promise<string>}
     */
    const getRefreshtoken = async () => {
        // Pega o state inicial, do localStorage, que foi informado pelo método authorize do nosso backend
        const state = localStorage.getItem("state");
        // Pega o state que foi informado pelo Google na URL
        const params = new URLSearchParams(
            new URL(window.location.href).search
        );
        const informedState = params.get("state");
        // Se os states não forem iguais, é possível que seja um ataque CSRF
        if (state !== informedState) {
            return;
        }
        // Remove o state do localStorage pq não precisamos dele mais
        localStorage.removeItem("state");
        // Faz a requisição para a API para obter o token de refresh
        let formData = new FormData();
        formData.append("authorization_response", window.location.href);
        const response = await form_api.post(
            `auth/google-account-get-refresh-token`,
            formData
        );
        return response?.data?.refresh_token;
    };

    /**
     * Salva o token de refresh no usuário
     * @param {String} token refresh_token
     * @returns {Promise<void>}
     */
    const saveRefreshToken = async (token) => {
        if (!artistId) throw new Error("No artist id");
        let formData = new FormData();
        formData.append("id", artistId);
        formData.append("google_refresh_token", token);
        // Faz a chamada pra atualizar o usuário no BD
        await PUT(`core/artist-google-data/${artistId}`, formData);
        localStorage.removeItem("aid");
        // Mensagem de sucesso e redirecionamento de volta pro app
        alert
            .fire("Sua conta do Google foi conectada ao AmplifyUs!")
            .then(() => window.location.replace("/back-to-app"));
    };

    useEffect(() => {
        if (!artistId) return; // Só dá início ao processo se já tiver conseguido obter o usuário
        getRefreshtoken()
            .then((token) => {
                if (!token) {
                    // Se não tiver token é pq deu pau
                    alert
                        .fire(
                            "Algo deu errado! Por favor, tente novamente mais tarde."
                        )
                        .then(() => window.location.replace("/back-to-app"));
                }
                saveRefreshToken(token)
                    // CÓDIGO THEN COMENTADO PORQUE O SAVEREFRESHTOKEN REDIRECIONA O USUÁRIO DE VOLTA PRO APP CASO DÊ TUDO CERTO
                    // .then(() => {
                    //     // Pega a página anterior que o usuário estava
                    //     const previousPage = localStorage.getItem(
                    //         "socialLoginSourceLocation"
                    //     );
                    //     // Limpa o localStorage da variável que não precisamos mais
                    //     localStorage.removeItem("socialLoginSourceLocation");
                    //     // Redireciona o usuário para a página que ele estava anteriormente
                    //     window.location.href = previousPage;
                    // })
                    .catch((err) => {
                        console.error(err);
                        alert
                            .fire(
                                "Algo deu errado! Por favor, tente novamente mais tarde."
                            )
                            .then(() =>
                                window.location.replace("/back-to-app")
                            );
                    });
            })
            .catch((err) => {
                console.error(err);
                alert
                    .fire(
                        "Algo deu errado! Por favor, tente novamente mais tarde."
                    )
                    .then(() => window.location.replace("/back-to-app"));
            });
    }, [artistId]);
    return <LoadingSpinner size="2xl" />;
};

export { GoogleAdsCallbackHandler };
