import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { CustomFileInput } from "../../../components/Form/CustomFileInput";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useOnesheetFileUploader } from "../../../helpers/Api/UploadOnesheetFileToBucket";
import {
    faDeezer,
    faItunesNote,
    faSpotify,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faCirclePlay } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputDefault } from "../../../components/Form/InputDefault";

/**
 * Formulário de Último Lançamento para onesheet
 * @param {String} tabId id unique da secao
 * @param {object} data valores do elemento
 * @param {function} setData setter do dado (handleChange)
 * @param {array} fieldErrors erros de validacao
 *
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetLatestReleaseSection = ({
    tabId,
    data,
    setData,
    fieldErrors,
}) => {
    const { onesheetId } = useParams();
    const { uploadOnesheetFileToBucket } = useOnesheetFileUploader();
    const { t } = useTranslate();
    const [featuredImage, setFeaturedImage] = useState([]);

    /**
     * Setter do extraContent no data quando a logoImage muda (ou seja, quando alguem sobe algum arquivo lá). Fazemos
     * isso pra mudar a preview na hora que a pessoa subir uma imagem nova, e aí com isso ainda tem o bônus de que
     * quando formos fazer o save, o background já salva a imagem no bucket e retorna o path pra ser salvo no backend
     */
    useEffect(() => {
        if (featuredImage.length > 0) {
            uploadOnesheetFileToBucket(
                featuredImage[0].file,
                featuredImage[0].file.name,
                `${process.env.REACT_APP_ENVIRONMENT}/onesheet/${onesheetId}/${tabId}/extraContent/`
            ).then((filename) => {
                setData(tabId, "featuredImage", filename);
            });
        }
    }, [featuredImage]);

    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data.show}
                            onChange={(e) =>
                                setData(tabId, "show", e.target.checked)
                            }
                        />
                    </label>
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>
            <hr className="my-10" />
            <div className="row mb-6">
                <label className="form-label fs-6">
                    {t("FORMS.ONESHEET.MAIN-BG-IMAGE")}
                </label>
                <div className="form-text text-muted">
                    {t("FORMS.ONESHEET.LAST-RELEASE.COVER.HELP")}
                </div>
                <CustomFileInput
                    fieldName={"featuredImage"}
                    inputClassName={"w-50"}
                    uploadedFileSrc={data?.featuredImage}
                    files={featuredImage}
                    setFiles={setFeaturedImage}
                />
            </div>
            <hr className="my-10" />
            <div className="row mb-6">
                <InputDefault 
                    label={
                        <>
                            <FontAwesomeIcon
                                icon={faSpotify}
                                className="me-2"
                            />
                            {t("FORMS.ONESHEET.LAST-RELEASE.SPOTIFY")}
                        </>
                    }
                    id="spotifyid"
                    type="text"
                    handleChange={(e) => {
                        let extraContent = { ...data }.extraContent;
                        extraContent["dsp_spotify_id"] = e.target.value;
                        setData(tabId, "extraContent", extraContent);
                    }}
                    value={data?.extraContent?.dsp_spotify_id}
                />
                {/* <InvInputDefault
                    label={
                        <>
                            <FontAwesomeIcon
                                icon={faSpotify}
                                className="me-2"
                            />
                            {t("FORMS.ONESHEET.LAST-RELEASE.SPOTIFY")}
                        </>
                    }
                    id="spotifyid"
                    type="text"
                    handleChange={(e) => {
                        let extraContent = { ...data }.extraContent;
                        extraContent["dsp_spotify_id"] = e.target.value;
                        setData(tabId, "extraContent", extraContent);
                    }}
                    value={data?.extraContent?.dsp_spotify_id}
                /> */}
            </div>
            <div className="row mb-6">
                <InputDefault
                    label={
                        <>
                            <FontAwesomeIcon icon={faDeezer} className="me-2" />
                            {t("FORMS.ONESHEET.LAST-RELEASE.DEEZER")}
                        </>
                    }
                    id="DEEZERid"
                    type="text"
                    handleChange={(e) => {
                        let extraContent = { ...data }.extraContent;
                        extraContent["dsp_deezer_id"] = e.target.value;
                        setData(tabId, "extraContent", extraContent);
                    }}
                    value={data?.extraContent?.dsp_deezer_id}
                />
            </div>
            <div className="row mb-6">
                <InputDefault
                    label={
                        <>
                            <FontAwesomeIcon
                                icon={faItunesNote}
                                className="me-2"
                            />
                            {t("FORMS.ONESHEET.LAST-RELEASE.APPLE-MUSIC")}
                        </>
                    }
                    id="APPLE-MUSICid"
                    type="text"
                    handleChange={(e) => {
                        let extraContent = { ...data }.extraContent;
                        extraContent["dsp_itunes_id"] = e.target.value;
                        setData(tabId, "extraContent", extraContent);
                    }}
                    value={data?.extraContent?.dsp_itunes_id}
                />
            </div>
            <div className="row mb-6">
                <InputDefault
                    label={
                        <>
                            <FontAwesomeIcon
                                icon={faYoutube}
                                className="me-2"
                            />
                            {t("FORMS.ONESHEET.LAST-RELEASE.YOUTUBE")}
                        </>
                    }
                    id="YOUTUBEid"
                    type="text"
                    handleChange={(e) => {
                        let extraContent = { ...data }.extraContent;
                        extraContent["dsp_youtube_id"] = e.target.value;
                        setData(tabId, "extraContent", extraContent);
                    }}
                    value={data?.extraContent?.dsp_youtube_id}
                />
            </div>
            <div className="row mb-6">
                <InputDefault
                    label={
                        <>
                            <FontAwesomeIcon
                                icon={faCirclePlay}
                                className="me-2"
                            />
                            {t("FORMS.ONESHEET.LAST-RELEASE.YOUTUBE-MUSIC")}
                        </>
                    }
                    id="YOUTUBE-MUSICid"
                    type="text"
                    handleChange={(e) => {
                        let extraContent = { ...data }.extraContent;
                        extraContent["dsp_youtube_music_id"] = e.target.value;
                        setData(tabId, "extraContent", extraContent);
                    }}
                    value={data?.extraContent?.dsp_youtube_music_id}
                />
            </div>

            <div className="row mb-6">
                <div>
                    <label htmlFor="extraContent" className="form-label fs-6">
                        {t("FORMS.ONESHEET.RELEASE-BIO")}
                    </label>
                    <div className="col-12 fv-row">
                        <textarea
                            id="extraContent"
                            name="extraContent"
                            className="form-control form-control-lg form-control-solid"
                            rows="7"
                            onChange={(e) =>
                                setData(tabId, "value", e.target.value)
                            }
                            maxLength={255}
                            value={data.value}
                        />
                        {fieldErrors?.includes("extraContent") ? (
                            <div className="text-danger mt-2">
                                {t("FORMS.GENERAL.MANDATORY-FIELD")}
                            </div>
                        ) : null}
                        <div className="form-text text-muted">
                            {t("FORMS.ONESHEET.RELEASE-BIO-HELP")}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export { OnesheetLatestReleaseSection };
