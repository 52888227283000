import { useTranslate } from "../../../../helpers/i18n/useTranslate";
import { useAlert } from "../../../../helpers/utils/CustomAlert";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import { toAbsoluteUrl } from "../../../../helpers/utils/toAbsoluteUrl";
import { CustomSaveButton } from "../../../../components/CustomSaveButton";
import { makeFormDataBody } from "../../../../helpers/utils/formUtils";
import api, { form_api } from "../../../../helpers/Api";
import { useApi } from "../../../../helpers/Api/ApiWrapper";
import { InputDefault } from "../../../../components/Form/InputDefault";

/**
 * Formulário de cadastro de usuário
 */
const Registration = () => {
    const initialFormData = {
        email: "",
        userFirstName: "",
        userLastName: "",
        password: "",
        passwordConfirmation: "",
        socialLogin: false,
    };
    const { t } = useTranslate();
    const { alert } = useAlert();
    const { setUserDataOnContext } = useApi();
    const [form, setForm] = useState(initialFormData);
    const [formSending, setFormSending] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        specialChar: false,
        number: false
    });
    const [showPassword, setShowPassword] = useState(false);
    const [fieldErrors, setFieldErrors] = useState([]);
    const [sendButtonDisabled, setSendButtonDisabled] = useState(false);

    const handleGoogleLoginSuccess = async (accessToken) => {
        /*
    Se o oauth2 do google funfar, pega os dados de email e nome e etc pra enviar pro backend
     */
        try {
            const response = await axios.get(
                "https://www.googleapis.com/oauth2/v3/userinfo",
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );
            setForm({ name: "email", value: response.data.email });
            setForm({
                name: "userFirstName",
                value: response.data.given_name
            });
            setForm({
                name: "userLastName",
                value: response.data.family_name
            });
            setForm({ name: "socialLogin", value: true });
            //skipStep(); // Pula pro próx passo sem o usuário precisar apertar "continuar"
        } catch (err) {
            console.error(err);
            alert.fire(t("GENERAL.SIGNUP.SOCIAL-LOGIN-FAILED"));
        }
    };

    const handleGoogleLoginError = () => {
        console.error("Login Failed");
        toast.error(t("GENERAL.FATAL-ERROR"));
    };

    const googleLogin = useGoogleLogin({
        onSuccess: (res) => handleGoogleLoginSuccess(res.access_token),
        onError: handleGoogleLoginError
    });

    const facebookLogin = (response) => {
        setForm({ name: "email", value: response.data.email });
        setForm({
            name: "userFirstName",
            value: response.data.first_name,
        });
        setForm({ name: "userLastName", value: response.data.last_name });
        setForm({ name: "socialLogin", value: true });
        //skipStep(); // Pula pro próx passo sem o usuário precisar apertar "continuar"
    };

    // função que lida com a senha e faz o teste no regex
    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;

        const lengthValid = newPassword.length >= 8;
        const uppercaseValid = /[A-Z]/.test(newPassword);
        const lowercaseValid = /[a-z]/.test(newPassword);
        const specialCharValid = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(
            newPassword
        );
        const numberValid = /\d/.test(newPassword);

        setPasswordValidation({
            length: lengthValid,
            uppercase: uppercaseValid,
            lowercase: lowercaseValid,
            specialChar: specialCharValid,
            number: numberValid
        });
    };

    /**
     * verifica se todos os requisitos de senha estão válidos
     */
    const isAllValid = Object.values(passwordValidation).every(
        (isValid) => isValid
    );

    /**
     * UseEffect que fica observando os requisitos da senha
     * se não forem atendidos, o botão de continuar fica disabled
     */
    useEffect(() => {
        setSendButtonDisabled(!isAllValid);
    }, [setSendButtonDisabled, isAllValid]);

    /*
     * Valida o form de cadastro
     */
    const validateSignupForm = async () => {
        let errors = {};
        try {
            let data = new FormData();
            data.append("email", form.email);
            data.append("userFirstName", form.userFirstName);
            data.append("password", form.password); // N vai validar esse campo se for login c google, mas manda assim msm
            data.append("useSocial", form.socialLogin);
            let response = await api.post("auth/validate-user/", data);
            if (response.status > 299) errors["password"] = response.data.msg;
        } catch (e) {
            errors = e.response.data.errors;
            console.log(errors);
        }
        setFieldErrors(Object.keys(errors));
        if (errors === undefined) {
            toast.error(t("GENERAL.ACTION-FAILED"));
            return false;
        } else {
            return Object.keys(errors).length === 0;
        }
    };

    /**
     * Manipula o envio de um formulário de registro.
     * Após o registro bem-sucedido, tenta fazer login no usuário, salvando tokens de autenticação e obtendo dados do usuário.
     *
     * @async
     * @function
     * @returns {Promise<void>}
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSending(true);
        let formIsValid = await validateSignupForm();
        if (!formIsValid) return;
        let data = makeFormDataBody(form);
        await api
            .post("auth/register/", data)
            .then(() => {
                // Se deu certo a chamada de registro, o usuário precisa agora completar o cadastro. Vamos redirecionar
                // ele pra tela de completar cadastro, salvando os tokens de autenticação pra conseguirmos identificar
                // o usuário lá
                alert.fire(t("AUTH.SIGNUP.S1.SUCCESS")).then(async () => {
                    let formData = new FormData();
                    formData.append("email", form.email);
                    formData.append("password", form.password);
                    try {
                        const loginResponse = await form_api.post(
                            "auth/login/",
                            formData
                        );
                        let responseData = loginResponse.data;
                        setUserDataOnContext(responseData.access, responseData.refresh); // Vai salvar os tokens no cookie e redirecionar pra CompleteSignup
                    } catch (err) {
                        console.error(err);
                        if (!err.response) {
                            toast.error(t("GENERAL.CONNECTION-ERROR"));
                        } else {
                            // tratamento de campos vazios
                            if (err.response.status === 400) {
                                setFieldErrors(Object.keys(err.response.data));
                            }
                            // tratamento de dados inválidos
                            else if (err.response.status === 401) {
                                toast.error(t("GENERAL.FORBIDDEN-ERROR"));
                            }
                            // tratamento de usuário sem perfil
                            else if (err.response.status === 403) {
                                toast.error(t("GENERAL.FORBIDDEN-ERROR"));
                            }
                            // tratamento de erro 500
                            else {
                                toast.error(t("GENERAL.CONNECTION-ERROR"));
                            }
                        }
                    } finally {
                        setFormSending(false);
                    }
                });
            })
            .catch((err) => {
                console.error(err);
                toast.error(t("GENERAL.FATAL-ERROR"));
                setHasError(true);
            })
            .finally(() => setFormSending(false));
    };

    return (
        <>
            <div className="w-100">
                <div className="mb-10 text-center">
                    <span className="text-2xl font-bold text-white">{t("AUTH.REGISTER.USER-INFO")}</span>
                </div>
                <div className="mb-10">
                    <InputDefault
                        label={t("AUTH.INPUT.NAME")}
                        id="userFirstName"
                        type="text"
                        handleChange={(e) => setForm({...form, 'userFirstName': e.target.value })}
                        required={!form.socialLogin}
                        maxLength={150}
                        value={form.userFirstName}
                        fieldError={fieldErrors}
                        error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                        placeholder={t("AUTH.INPUT.NAME")}
                    />
                </div>
                <div className="mb-10">
                    <InputDefault
                        label={t("AUTH.INPUT.USERNAME")}
                        id="email"
                        type="email"
                        handleChange={(e) => setForm({...form, 'email': e.target.value })}
                        value={form.email}
                        required={!form.socialLogin}
                        fieldError={fieldErrors}
                        error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                        placeholder={t("AUTH.INPUT.USERNAME")}
                    />
                </div>
                <div className="mb-10">
                    <InputDefault
                        label={t("AUTH.INPUT.PASSWORD")}
                        idPassword="showPassword"
                        id="password"
                        isPassword
                        name="password"
                        value={form.password}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        type={showPassword ? "text" : "password"}
                        handleChange={(e) => {
                                setForm({...form, 'password': e.target.value });
                                handlePasswordChange(e);
                            }}
                        placeholder={t("AUTH.INPUT.PASSWORD")}
                        required
                        fieldError={fieldErrors}
                        error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                    />
                </div>
                <label className="form-label fs-6 fw-bolder text-dark">
                    {t("AUTH.PASSWORD-REQUIREMENTS")}
                </label>
                <ul>
                    <li
                        className={
                            passwordValidation.length
                                ? "text-success"
                                : "text-danger"
                        }
                    >
                        {t("AUTH.MINIMUM-CHARACTERS-REQUIREMENT")}
                    </li>
                    <li
                        className={
                            passwordValidation.uppercase
                                ? "text-success"
                                : "text-danger"
                        }
                    >
                        {t("AUTH.CAPITAL-LETTER-REQUIREMENT")}
                    </li>
                    <li
                        className={
                            passwordValidation.lowercase
                                ? "text-success"
                                : "text-danger"
                        }
                    >
                        {t("AUTH.LOWER-CASE-REQUIREMENT")}
                    </li>
                    <li
                        className={
                            passwordValidation.specialChar
                                ? "text-success"
                                : "text-danger"
                        }
                    >
                        {t("AUTH.SPECIAL-CHARACTER-REQUIREMENT")}
                    </li>
                    <li
                        className={
                            passwordValidation.number
                                ? "text-success"
                                : "text-danger"
                        }
                    >
                        {t("AUTH.NUMBER-REQUIREMENT")}
                    </li>
                </ul>

                <CustomSaveButton
                    type="submit"
                    onClick={handleSubmit}
                    buttonText={"AUTH.GENERAL.SIGNUP_BUTTON"}
                    className="btn btn-lg btn-primary w-100 my-10"
                    formSending={formSending}
                    disabled={sendButtonDisabled}
                    hasError={hasError}
                    setHasError={setHasError}
                />

                {/*/!* begin::Separator *!/*/}
                <div className="text-center text-muted text-uppercase fw-bolder mb-10">
                    {t("GENERAL.OR")}
                </div>

                {/* Login com Google */}
                <button
                    type="button"
                    className="btn btn-flex flex-center bg-white text-secondary btn-lg w-100 mb-5"
                    onClick={googleLogin}
                >
                    <img
                        alt="Logo"
                        src={toAbsoluteUrl(
                            "/media/svg/brand-logos/google-icon.svg"
                        )}
                        className="h-20px me-3"
                    />
                    {t("GENERAL.SIGNUP.CONTINUE-WITH")} Google
                </button>
                {/* end::Google link */}

                {/* Login com Facebook */}
                {/*<LoginSocialFacebook*/}
                {/*  appId={appId}*/}
                {/*  onReject={(error) => {*/}
                {/*    console.error(error);*/}
                {/*    toast.error(t("GENERAL.FATAL-ERROR"));*/}
                {/*  }}*/}
                {/*  onResolve={(response) => facebookLogin(response)}*/}
                {/*>*/}
                {/*  <FacebookLoginButton*/}
                {/*    style={{*/}
                {/*      borderRadius: ".625rem",*/}
                {/*      display: "flex",*/}
                {/*      justifyContent: "center",*/}
                {/*      alignItems: "center",*/}
                {/*      fontSize: "15px",*/}
                {/*      margin: 0,*/}
                {/*      width: "100%",*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <p className="m-0">{t("GENERAL.SIGNUP.CONTINUE-WITH")} Facebook</p>*/}
                {/*  </FacebookLoginButton>*/}
                {/*</LoginSocialFacebook>*/}
                {/*/!* end::Separator *!/*/}
            </div>
        </>
    );
};

export { Registration };
