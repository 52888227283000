import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useTranslate} from "../../../helpers/i18n/useTranslate";
import {useCreateButton} from "../../../helpers/subHeaderButtons/createButtonProvider";
import {useApi} from "../../../helpers/Api/ApiWrapper";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {PageTitle} from "../../../layout/core";
import {LoadingSpinner} from "../../../components/LoadingSpinner";


/**
 * Lista de tutoriais
 * @type {React.FunctionComponent<object>}
 */
const TutorialDetails = observer(() => {
    const {t} = useTranslate();
    const {tutorialId} = useParams();
    const {GET} = useApi();
    const [tutorial, setTutorial] = useState([]);
    const [loading, setLoading] = useState(true);
    const {setHref} = useCreateButton();
    setHref("");

    const tutorialsBreadCrumbs = [
        {
            title: t("GENERAL.AVAILABLE-FEATURES.KBASE"),
            path: "academy",
            isSeparator: false,
            isActive: false,
        },
    ];

    /**
     * Pega todas as infos do tutorial
     * @returns {Promise<void>}
     */
    const getTutorials = async () => {
        setLoading(true);
        try {
            const tutorialsResponse = await GET(`academy/tutorials/${tutorialId}`);
            setTutorial(tutorialsResponse.data);
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ERROR-PAGE.TITLE"));
        } finally {
            setLoading(false);
        }
    };

    /**
     * Chama a função que obtém dados do curso
     */
    useEffect(() => {
        getTutorials();
    }, [tutorialId]);


    return (
        <>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <LoadingSpinner size="2xl"/>
                </div>
            ) : (
                <>
                    <PageTitle translateTitle={false} breadcrumbs={tutorialsBreadCrumbs}>
                        {tutorial.title}
                    </PageTitle>
                    {tutorial.youtube_embedded && (
                        <div className="mb-5">
                            <iframe
                                title="widget11-video"
                                className="embed-responsive-item rounded h-400px w-700px"
                                src={`https://www.youtube.com/embed/${tutorial.youtube_embedded}`}
                                allowFullScreen={true}
                            />
                        </div>
                    )}

                    <div className="mt-10 overflow-hidden">
                        <iframe
                            className="w-full h-600px"
                            srcDoc={tutorial.description}
                        />
                    </div>

                </>
            )
            }
        </>
    )
        ;
});

export {TutorialDetails};
