import { Container, Grid, Image, Link, Modal, Text } from "@nextui-org/react";
import { Title } from "../Components/Title";
import { Subtitle } from "../Components/Subtitle";
import { useState } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";

/**
 * Prévia para o elemento Galeria de Imagens do Onesheet
 * @param {object} data informações necessárias pra renderização da prévia
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetImageGalleryPreview = ({ data }) => {
    const [visible, setVisible] = useState(false);
    const [clickedImageSrc, setClickedImageSrc] = useState("");
    const [linkImage, setLinkImage] = useState("");

    /**
     * seta imagem e link que vai abrir no modal e seta  visibilidade do modal como true
     * @param {string} src conteúdo da imagem
     * @param {link} link
     */
    const handler = (src, link) => {
        setClickedImageSrc(src);
        setLinkImage(link);
        setVisible(true);
    };

    const closeHandler = () => {
        setVisible(false);
    };

    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container
                direction="column"
                css={{ mw: "1300px", m: "0 auto" }}
            >
                <Grid css={{ mb: "1.5rem" }}>
                    <Title color={data?.fontColor} text={data?.title} />
                    <Subtitle
                        color={data?.secondary_color}
                        text={data?.subtitle}
                    />
                </Grid>
                <Grid.Container gap={3} justify="center">
                    {data.value?.map((imageObject) => (
                        <Grid md={4} key={imageObject.id}>
                            <Image
                                src={imageObject.image}
                                width={"100%"}
                                height={"100%"}
                                objectFit="cover"
                                onClick={() =>
                                    handler(imageObject.image, imageObject.link)
                                }
                                css={{
                                    mw: "380px",
                                    mh: "380px",
                                    borderRadius: "0.75rem",
                                    cursor: "pointer",
                                    aspectRatio: "1 / 1",
                                }}
                            />
                        </Grid>
                    ))}

                    <ModalImage
                        imageSrc={clickedImageSrc}
                        visible={visible}
                        closeHandler={closeHandler}
                        fontColor={data?.fontColor}
                        link={linkImage}
                    />

                    {/*<ScrollTo*/}
                    {/*    to="#invite-form"*/}
                    {/*    bg_color={!data?.has_bg_color ? data?.bg_color : "#fff"}*/}
                    {/*    text_color={*/}
                    {/*        data?.has_bg_color ? data?.bg_color : "#fff"*/}
                    {/*    }*/}
                    {/*    cta={`Convidar ${data?.artist}`}*/}
                    {/*/>*/}
                </Grid.Container>
            </Grid.Container>
        </Container>
    );
};

/**
 * Modal que abre uma imagem quando ela é clicada
 * @param {String} imageSrc path da imagem
 * @param {boolean} visible indica se o modal tá aberto
 * @param {function} closeHandler handler de fechar o modal de prévia da img
 * @param {String} fontColor cor da fonte
 * @param {String} link url de redirecionamento
 * @returns {JSX.Element}
 * @constructor
 */
const ModalImage = ({ imageSrc, visible, closeHandler, fontColor, link }) => {
    const { t } = useTranslate();
    return (
        <Modal
            noPadding
            closeButton={!link}
            open={visible}
            onClose={closeHandler}
            css={{ p: 0, cursor: "default" }}
        >
            {link ? (
                <Modal.Header
                    css={{
                        position: "absolute",
                        zIndex: "$1",
                        top: 5,
                        right: 5,
                    }}
                >
                    <Text
                        color="#363449"
                        css={{
                            bc: "rgba(20, 20, 20, .5)",
                            p: ".5rem .9rem",
                            backdropFilter: "blur(5px)",
                            borderRadius: "12px",
                        }}
                    >
                        <Link
                            isExternal
                            css={{ color: fontColor, fs: "12px" }}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={link}
                        >
                            {t("GENERAL.ACCESS-LINK")}
                        </Link>
                    </Text>
                </Modal.Header>
            ) : null}

            <Modal.Body>
                <Image
                    showSkeleton
                    src={imageSrc}
                    width={"100%"}
                    height={"100%"}
                    objectFit="cover"
                    css={{ borderRadius: "0.75rem" }}
                />
            </Modal.Body>
        </Modal>
    );
};

export { OnesheetImageGalleryPreview };
