import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import api from "../../../helpers/Api";
import { useAuth } from "../utils/AuthProvider";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

/**
 * Renderiza a tabela de preços do Stripe
 * @returns {JSX.Element}
 */
export function StripePaymentScreen() {
    const location = useLocation();
    const { auth, currentUser, setCurrentUser, saveAuth } = useAuth();
    const { productId } = useParams();
    const [tableID, setTableID] = useState(null);

    /**
     * Tenta obter o id da tabela de preços do Stripe com base no produto indicado
     * @param {String} token access token pra buscar na api
     * @returns {Promise<void>}
     */
    const getTableId = async (token) => {
        try {
            const tableIdResponse = await api.get(
                `/core/get-stripe-table-id/${productId}`,
                {
                    // Pega o user relacionado ao token passado por parametro
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setTableID(tableIdResponse.data.table_id);
        } catch (e) {
            console.log(e);
            // window.location.replace("/error/404");
        }
    };

    /**
     * Tenta obter o usuário da requisição com base no token indicado
     * @param {String} token access token pra buscar pelo usuário
     * @returns {Promise<void>}
     */
    const getUser = async (token) => {
        try {
            const userResponse = await api.get("/core/users", {
                // Pega o user relacionado ao token passado por parametro
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            let userData = userResponse.data[0];
            setCurrentUser({
                id: userData.id,
                email: userData.email,
                first_name: userData.first_name,
                last_name: userData.first_name,
                hasAcceptedTerms: userData.has_accepted_terms,
            });
            saveAuth({
                api_token: token,
                refreshToken: "",
            });
        } catch (e) {
            console.log(e);
            // window.location.replace("/error/404");
        }
    };

    /**
     * Obtém o parâmetro "at" da URL (que corresponde ao access token) e faz uma
     * chamada para o backend para buscar o usuário correspondente. Caso não
     * haja o parâmetro ou não seja possível encontrar o usuário, redireciona
     * para 404.
     */
    useEffect(() => {
        if (!productId) return;
        // Obtém o parâmetro "at" da URL
        const searchParams = new URLSearchParams(location.search);
        const at = searchParams.get("at") || auth?.api_token;
        if (!at) window.location.replace("/error/404");

        getUser(at);
        getTableId(at);
    }, [location, productId]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <>
            {tableID ? (
                <stripe-pricing-table
                    pricing-table-id={tableID}
                    publishable-key={
                        process.env
                            .REACT_APP_STRIPE_SUBSCRIPTION_PUBLISHABLE_KEY
                    }
                    client-reference-id={`${currentUser.id}abc${productId}`}
                ></stripe-pricing-table>
            ) : (
                <LoadingSpinner size={"2xl"} />
            )}
        </>
    );
}
