let converter = require("json-2-csv");

/**
 * Baixa um csv a partir de uma lista de objetos (um json)
 * @param {array} jsonData lista de objetos para serem convertidos em csv
 * @returns {Promise<void>}
 */
const handleDownloadCSV = async (jsonData) => {
    try {
        const csvData = await json2csvAsync(jsonData);

        // Cria um objeto Blob para o CSV
        const blob = new Blob([csvData], { type: "text/csv" });

        // Cria um link para download e simula um clique
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "leads.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error("Erro ao converter para CSV:", error);
    }
};

/**
 * Função que se utiliza de um pacote externo para realizar a conversão de json para csv
 * @param data
 * @returns {Promise<string>}
 */
const json2csvAsync = async (data) => {
    const csv = await converter.json2csv(data);
    return csv;
};

export { handleDownloadCSV };
