import React from "react";
import { Container, Grid, Link, Text } from "@nextui-org/react";
import { Title } from "../Components/Title";
import { Subtitle } from "../Components/Subtitle";
import styles from "./OnesheetNewsPreview.module.css";

const OnesheetNewsPreview = ({ data }) => {
    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container
                direction="column"
                justify="center"
                css={{ mw: "1300px", m: "0 auto" }}
            >
                <Grid css={{ mb: "1.5rem" }}>
                    <Title color={data?.fontColor} text={data?.title} />
                    <Subtitle
                        color={data?.secondary_color}
                        text={data?.subtitle}
                    />
                </Grid>
                <div className={styles.wrapper}>
                    {data?.value.map((link) => (
                        <Link
                            className={styles.news}
                            target="_blank"
                            href={link.link_article}
                            rel="noopener noreferrer"
                        >
                            <img
                                className={styles.imageArticle}
                                alt={link.title_article}
                                src={link.image_article}
                            />
                            <Text
                                h1
                                className={styles.titleArticle}
                                css={{ color: link.fontColor }}
                            >
                                {link.title_article}
                            </Text>
                        </Link>
                    ))}
                </div>

                {/*<ScrollTo*/}
                {/*    to="#invite-form"*/}
                {/*    bg_color={!data?.has_bg_color ? data?.bg_color : "#fff"}*/}
                {/*    text_color={data?.has_bg_color ? data?.bg_color : "#fff"}*/}
                {/*    cta={`Convidar ${data?.artist}`}*/}
                {/*/>*/}
            </Grid.Container>
        </Container>
    );
};

export { OnesheetNewsPreview };
