import { useEffect } from "react";
import { useAuth } from "../utils/AuthProvider";

const RedirectToApp = () => {
    const { logout } = useAuth();
    useEffect(() => {
        logout();
        window.location.replace("amplifyus://mobile.amplifyus.app/refreshPage");
    }, []);
    return <></>;
};

export { RedirectToApp };
