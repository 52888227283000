import React from "react";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

/**
 * @description
 * Um componente que renderiza um ícone de informação com um tooltip.
 * O tooltip pode exibir uma breve explicação ou um conteúdo mais extenso quando ocorre o hover.
 *
 * @component
 * @param {string} id - O identificador único para o tooltip.
 * @param {string} text - Usado quando for um breve texto.
 * @param {string} extensiveContent - Conteúdo mais extenso, de preferência com tags html.
 * @param {string} place - localização do tooltip em relação ao componente que terá o tooltip.
 * @returns {JSX.Element} - Um elemento JSX que representa o botão que aciona o tooltip quando ocorre o hover.
 *
 * Este componente deve ser utilizado juntamente com a label
 * @example
 * <label
 *       htmlFor="name"
 *       className="name"
 *   >
 *       Name
 *       <InfoForFields
 *           id="name"
 *           text={"name"}
 *      />
 * </label>
 */

export const InfoForFields = ({ id, text, extensiveContent, place, icon, size, color }) => {
    let infoId = `inf-${id}`;

    return (
        <>
            <button
            onClick={(e) => {e.preventDefault()}}
                className="btn p-0 m-0 ms-2"
                id={infoId}
            >
                <FontAwesomeIcon icon={icon || faCircleInfo} size={size || 'md'} color={color || '#fff'} data-tooltip-id={infoId} />
            </button>
            {extensiveContent ? (
                <Tooltip
                    anchorSelect={`#${infoId}`}
                    style={{ zIndex: 9999 }}
                    id={infoId}
                    place={place || 'top'}
                >
                    {extensiveContent}
                </Tooltip>
            ) : (
                <Tooltip anchorSelect={`#${infoId}`} content={text} place={place || 'top'}/>
            )}
        </>
    );
};
