import React, {useState} from "react";
import { observer } from "mobx-react-lite";
import { PagedListComponent } from "../../../components/PagedListComponent";
import {CardTutorial} from "../../../components/Cards/CardTutorial";

/**
 * Lista de tutoriais
 * @type {React.FunctionComponent<object>}
 */
const Tutorials = observer(() => {
    const [tutorials, setTutorials] = useState([]);

    return (
        <>
            <PagedListComponent
                data={tutorials}
                setData={setTutorials}
                filters={{}}
                baseUrl={"/academy/tutorials"}
            >
                <div className="flex gap-3 flex-wrap">
                    {tutorials.map((tutorial, index) => {
                        return (
                           <CardTutorial key={index}
                                    title={tutorial.title}
                                    badgeColor="primary"
                                    category={tutorial.category_title}
                                    featured_image={tutorial.featured_image}
                                    created_at={tutorial.created_at}
                                    redirectTo={`/academy/tutorial/${tutorial.id}`}
                                />
                        );
                    })}
                </div>
            </PagedListComponent>
        </>
    );
});

export { Tutorials };