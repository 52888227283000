/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslate } from "../../../../helpers/i18n/useTranslate";
import { ArtistOrRecorderStep } from "./formSteps/one";
import { SearchOnSpotifyStep } from "./formSteps/two";
import { ConfirmDspAccountsStep } from "./formSteps/three";
import { CompleteSignupFormContext } from "./CompleteSignupFormContext";
import { observer } from "mobx-react-lite";
import { defaultStepperOptions, StepperComponent } from "../../../../../_metronic/assets/ts/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-light-svg-icons";
import api, { form_api } from "../../../../helpers/Api";
import { makeFormDataBody } from "../../../../helpers/utils/formUtils";
import { useAlert } from "../../../../helpers/utils/CustomAlert";
import { toast, ToastContainer } from "react-toastify";
import { CustomSaveButton } from "../../../../components/CustomSaveButton";
import { useAuth } from "../../utils/AuthProvider";
import { customGetCookie } from "../../../../helpers/usefulFunctions";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { useApi } from "../../../../helpers/Api/ApiWrapper";
import Swal from "sweetalert2/src/sweetalert2";
import "/node_modules/@sweetalert2/theme-dark/dark.scss";

/**
 * Formulário de completar cadastro
 * @type {React.FunctionComponent<object>}
 */
export const CompleteSignup = observer(() => {
        const { t } = useTranslate();
        const { alert } = useAlert();
        const { saveAuth } = useAuth();
        const { setUserDataOnContext } = useApi();
        const [userName, setUserName] = useState("");
        const [userId, setUserId] = useState("");
        const [isSubmitButton, setIsSubmitButton] = useState(false);
        const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
        const [formSending, setFormSending] = useState(false);
        const [hasError, setHasError] = useState(false);
        const [step, setStep] = useState(1); // Usado apenas pra mudar a frase a cada step
        const form = useContext(CompleteSignupFormContext);
        // Precisa de dois refs de stepper por causa do StepperComponent. Não dá pra ussar o mesmo ref pra instanciar.
        const stepperRef = useRef(null);
        const stepper = useRef(null);
        const accessToken = customGetCookie('at');
        const refreshToken = customGetCookie('rt');

        /**
         * Obtém o nome e id do usuário e coloca no estado do componente
         */
        const getUser = async () => {
            try {
                const userResponse = await api.get("/core/users", {
                    // Pega o user relacionado ao token passado por parametro
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                const user = userResponse.data[0];
                setUserName(user.first_name);
                // Local não cria user no firebase, entao precisamos buscar pelo id. Mas em prod precisamos fazer a busca
                // pelo firebase_uid porque o mobile não possui a informação do id do usuário no bd, apenas no firebase.
                setUserId(process.env.REACT_APP_ENVIRONMENT === 'production' ? user.firebase_uid : user.id);
            } catch (err) {
                console.error(err);
                alert.fire(t("GENERAL.AUTH.LOGIN-AGAIN")).then(() => {
                    window.location.replace("/logout");
                });
            }
        };

        /**
         * Pega o token de acesso dos cookies pra pegar o usuário logado
         */
        useEffect(() => {
            getUser();
        }, []);

        /**
         * Instancia o StepperComponent
         */
        useEffect(() => {
            if (!stepperRef.current) return;
            stepper.current = StepperComponent.createInsance(stepperRef.current, {
                ...defaultStepperOptions,
                animation: true,
                animationNextClass: "animate__animated animate__fadeIn animate__fast",
                animationPreviousClass: "animate__animated animate__fadeIn animate__fast"
            });
        }, [stepperRef]);

        /**
         * Volta ao passo anterior
         */
        const prevStep = () => {
            if (!stepper.current) return;

            stepper.current.goPrev();
            setStep(stepper.current.getCurrentStepIndex());

            setIsSubmitButton(
                stepper.current.isLastStep()
            );
        };

        /**
         * Valida e passa para o próximo passo, gerenciando o texto do botão de envio
         * @param e
         * @returns {Promise<void>}
         */
        const submitStep = async (e) => {
            e?.preventDefault();
            //Começa o loading no botão de continuar
            setFormSending(true);
            if (hasError) {
                // Não prossiga se houver erros
                setFormSending(false);
                return;
            }
            setHasError(false); //Reseta estado do erro
            // Tem q ter a instancia de StepperComponent pra pular pro próx passo
            if (!Boolean(stepper.current)) {
                // Se a validação falhar, interrompe o estado de carregamento
                setFormSending(false);
                return;
            }
            // todo tratar erro aqui no caso de nao retornar erro de formulario (404 por exemplo) Alguma mensagems tem que ir pro usuario
            if (!stepper.current.isLastStep()) {
                stepper.current.goNext(); // Só pula pro próximo passo
                setFormSending(false);
            } else { // submissão do form
                alert.fire({
                    title: t("AUTH.REGISTRATION.CONFIRM-TITLE"),
                    text: t("AUTH.REGISTRATION.CONFIRM-TEXT"),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: t("AUTH.REGISTRATION.BUTTON-CONFIRM"),
                    cancelButtonText: t("AUTH.REGISTRATION.BUTTON-CANCEL"),
                    showLoaderOnConfirm: true,
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        handleSubmit();
                    } else {
                        setFormSending(false);
                    }
                });
            }
            setIsSubmitButton(
                stepper.current.isLastStep()
            );
            setStep(stepper.current.getCurrentStepIndex());
        };

        /**
         * Deixa o botão de Enviar desabilitado se o campo de nome do artista e spotify ID estiverem inválidos
         */
        useEffect(() => {
            //Só pode fazer a validação se for isSubmitButton porque senão o botão fica habilitado em estágios anteriores do formulário
            if (isSubmitButton) {
                // Verifique se o campo 'clientName' e 'spotifyId' estão definidos
                const isFormValid = form.data.clientName && form.data.spotifyId;
                setSendButtonDisabled(!isFormValid);
            } else setSendButtonDisabled(false);
        }, [isSubmitButton, form.data.clientName, form.data.spotifyId]);

        /**
         * Manipula o envio de um formulário de completar cadastro.
         * Após o registro bem-sucedido, tenta fazer login no usuário, salvando tokens de autenticação e obtendo dados do usuário.
         *
         * @async
         * @function
         * @returns {Promise<void>}
         */
        const handleSubmit = async () => {
            setFormSending(true);
            let data = makeFormDataBody(form.data);
            data.append("userId", userId);
            try {
                let headers = {
                    Authorization: `Bearer ${accessToken}`,
                };
                await form_api.post("auth/complete-signup/", data, {
                    headers: headers,
                });
                saveAuth({
                    api_token: accessToken,
                    refreshToken: refreshToken
                });
                setUserDataOnContext(accessToken, refreshToken);
            } catch (err) {
                console.error(err);
                toast.error(t("GENERAL.FATAL-ERROR"));
                setHasError(true);
            } finally {
                setFormSending(false);
            }
        };

        return (
            <div className="card">
                <ToastContainer className="Toastify__toast--dark" />
                <div className="card-body">
                    {/* begin: Heading */}
                    {userName &&
                        <div className="text-center mb-10">
                            <span
                                className="font-bold text-2xl mb-3">
                                {t("GENERAL.AUTH.COMPLETE-SIGNUP").replace("$NAME$", userName)}
                            </span>
                            {
                                stepper?.current?.getCurrentStepIndex() &&
                                <div
                                    className="flex flex-col gap-2 mt-4 md:flex-row md:gap-0 text-gray-400 font-bold text-xl justify-center ">
                                    {
                                        t(`GENERAL.AUTH.COMPLETE-SIGNUP-STEP-${step}`)
                                    }
                                </div>
                            }
                        </div>
                    }
                    {/* end: Heading */}
                    <div
                        ref={stepperRef}
                        className={userName && userId ? ("stepper stepper-links flex flex-col") : ("hidden")}
                        id="kt_create_account_stepper"
                    >
                        <div data-kt-stepper-element="nav"/>
                        <div data-kt-stepper-element="nav"/>
                        <div data-kt-stepper-element="nav"/>
                        <form
                            className="mx-auto mw-600px w-100"
                            id="kt_create_account_form"
                            onSubmit={submitStep}
                        >
                            <div className="current" data-kt-stepper-element="content">
                                <ArtistOrRecorderStep />
                            </div>
                            <div data-kt-stepper-element="content">
                                <SearchOnSpotifyStep />
                            </div>
                            <div data-kt-stepper-element="content">
                                <ConfirmDspAccountsStep />
                            </div>

                            <div className="d-flex flex-stack pt-15">
                                <div className="mr-2">
                                    <button
                                        onClick={prevStep}
                                        type="button"
                                        className="btn btn-lg btn-light-primary me-3"
                                        data-kt-stepper-action="previous"
                                    >
                                        <FontAwesomeIcon
                                            icon={faArrowLeft}
                                            className="me-2"
                                        />
                                        {t("GENERAL.BACK")}
                                    </button>
                                </div>
                                <div>
                                    <CustomSaveButton
                                        type="submit"
                                        afterIcon={
                                            <FontAwesomeIcon
                                                icon={faArrowRight}
                                                className="ms-2"
                                            />
                                        }
                                        buttonText={
                                            isSubmitButton ?
                                                "GENERAL.SUBMIT"
                                                : "GENERAL.CONTINUE"
                                        }
                                        className="btn btn-lg btn-primary me-3"
                                        formSending={formSending}
                                        disabled={sendButtonDisabled}
                                        hasError={hasError}
                                        setHasError={setHasError}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <LoadingSpinner hide={userName && userId} size={"2xl"} />
                </div>
            </div>
        );
    })
;
