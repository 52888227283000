import React from "react";
import { Container, Grid } from "@nextui-org/react";
import styles from "./OnesheetExternalLinksSection.module.css";
import { Title } from "../Components/Title";

/**
 * Componente de visualização dos links externos de uma Onesheet.
 * @module OnesheetExternalLinksPreview
 * @param {Object} data - Dados dos links externos.
 * @param {string} data.id - ID do componente.
 * @param {string} data.background - Cor de fundo do componente.
 * @param {string} data.fontColor - Cor da fonte do título.
 * @param {string} data.secondary_color - Cor secundária dos links.
 * @param {Array} data.value - Array de objetos contendo os links e títulos.
 * @returns {JSX.Element} Componente de visualização dos links externos.
 */
export const OnesheetExternalLinksPreview = ({ data }) => {
    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container gap={2} css={{ mw: "1300px", m: "0 auto" }}>
                <Grid xs={12} direction="column" css={{ w: "100%" }}>
                    <Title color={data?.fontColor} text="Links" />

                    <div className={styles.links}>
                        {data.value.length > 0 &&
                            data?.value.map((link, index) => {
                                if (link.link || link.title) {
                                    return (
                                        <div
                                            key={index}
                                            className="w-full flex flex-col"
                                        >
                                            <a
                                                style={{
                                                    width: "100%",
                                                    border: `solid 2px ${data.secondary_color}`,
                                                    padding: "1rem",
                                                    borderRadius: "0.75rem",
                                                    color: data.secondary_color,
                                                    marginBottom: ".5rem",
                                                    textAlign: "center",
                                                    fontWeight: "600",
                                                }}
                                                href={link.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {link.title}
                                            </a>
                                        </div>
                                    );
                                }
                            })}
                    </div>
                </Grid>
            </Grid.Container>
        </Container>
    );
};
