import {PageTitle} from "../../layout/core";
import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useTranslate} from "../../helpers/i18n/useTranslate";
import {useCreateButton} from "../../helpers/subHeaderButtons/createButtonProvider";
import {useLocation} from "react-router-dom";
import {Courses} from "./Courses/ListCourses";
import {PostsBlog} from "./Blog/PostsBlog";
import {Tab, Tabs} from "next2";
import {Tutorials} from "./Tutorials/ListTutorials";

const breadCrumbs = [];

/**
 * Academy
 * @type {React.FunctionComponent<object>}
 */
const Academy = observer(() => {
    const {t} = useTranslate();
    const {setHref} = useCreateButton();
    let {state} = useLocation();

    const [selected, setSelected] = useState(
        state ? state.selectedTab : "courses"
    );
    setHref("");

    return (
        <>
            <PageTitle
                description={t("INTRO.ACADEMY")}
                breadcrumbs={breadCrumbs}
            >
                APPS.KNOWLEDGEBASE.ACADEMY
            </PageTitle>

            <Tabs
                size="lg"
                color="primary"
                aria-label="Options"
                variant="light"
                selectedKey={selected}
                onSelectionChange={setSelected}
            >
                <Tab key="courses" title={<span className="font-bold">{t("APPS.KNOWLEDGEBASE.COURSES")}</span>}>
                    <Courses/>
                </Tab>
                <Tab key="blog" title={<span className="font-bold">{t("APPS.KNOWLEDGEBASE.BLOG")}</span>}>
                    <PostsBlog/>
                </Tab>
                <Tab key="tutorials" title={<span className="font-bold">{t("APPS.KNOWLEDGEBASE.TUTORIALS")}</span>}>
                    <Tutorials/>
                </Tab>
            </Tabs>
        </>
    );
});

export {Academy};
