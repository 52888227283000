import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareInfo } from "@fortawesome/pro-light-svg-icons";
import { useTranslate } from "../../../../../helpers/i18n/useTranslate";

const SelectArtistCard = ({ text }) => {
    const { t } = useTranslate();
    return (
        <div className="notice h-[100%] flex items-center bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
            <FontAwesomeIcon icon={faSquareInfo} size="2xl" className="me-5" />
            <div className="d-flex flex-stack flex-grow-1">
                <div>
                    <div className="text-dark d-block fs-6">
                        {t(text || "APPS.DATASCIENCE.HC.SELECT-ARTIST")}
                    </div>
                    <a
                        href="#"
                        id="change_artist_or_client_tab_toggle"
                        className="text-dark fw-bold text-hover-primary d-block fs-6"
                    >
                        "{t("FORMS.CORE.PROJECT.ARTIST.SELECT")}"
                    </a>
                </div>
            </div>
        </div>
    );
};

export { SelectArtistCard };
