import {createContext} from "react";
import {DefaultFormContext} from "../../../../components/Form/defaultFormContext";

class FormVariables extends DefaultFormContext {
    getArtistType = () => "artist";
    getRecorderType = () => "recorder";
    data = {
        clientName: "",
        userType: "artist",
        artistName: "",
        spotifyId: "",
        youtubeId: "",
        artistPicture: "",
    };
}

export const CompleteSignupFormContext = createContext(new FormVariables());
