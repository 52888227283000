import { observer } from "mobx-react-lite";
import { useTranslate } from "../../../../../helpers/i18n/useTranslate";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faSearch } from "@fortawesome/pro-light-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";
import { Button } from "@nextui-org/react";

/**
 * Renderiza o componente de confirmação de conta do Youtube.
 * @type {React.FunctionComponent<{readonly form?: *, readonly handleChange?: *}>}
 */
const YoutubeAccountConfirm = observer(({ form, handleChange }) => {
    const youtubeApiToken = process.env.REACT_APP_YOUTUBETOKEN;
    const { t } = useTranslate();
    const spotifyName = form.artistName || form.clientName;
    const [youtubeChannel, setYoutubeChannel] = useState(null);
    const [isMe, setIsMe] = useState(true);
    const [noAccount, setNoAccount] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [selectedArtist, setSelectedArtist] = useState({});

    useEffect(() => {
        fetchYoutubeChannel();
    }, [
        form.artistName,
        form.clientName,
        form.spotifyId,
        form.clientSpotifyId
    ]);

    /*
    Essa função faz a busca no youtube pelo nome do artista e pega o primeiro resultado
     */
    const fetchYoutubeChannel = async () => {
        //  we need to make the url with the youtube api token
        if (!spotifyName) return;
        try {
            const response = await axios.get(
                `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=${spotifyName}&type=channel&key=${youtubeApiToken}`
            );
            setYoutubeChannel(response.data.items[0]);
            handleChange({ name: "youtubeId", value: response.data.items[0].id.channelId });
        } catch (err) {
            // setIsMe(false)
            console.log(err);
        }
    };

    /*
    Essa função seleciona o artista que o usuario clicou e muda o youtubeId para o id do artista
     */
    const selectArtist = (artist) => {
        setSelectedArtist(artist);
        handleChange({ name: "youtubeId", value: artist.id.channelId });
    };


    /*
    Essa função faz a busca no youtube pelo o que o usuario digitou no input
     */
    const handleYoutubeAccountSearch = async () => {
        try {
            const response = await axios.get(
                `https://youtube.googleapis.com/youtube/v3/search?part=snippet&q=${searchText}&type=channel&key=${youtubeApiToken}`
            );
            setSearchResults(response.data.items);
        } catch (err) {
            if (err.response.status === 403) {
                // use a toast to show the error
                toast.error(t("GENERAL.CONNECTION-ERROR"));
            }
            console.log(err);
        }
    };

    return (
        <>
            {noAccount ? <>
                    <div className="flex flex-column gap-5 items-center justify-center">
                        <span
                            className="text-slate-50 text-lg font-semibold">
                            {t("AUTH.REGISTRATION.ACCOUNT-CONFIRM-ADD-LATER")}
                        </span>
                        <span style={{ cursor: "pointer" }}
                              className="text-decoration-underline"
                              onClick={() => {
                                  setNoAccount(false);
                                  setIsMe(false);
                              }
                              }>
                            {t("AUTH.REGISTRATION.YOUTUBE-ACCOUNT-CONFIRM-SEARCH")}
                        </span>
                    </div>
                </> :
                isMe && youtubeChannel ? (
                    <>
                        {/*    confirm if its the correct channel from the youtube */}
                        <div className="mb-2">
                            <label className="form-label">
                                {t("AUTH.REGISTRATION.YOUTUBE-ACCOUNT-CONFIRM-TEXT")}
                            </label>
                        </div>
                        <div className="mb-2">
                            <div className="d-flex align-items-center">
                                <div className="me-2">
                                    <img
                                        src={youtubeChannel?.snippet?.thumbnails?.default?.url}
                                        alt=""
                                        className="rounded-circle"
                                        width="40"
                                        height="40"
                                    />
                                </div>
                                <div>
                                    <div className="fw-bold">{youtubeChannel?.snippet?.title}</div>
                                    <div className="text-muted">
                                        {youtubeChannel?.snippet?.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*   a text in the rights saiyng is not me that changes the isme state to false*/}
                        <div className="mb-2 text-end">
                            <span
                                style={{ cursor: "pointer" }}
                                className="text-decoration-underline"
                                onClick={() => {
                                    setIsMe(false);
                                    form.youtubeId = "";
                                }
                                }
                            >
                            {t("AUTH.REGISTRATION.YOUTUBE-ACCOUNT-CONFIRM-NOT-ME")}
                            </span>
                        </div>
                    </>
                ) : (
                    //     here we have the search for the youtube channel and the list of the results just like ArtistSearchOnDsps
                    <>
                        <div className="mb-2">
                            <label className="form-label">
                                {t("AUTH.REGISTRATION.YOUTUBE-ACCOUNT-CONFIRM-SEARCH")}
                            </label>
                        </div>
                        <div className="mb-2">
                            <div className="d-flex align-items-center mb-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("AUTH.REGISTRATION.YOUTUBE-ACCOUNT-PLACEHOLDER")}
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                                <div className="ms-2">
                                    <Button
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleYoutubeAccountSearch();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </div>
                            </div>
                            {/*    confirm search button*/}
                        </div>
                        <div className="mb-2">
                            <ul>
                                {searchResults.map((result) => (
                                    <li
                                        style={{ cursor: "pointer" }}
                                        onClick={
                                            () => {
                                                selectArtist(result);
                                            }
                                        }
                                        className="d-flex align-items-center mb-7"
                                    >

                                        <div className="d-flex">
                                            <div className="me-2">
                                                <img
                                                    src={result?.snippet?.thumbnails?.default?.url}
                                                    alt=""
                                                    className="rounded-circle"
                                                    width="40"
                                                    height="40"
                                                />
                                            </div>
                                            <div>
                                                <div className="fw-bold">
                                                    {result?.snippet?.title}

                                                </div>

                                                <div className="text-muted">
                                                    {result?.snippet?.description}
                                                </div>

                                            </div>
                                            <div className="text-end">
                                                {selectedArtist?.id?.channelId === result?.id?.channelId && (
                                                    <FontAwesomeIcon icon={faCircleCheck} />
                                                )}
                                            </div>
                                        </div>
                                        {/*    if the selected artist is the one that was clicked put a check icon in the right */}

                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="flex justify-between">
                            <span
                                style={{ cursor: "pointer" }}
                                className="text-decoration-underline text-red-500"
                                onClick={() => {
                                    setNoAccount(true);
                                    form.youtubeId = "";
                                }
                                }
                            >
                            {t("AUTH.REGISTRATION.ACCOUNT-CONFIRM-NOT-FOUND")}
                            </span>
                                        <span
                                            style={{ cursor: "pointer" }}
                                            className="text-decoration-underline text-cyan-600"
                                            onClick={() => {
                                                setNoAccount(true);
                                                form.youtubeId = "";
                                            }
                                            }
                                        >
                            {t("AUTH.REGISTRATION.ACCOUNT-CONFIRM-NO-ACCOUNT")}
                            </span>
                        </div>
                    </>
                )}
        </>
    );
});

export default YoutubeAccountConfirm;
