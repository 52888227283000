/**
 * Tenta pegar o token spotify do usuário no momento que a página carrega. Ele só vai conseguir pegar esse token se
 * a página tiver carregado a partir da callback url do login com spotify
 * @returns {{}}
 */
function getSpotifyTokenFromUrl() {
    return window.location.hash
        .substring(1)
        .split("&")
        .reduce((initial, item) => {
            let parts = item.split("=");
            initial[parts[0]] = decodeURIComponent(parts[1]);
            return initial;
        }, {});
}

export { getSpotifyTokenFromUrl };
