/**
 * Faz a inicialização da autenticação quando o usuário acessa a aplicação. Verifica se há token de login, tenta logar
 * com o token, etc.
 */
import { useEffect, useRef, useState } from "react";
import { LayoutSplashScreen } from "../../../layout/core";
import { useAuth } from "./AuthProvider";
import { getUserByToken } from "./AuthHelpers";
import api from "../../../helpers/Api";
import { useApi } from "../../../helpers/Api/ApiWrapper";

const AuthInit = ({ children }) => {
    const { auth, logout } = useAuth();
    const { setUserDataOnContext } = useApi();
    const didRequest = useRef(false);
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    /**
     * Tenta pegar o usuário a partir do token antes de renderizar a aplicação
     */
    useEffect(() => {
        const requestUser = async (apiToken) => {
            try {
                if (!didRequest.current) {
                    await setUserDataOnContext(apiToken);
                }
            } catch (error) {
                console.error(error);
                if (!didRequest.current) {
                    logout();
                }
            } finally {
                setShowSplashScreen(false);
            }

            return () => (didRequest.current = true);
        };
        if (auth && auth.api_token) {
            requestUser(auth.api_token);
        } else {
            logout();
            setShowSplashScreen(false);
        }
        // eslint-disable-next-line
    }, []);

    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};
export { AuthInit };
