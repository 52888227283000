import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "@nextui-org/react";
import { TidalLogo } from "../TidalLogo";

/**
 * Componente reutilizável para exibir números sociais com ícones e estilos personalizáveis.
 *
 * @param {string} title - Título do link (atributo "title").
 * @param {string} href - URL do link.
 * @param {string} className - Classes CSS adicionais para estilização.
 * @param {string} name - Nome da rede social.
 * @param {string} iconColor - Cor do ícone.
 * @param {string} textColor - Cor do texto.
 * @param {object} icon - Ícone a ser exibido (do FontAwesome ou TidalLogo).
 * @param {string} number - Número a ser exibido.
 * @param {string} label - Legenda do número (o que ele representa).
 * @returns {JSX.Element} Componente que exibe um ícone e um número social formatado.
 */

export const SocialNumber = ({
    title,
    href,
    className,
    name,
    iconColor,
    textColor,
    icon,
    number,
    label,
}) => {
    return (
        <a
            title={title}
            href={href || null}
            target="_blank"
            rel="noopener noreferrer"
            className={className}
        >
            {name === "Tidal" ? (
                // Foi feito dessa forma pq não tem esse logo no fontawesome.
                <TidalLogo color={iconColor} />
            ) : (
                <FontAwesomeIcon color={iconColor} size="xl" icon={icon} />
            )}
            <Text
                css={{
                    m: 0,
                    color: textColor,
                    fontWeight: "bold",
                    fs: "1rem",
                }}
            >
                {number} {label}
            </Text>
        </a>
    );
};
