import { createRoot } from "react-dom/client";
import { MetronicI18nProvider } from "./app/helpers/i18n/Metronici18n";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./assets/sass/plugins.scss";
import "./assets/sass/toastify-dark-theme.scss";
import "./assets/sass/style.scss";
import "./assets/sass/style.react.scss";
import "./index.css";

import { AppRoutes } from "./app/routing/AppRoutes";
import { AuthProvider } from "./app/modules/auth";
import { ApiProvider } from "./app/helpers/Api/ApiWrapper";
import { ThemeModeProvider } from "./app/layout/theme-mode/ThemeModeProvider";
import { AppOniApiProvider } from "./app/helpers/Api/AppOniApiWrapper";
import { CreateButtonProvider } from "./app/helpers/subHeaderButtons/createButtonProvider";
import { UploadOnesheetFileToBucketProvider } from "./app/helpers/Api/UploadOnesheetFileToBucket";
import { NextUIProvider } from "next2";

const container = document.getElementById("root");
const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
if (container) {
    createRoot(container).render(
        <GoogleOAuthProvider clientId={String(apiKey)}>
            <NextUIProvider>
                <main className="dark text-foreground">
                <MetronicI18nProvider>
                    {/* Provider do template */}
                    <ThemeModeProvider>
                        {/* Provider do template */}
                        <AuthProvider>
                            {/* Provider custom do useApi() */}
                            <ApiProvider>
                                {/* Provider de api pra chamadas no apponi */}
                                <AppOniApiProvider>
                                    {/* Provider do context que componentiza a função de subir arquivos de OneSheet pro bucket */}
                                    <UploadOnesheetFileToBucketProvider>
                                        {/* Provider pra gerenciar o botão de Criar genérico das telas */}
                                        <CreateButtonProvider>
                                            <AppRoutes />
                                        </CreateButtonProvider>
                                    </UploadOnesheetFileToBucketProvider>
                                </AppOniApiProvider>
                            </ApiProvider>
                        </AuthProvider>
                    </ThemeModeProvider>
                </MetronicI18nProvider>
                </main>
            </NextUIProvider>
        </GoogleOAuthProvider>
    );
}
