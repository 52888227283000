import clsx from "clsx";
import { Link } from "react-router-dom";
import { useTranslate } from "../../../../helpers/i18n/useTranslate";
import Placeholder from "../../../../components/Placeholder";
import { Card, CardBody, CardHeader } from "next2";
import { CardTitle } from "../../../../components/Cards/CardTitle";
import { CardSubtitle } from "../../../../components/Cards/CardSubtitle";

const ProjectCardWidget = ({
    color = "primary",
    link,
    image,
    title,
    date,
    progress = 0,
}) => {
    const { t } = useTranslate();
    return (
        <>
            <Card className="p-8 bg-body">
                <CardHeader className="p-0 flex justify-between items-start gap-4">
                    <div className="rounded-full">
                        {image ? (
                            <img
                                src={image}
                                className="h-[50px] w-[50px] align-self-center rounded-full object-cover"
                                alt=""
                            />
                        ) : (
                            <Placeholder
                                className="rounded-full"
                                width="50"
                                height="50"
                                text={String(t("APPS.CORE.PROJECT"))}
                            />
                        )}
                    </div>

                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                        <Link
                            to={link}
                        >
                             <CardTitle text={title} />
                        </Link>
                        <CardSubtitle text={date
                                ? `${t("FORMS.CORE.PROJECT.RDATE")}: ${date}`
                                : t("FORMS.CORE.PROJECT.RDATE.EMPTY")} />
                    </div>
                </CardHeader>
                <CardBody className="pb-0 px-0">
                    <div className="d-flex flex-column w-100 my-6">
                        <span className="text-dark me-2 fw-bold pb-3">
                            {t("APPS.CATALOG.PROJECT.PROGRESS")} {progress}%
                        </span>

                        <div className="progress h-5px w-100">
                            <div
                                className={`progress-bar bg-${color}`}
                                role="progressbar"
                                style={{ width: `${progress}px` }}
                            ></div>
                        </div>
                    </div>
                </CardBody>
            </Card>
            {/* <div className={`card card-xl-stretch my-xl-4`}>
                <div className="card-body">
                    <div className="d-flex flex-stack">
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-60px me-5">
                                <span
                                    className={clsx(
                                        `symbol-label`,
                                        `bg-${color}-light`
                                    )}
                                >
                                    {image ? (
                                        <img
                                            src={image}
                                            className="h-50 align-self-center"
                                            alt=""
                                        />
                                    ) : (
                                        <Placeholder
                                            width="50"
                                            height="50"
                                            text={String(
                                                t("APPS.CORE.PROJECT")
                                            )}
                                        />
                                    )}
                                </span>
                            </div>

                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                <Link
                                    to={link}
                                    className="text-dark fw-bold text-hover-primary fs-5"
                                >
                                    {title}
                                </Link>

                                <span className="text-muted fw-semibold">
                                    {date
                                        ? `${t(
                                              "FORMS.CORE.PROJECT.RDATE"
                                          )}: ${date}`
                                        : t("FORMS.CORE.PROJECT.RDATE.EMPTY")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column w-100 my-6">
                        <span className="text-dark me-2 fw-bold pb-3">
                            {t("APPS.CATALOG.PROJECT.PROGRESS")} {progress}%
                        </span>

                        <div className="progress h-5px w-100">
                            <div
                                className={`progress-bar bg-${color}`}
                                role="progressbar"
                                style={{ width: `${progress}px` }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export { ProjectCardWidget };
