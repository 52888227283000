import React, { useState, useEffect } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import {
    faAmazon,
    faApple,
    faDeezer,
    faFacebook,
    faInstagram,
    faSoundcloud,
    faSpotify,
    faTiktok,
    faXTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { NumbersInvSection } from "../../../components/Form/NumbersInvSection";
import { faHeadphones } from "@fortawesome/pro-solid-svg-icons";

const OnesheetFeaturedNumbersSection = ({
    tabId,
    data,
    setData,
    fieldErrors,
}) => {
    const { t } = useTranslate();
    const [totalNumbers, setTotalNumbers] = useState({});

    const updateNumber = (e) => {
        const name = e.target.name;
        let value = name.startsWith("show") ? e.target.checked : e.target.value;

        setData(tabId, "value", [
            {
                ...data.value[0],
                [name]: value,
            },
        ]);

        setTotalNumbers({
            ...data.value[0],
            [name]: value,
        });
    };

    useEffect(() => {
        if (!data) return;
        setTotalNumbers(data.value[0]);
    }, []);

    // este array contém o nome dos campos que os seus valores serão utilizados para fazer a soma
    // do total de "plays"
    const fieldsNumbersValue = [
        "amazon_numbers",
        "apple_numbers",
        "deezer_numbers",
        "soundcloud_numbers",
        "spotify_streams",
        "tidal_numbers",
        "youtube_views",
    ];

    /**
     * Filtra os valores de um objeto com base no array para somar o total de plays.
     *
     * @param {Object} obj - O objeto contendo os valores a serem filtrados.
     * @returns {Array} - Um array contendo os valores correspondentes às chaves presentes em fieldsNumbersValue.
     */
    const filterValues = (obj) => {
        return Object.keys(obj)
            .filter((key) => fieldsNumbersValue.includes(key))
            .map((key) => Number(obj[key]));
    };

    /**
     * Soma todos os números presentes no array.
     *
     * @param {Array} arr - O array de números a serem somados.
     * @returns {number} - A soma total dos números no array.
     */
    const sumNumbers = (arr) => {
        return arr.reduce((acum, currentValue) => acum + currentValue, 0);
    };

    useEffect(() => {
        const finalValues = filterValues(totalNumbers); //array com números
        setData(tabId, "value", [
            {
                ...data.value[0],
                total_numbers: sumNumbers(finalValues),
            },
        ]);
    }, [totalNumbers]);

    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data.show}
                            onChange={(evt) =>
                                setData(tabId, "show", evt.target.checked)
                            }
                        />
                    </label>

                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>

            <hr className="my-10" />

            <div className="row mb-6">
                <label htmlFor="title" className="form-label">
                    {t("FORMS.ONESHEET.FEATURED-SOCIAL-LABEL")}
                </label>
                <select
                    name="featured_social"
                    id="featured_social"
                    className="form-select"
                    onChange={(e) => updateNumber(e)}
                    value={data.value[0]?.featured_social}
                >
                    <option value="NN">
                        {t("FORMS.ONESHEET.FEATURED-SOCIAL-NONE")}
                    </option>
                    <option value="YT">YouTube</option>
                    <option value="TT">TikTok</option>
                    <option value="IG">Instagram</option>
                    <option value="FB">Facebook</option>
                    <option value="TW">Twitter</option>
                    <option value="SP">Spotify</option>
                    <option value="DZ">Deezer</option>
                    <option value="AM">Amazon</option>
                    <option value="AP">Apple</option>
                    <option value="TD">Tidal</option>
                    <option value="SC">Soundcloud</option>
                </select>
            </div>

            {/* Streams SPOTIFY */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.SPOTIFY-STREAMS")}
                    icon={faSpotify}
                    id="spotify_streams"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.spotify_streams}
                    idShowNumber="show_spotify_streams"
                    showNumber={data.value[0]?.show_spotify_streams}
                    nameShowNumber="show_spotify_streams"
                />
            </div>

            {/* Seguidores SPOTIFY */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.SPOTIFY-FANBASE")}
                    icon={faSpotify}
                    id="spotify_fanbase"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.spotify_fanbase}
                    idShowNumber="show_spotify_fanbase"
                    showNumber={data.value[0]?.show_spotify_fanbase}
                    nameShowNumber="show_spotify_fanbase"
                />
            </div>

            {/* Playlist Reach SPOTIFY */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.SPOTIFY-PLAYLIST-REACH")}
                    icon={faSpotify}
                    id="spotify_playlist_reach"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.spotify_playlist_reach}
                    idShowNumber="show_spotify_playlist_reach"
                    showNumber={data.value[0]?.show_spotify_playlist_reach}
                    nameShowNumber="show_spotify_playlist_reach"
                />
            </div>

            {/* Inscritos Youtube */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.YOUTUBE-SUBS")}
                    icon={faYoutube}
                    id="youtube_fanbase"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.youtube_fanbase}
                    idShowNumber="show_youtube_fanbase"
                    showNumber={data.value[0]?.show_youtube_fanbase}
                    nameShowNumber="show_youtube_fanbase"
                />
            </div>

            {/* Views Youtube */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.YOUTUBE-VIEWS")}
                    icon={faYoutube}
                    id="youtube_views"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.youtube_views}
                    idShowNumber="show_youtube_views"
                    showNumber={data.value[0]?.show_youtube_views}
                    nameShowNumber="show_youtube_views"
                />
            </div>

            {/* Fanbase Tiktok */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.TIKTOK-FANBASE")}
                    icon={faTiktok}
                    id="tiktok_fanbase"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.tiktok_fanbase}
                    idShowNumber="show_tiktok_fanbase"
                    showNumber={data.value[0]?.show_tiktok_fanbase}
                    nameShowNumber="show_tiktok_fanbase"
                />
            </div>

            {/* Likes Tiktok */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.TIKTOK-LIKES")}
                    icon={faTiktok}
                    id="tiktok_likes"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.tiktok_likes}
                    idShowNumber="show_tiktok_likes"
                    showNumber={data.value[0]?.show_tiktok_likes}
                    nameShowNumber="show_tiktok_likes"
                />
            </div>

            {/* Seguidores Instagram */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.INSTAGRAM-FANBASE")}
                    icon={faInstagram}
                    id="instagram_fanbase"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.instagram_fanbase}
                    idShowNumber="show_instagram_fanbase"
                    showNumber={data.value[0]?.show_instagram_fanbase}
                    nameShowNumber="show_instagram_fanbase"
                />
            </div>

            {/* Likes Instagram */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.INSTAGRAM-LIKES")}
                    icon={faInstagram}
                    id="instagram_likes"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.instagram_likes}
                    idShowNumber="show_instagram_likes"
                    showNumber={data.value[0]?.show_instagram_likes}
                    nameShowNumber="show_instagram_likes"
                />
            </div>

            {/* Seguidores TWITTER */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.TWITTER-FANBASE")}
                    icon={faXTwitter}
                    id="twitter_fanbase"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.twitter_fanbase}
                    idShowNumber="show_twitter_fanbase"
                    showNumber={data.value[0]?.show_twitter_fanbase}
                    nameShowNumber="show_twitter_fanbase"
                />
            </div>

            {/* Likes TWITTER */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.TWITTER-LIKES")}
                    icon={faXTwitter}
                    id="twitter_likes"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.twitter_likes}
                    idShowNumber="show_twitter_likes"
                    showNumber={data.value[0]?.show_twitter_likes}
                    nameShowNumber="show_twitter_likes"
                />
            </div>

            {/* Números Facebook */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.FACEBOOK-NUMBERS")}
                    icon={faFacebook}
                    id="facebook_numbers"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.facebook_numbers}
                    idShowNumber="show_facebook_numbers"
                    showNumber={data.value[0]?.show_facebook_numbers}
                    nameShowNumber="show_facebook_numbers"
                />
            </div>

            {/* Números DEEZER */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.DEEZER-NUMBERS")}
                    icon={faDeezer}
                    id="deezer_numbers"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.deezer_numbers}
                    idShowNumber="show_deezer_numbers"
                    showNumber={data.value[0]?.show_deezer_numbers}
                    nameShowNumber="show_deezer_numbers"
                />
            </div>

            {/* Números AMAZON */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.AMAZON-NUMBERS")}
                    icon={faAmazon}
                    id="amazon_numbers"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.amazon_numbers}
                    idShowNumber="show_amazon_numbers"
                    showNumber={data.value[0]?.show_amazon_numbers}
                    nameShowNumber="show_amazon_numbers"
                />
            </div>

            {/* Números APPLE */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.APPLE-NUMBERS")}
                    icon={faApple}
                    id="apple_numbers"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.apple_numbers}
                    idShowNumber="show_apple_numbers"
                    showNumber={data.value[0]?.show_apple_numbers}
                    nameShowNumber="show_apple_numbers"
                />
            </div>

            {/* Números TIDAL */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.TIDAL-NUMBERS")}
                    icon="Tidal"
                    id="tidal_numbers"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.tidal_numbers}
                    idShowNumber="show_tidal_numbers"
                    showNumber={data.value[0]?.show_tidal_numbers}
                    nameShowNumber="show_tidal_numbers"
                />
            </div>

            {/* Números SoundCloud */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.SOUNDCLOUD-NUMBERS")}
                    icon={faSoundcloud}
                    id="soundcloud_numbers"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.soundcloud_numbers}
                    idShowNumber="show_soundcloud_numbers"
                    showNumber={data.value[0]?.show_soundcloud_numbers}
                    nameShowNumber="show_soundcloud_numbers"
                />
            </div>

            {/* Números Total de plays */}
            <div className="row mb-6">
                <NumbersInvSection
                    label={t("FORMS.ONESHEET.SHOW-TOTAL-PLAYS")}
                    icon={faHeadphones}
                    id="total_numbers"
                    handleChange={(e) => updateNumber(e)}
                    value={data?.value[0]?.total_numbers}
                    idShowNumber="show_total_numbers"
                    showNumber={data.value[0]?.show_total_numbers}
                    nameShowNumber="show_total_numbers"
                />
            </div>
        </>
    );
};

export { OnesheetFeaturedNumbersSection };
