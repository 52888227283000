import { useState, useEffect } from "react";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import "./styles.css";

/**
 * Componente Intro
 * @description
 * Este componente é responsável por exibir uma sequência de etapas (steps) usando a biblioteca Intro.js,
 * que fornece uma experiência de introdução para orientar os usuários através de recursos e funcionalidades.
 *
 * @param {array} steps - Um array de objetos que representa as etapas a serem exibidas no guia de introdução.
 *                        Cada objeto deve ter as seguintes propriedades:
 *                           - element: o seletor do elemento (uma classe ou id . #) no qual o destaque da etapa deve ser aplicado.
 *                           - title: título da etapa
 *                           - intro: texto que será exibido na etapa.
 *                           - position: a posição da dica da etapa (top, bottom, left, right).
 *                           - tooltipClass: classe css para ser aplicado no "card" das etapas
 *
 * @info
 * os steps devem ficar no componente que vai ser usado o "Intro".
 *
 *
 * Exemplo de uso:
 * import React from "react";
 * import { Intro } from "./Intro";
 *
 * const steps = [
 *     {
 *         element: "#step1",
 *         intro: "Este é o primeiro passo da introdução.",
 *         position: "bottom",
 *         tooltipClass: "bg-body",
 *     },
 *     {
 *         element: "#step2",
 *         intro: "Aqui vem o segundo passo.",
 *         position: "top",
 *         tooltipClass: "bg-body",
 *     },
 *     // Adicionar mais etapas, se necessário.
 * ];
 *
 * const App = () => {
 *     return (
 *         <div>
 *             <div id="step1">Elemento 1</div>
 *             <div id="step2">Elemento 2</div>
 *             <Intro steps={steps} />
 *         </div>
 *     );
 * };
 *
 */

export const Intro = ({ steps }) => {
    const { t } = useTranslate();
    const [stepsEnabled, setStepsEnabled] = useState(true);

    // options para deixar o componente com mais funcionalidades e de acordo com o tema
    const options = {
        nextLabel: t("INTRO.NEXT"),
        prevLabel: t("INTRO.BACK"),
        showProgress: true,
        dontShowAgain: true,
        dontShowAgainLabel: t("INTRO.DONT-SHOW-AGAIN"),
        buttonClass: "btn btn-light-primary",
        progressBarAdditionalClass: "bg-primary",
        doneLabel: t("INTRO.DONE"),
    };

    const onExit = () => {
        setStepsEnabled(false);
    };

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }

    return (
        <>
            {getWindowDimensions().width < 992 ? null : (
                <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={0}
                    onExit={onExit}
                    options={options}
                />
            )}
        </>
    );
};
