import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/AuthProvider";
import { form_api } from "../../../helpers/Api";
import { observer } from "mobx-react-lite";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { toast, ToastContainer } from "react-toastify";
import { CustomSaveButton } from "../../../components/CustomSaveButton";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { InputDefault } from "../../../components/Form/InputDefault";
import AppDownloadButtons from "../../../components/AppDownloadButtons";

export const Login = observer(() => {
    const { t } = useTranslate();
    const [loading, setLoading] = useState(false);
    const { saveAuth } = useAuth();
    const { setUserDataOnContext } = useApi();
    const [message, setMessage] = useState("");
    const [fieldErrors, setFieldErrors] = useState([]);
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    });
    const [showPassword, setShowPassword] = useState(false);

    const getUser = async (access, refresh = "") => {
        try {
            await setUserDataOnContext(access, refresh);
        } catch (err) {
            console.error(err);
            toast.error(t("GENERAL.CONNECTION-ERROR"));
        }
    };

    const handleChange = (evt) => {
        let name = evt.target.name;
        let value = evt.target.value;
        setLoginData({
            ...loginData,
            [name]: value,
        });

        setMessage("");

        const removeFieldChanged = fieldErrors.filter((item) => item !== name);

        if (fieldErrors.includes(name)) {
            setFieldErrors(removeFieldChanged);
        }
    };

    async function login(e) {
        e.preventDefault();
        setLoading(true);
        let formData = new FormData();
        formData.append("email", loginData.email);
        formData.append("password", loginData.password);
        try {
            const loginResponse = await form_api.post("auth/login/", formData);
            // Se o login tiver sido feito, salve os tokens e redirecione o usuário para o artist picker
            if (loginResponse.status === 200 && !loginResponse.data.status) {
                let data = loginResponse.data;
                saveAuth({
                    api_token: data.access,
                    refreshToken: data.refresh,
                });
                getUser(data.access, data.refresh);
            } else {
                toast.error(t("AUTH.LOGIN.NO-PROFILE"));
            }
        } catch (err) {
            console.error(err);
            if (!err.response) {
                toast.error(t("GENERAL.CONNECTION-ERROR"));
            } else {
                // tratamento de campos vazios
                if (err.response.status === 400) {
                    setFieldErrors(Object.keys(err.response.data));
                }
                // tratamento de dados inválidos
                else if (err.response.status === 401) {
                    if (
                        err.response.data.detail ===
                        "No active account found with the given credentials"
                    ) {
                        setMessage(t("GENERAL.ACCOUNT-NOT-FOUND"));
                    } else toast.error(t("GENERAL.FORBIDDEN-ERROR"));
                }
                // tratamento de usuário sem perfil
                else if (err.response.status === 403) {
                    toast.error(t("GENERAL.FORBIDDEN-ERROR"));
                }
                // tratamento de erro 500
                else {
                    toast.error(t("GENERAL.CONNECTION-ERROR"));
                }
            }
        } finally {
            setLoading(false);
        }
    }

    const handleGoogleLoginSuccess = async (credentialResponse) => {
        setLoading(true);
        var decoded = jwt_decode(credentialResponse.credential);
        try {
            validateGoogleTokenAndObtainSession(
                decoded,
                credentialResponse.credential
            );
        } catch (e) {
            console.error(e);
            setMessage(t("GENERAL.ACCOUNT-NOT-FOUND"));
        } finally {
            setLoading(false);
        }
    };

    const validateGoogleTokenAndObtainSession = async (data, idToken) => {
        const header = {
            Authorization: idToken,
            "Content-type": "multipart/form-data",
        };
        let formData = new FormData();
        formData.append("email", data.email);
        try {
            let loginResponse = await form_api.post(
                "auth/google-login/",
                formData,
                {
                    headers: header,
                }
            );
            saveAuth({
                api_token: loginResponse.data.access,
                refreshToken: loginResponse.data.refresh,
            });
            getUser(loginResponse.data.access, loginResponse.data.refresh);
        } catch (e) {
            if (e.response.status === 404) {
                toast.error(t("AUTH.LOGIN.NO-ACCOUNT"));
                window.open(
                    "https://1ed7bcbb.sibforms.com/serve/MUIFACm2sQsOX0DKv3lw7gSltA52PF05FYyJVtmelxnU6oxkS5FJiH4giAXu7Q8egkSl91FjyF0-Z8zBc_9P95djiRUlS-BOKbqfL00-JAdEYhFLRxx2XQ9gqqWlKr1ZwBCuzqdhGSg5S5nD6z_x3wRfiZ_Ir58e27fDXFznoPDimO0Rg3b6a3BROLSQohsCZjD3cZULljwVPmIR",
                    "_blank"
                );
            } else toast.error(t("GENERAL.FATAL-ERROR"));
        }
    };

    const handleGoogleLoginError = () => {
        toast.error(t("GENERAL.FATAL-ERROR"));
        setLoading(false);
    };

    const facebookLogin = async (response) => {
        let data = response.data;
        const header = {
            Authorization: data.accessToken,
            "Content-type": "multipart/form-data",
        };
        let formData = new FormData();
        formData.append("email", data.email);
        try {
            let loginResponse = await form_api.post(
                "auth/facebook-login/",
                formData,
                {
                    headers: header,
                }
            );
            saveAuth({
                api_token: loginResponse.data.access,
                refreshToken: loginResponse.data.refresh,
            });
            getUser(loginResponse.data.access, loginResponse.data.refresh);
        } catch (e) {
            if (e.response?.status === 404) {
                setMessage(t("GENERAL.ACCOUNT-NOT-FOUND"));
            } else {
                toast.error(t("GENERAL.FATAL-ERROR"));
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <ToastContainer className="Toastify__toast--dark" />
            <form
                className="form w-100"
                onSubmit={login}
                noValidate
                id="kt_login_signin_form"
            >
                {/* begin::Heading */}
                <div className="text-center mb-10">
                    <span className="font-bold text-2xl mb-3">
                        {t("AUTH.LOGIN.TITLE")}
                    </span>
                    {/*<div className="flex flex-col gap-2 mt-4 md:flex-row md:gap-0 text-gray-400 font-bold text-xl justify-center ">*/}
                    {/*    {t("AUTH.GENERAL.NO_ACCOUNT")}*/}
                    {/*    {process.env.REACT_APP_ENVIRONMENT === "production" ? (*/}
                    {/*        <a*/}
                    {/*            className="text-[#009EF7] font-extrabold ml-3"*/}
                    {/*            href="https://1ed7bcbb.sibforms.com/serve/MUIFACm2sQsOX0DKv3lw7gSltA52PF05FYyJVtmelxnU6oxkS5FJiH4giAXu7Q8egkSl91FjyF0-Z8zBc_9P95djiRUlS-BOKbqfL00-JAdEYhFLRxx2XQ9gqqWlKr1ZwBCuzqdhGSg5S5nD6z_x3wRfiZ_Ir58e27fDXFznoPDimO0Rg3b6a3BROLSQohsCZjD3cZULljwVPmIR"*/}
                    {/*            target="_blank"*/}
                    {/*            rel="noopener noreferrer"*/}
                    {/*        >*/}
                    {/*            {t("AUTH.GENERAL.SIGNUP_BUTTON")}*/}
                    {/*        </a>*/}
                    {/*    ) : (*/}
                    {/*        <Link*/}
                    {/*            to="/auth/registration"*/}
                    {/*            className="text-[#009EF7] font-extrabold ml-3"*/}
                    {/*        >*/}
                    {/*            {t("AUTH.GENERAL.SIGNUP_BUTTON")}*/}
                    {/*        </Link>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
                {/* begin::Heading */}

                {message ? (
                    <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold text-center">
                            {message}
                        </div>
                    </div>
                ) : null}
                {/* begin::Email */}
                <div className="mb-10">
                    <InputDefault
                        label={t("AUTH.INPUT.USERNAME")}
                        id="email"
                        type="email"
                        handleChange={(e) => handleChange(e)}
                        value={loginData.email}
                        required
                        fieldError={fieldErrors}
                        error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                        placeholder={t("AUTH.INPUT.USERNAME")}
                    />
                    {/* {fieldErrors.includes("email") ? (
                        <div className="text-danger mt-2">
                            {t("FORMS.GENERAL.MANDATORY-FIELD")}
                        </div>
                    ) : null} */}
                </div>
                {/* end::Email */}

                {/* begin::Senha */}
                <div className="mb-10">
                    <InputDefault
                        label={t("AUTH.INPUT.PASSWORD")}
                        idPassword="showPassword"
                        id="password"
                        isPassword
                        name="password"
                        value={loginData.password}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        type={showPassword ? "text" : "password"}
                        handleChange={(e) => handleChange(e)}
                        placeholder={t("AUTH.INPUT.PASSWORD")}
                        required
                        fieldError={fieldErrors}
                        error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                        recoverPassword={
                            <Link
                                to="/auth/forgot-password"
                                className="font-bold text-base text-[#009EF7] d-flex justify-end"
                            >
                                {t("AUTH.FORGOT.TITLE")}
                            </Link>
                        }
                    />
                    {/* {fieldErrors.includes("password") ? (
                        <div className="text-danger mt-2">
                            {t("FORMS.GENERAL.MANDATORY-FIELD")}
                        </div>
                    ) : null} */}

                    {/* <Link
                        to="/auth/forgot-password"
                        className="font-bold text-base text-[#009EF7] d-flex justify-end"
                    >
                        {t("AUTH.FORGOT.TITLE")}
                    </Link> */}
                </div>
                {/* end::Senha */}

                {/* begin::Action */}
                <div className="text-center">
                    <CustomSaveButton
                        type="submit"
                        id="kt_sign_in_submit"
                        className="bg-[#009EF7] w-full mb-5"
                        formSending={loading}
                        buttonText={"AUTH.LOGIN.BUTTON"}
                    />

                    {/* begin::Separator */}
                    {/*<div className="text-center text-gray-400 uppercase font-extrabold mb-5">*/}
                    {/*    {t("GENERAL.OR")}*/}
                    {/*</div>*/}

                    {/* Login com Google */}
                    {/*<button*/}
                    {/*  type="button"*/}
                    {/*  className="btn btn-flex flex-center bg-white text-secondary btn-lg w-100 mb-5"*/}
                    {/*  onClick={googleLogin}*/}
                    {/*>*/}
                    {/*  <img alt="Logo" src={GoogleIcon} className="h-20px me-3" />*/}
                    {/*  {t("GENERAL.LOGIN.CONTINUE-WITH")} Google*/}
                    {/*</button>*/}
                    {/*<div*/}
                    {/*    id="social-login-buttons"*/}
                    {/*    onClick={() => setLoading(true)}*/}
                    {/*>*/}
                    {/*<GoogleLogin*/}
                    {/*    onSuccess={handleGoogleLoginSuccess}*/}
                    {/*    onError={handleGoogleLoginError}*/}
                    {/*    // useOneTap*/}
                    {/*/>*/}
                    {/* end::Google link */}

                    {/* Login com Facebook */}
                    {/*<LoginSocialFacebook*/}
                    {/*  appId={appId}*/}
                    {/*  onReject={(error) => {*/}
                    {/*    console.error(error);*/}
                    {/*    toast.error(t("GENERAL.FATAL-ERROR"));*/}
                    {/*    setLoading(false);*/}
                    {/*  }}*/}
                    {/*  onResolve={(response) => facebookLogin(response)}*/}
                    {/*>*/}
                    {/*  <FacebookLoginButton*/}
                    {/*    style={{*/}
                    {/*      borderRadius: ".625rem",*/}
                    {/*      display: "flex",*/}
                    {/*      justifyContent: "center",*/}
                    {/*      alignItems: "center",*/}
                    {/*      fontSize: "15px",*/}
                    {/*      margin: 0,*/}
                    {/*      width: "100%",*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    <p className="m-0">*/}
                    {/*      {t("GENERAL.LOGIN.CONTINUE-WITH")} Facebook*/}
                    {/*    </p>*/}
                    {/*  </FacebookLoginButton>*/}
                    {/*</LoginSocialFacebook>*/}
                    {/*</div>*/}
                    {/* end::Separator */}
                </div>
                {/* end::Action */}
            </form>
            <AppDownloadButtons />
        </>
    );
});
