import React from "react";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { ArtistPicker } from "./ArtistPicker";

const ChangeArtistOrClient = () => {
    const { t } = useTranslate();
    return (
        <div
            id="change_artist_or_client_tab"
            className="bg-white w-40"
            style={{ minWidth: "400px", margin: "0", padding: "0" }}
            data-kt-drawer="true"
            data-kt-drawer-name="change_artist_or_client_tab"
            data-kt-drawer-activate="true"
            data-kt-drawer-overlay="true"
            // data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
            data-kt-drawer-direction="end"
            data-kt-drawer-toggle="#change_artist_or_client_tab_toggle"
            data-kt-drawer-close="#change_artist_or_client_tab_close"
        >
            <div className="card shadow-none rounded-0 w-100">
                <div
                    className="card-header"
                    id="change_artist_or_client_tab_header"
                    style={{ padding: "10px" }}
                >
                    <div className="card-toolbar">
                        <div className="d-flex gap-3 align-items-center">
                            <button
                                type="button"
                                className="btn btn-sm btn-icon btn-active-light-primary me-n5"
                                id="change_artist_or_client_tab_close"
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                        </div>
                        <h3 className="card-title fw-bold text-dark pl-10">
                            {String(t("FORMS.CORE.PROJECT.ARTIST.SELECT"))}
                        </h3>
                    </div>
                </div>
                <div id="change_artist_or_client_tab_body">
                    <div
                        id="change_artist_or_client_tab_scroll"
                        className="position-relative scroll-y me-n5 pe-1"
                        data-kt-scroll="true"
                        data-kt-scroll-height="auto"
                        data-kt-scroll-wrappers="#change_artist_or_client_tab_body"
                        data-kt-scroll-dependencies="#change_artist_or_client_tab_header, #change_artist_or_client_tab_footer"
                        data-kt-scroll-offset="5px"
                    >
                        <ArtistPicker />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ChangeArtistOrClient };
