import React from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { TidalLogo } from "../../TidalLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useThemeMode } from "../../../layout/theme-mode/ThemeModeProvider";

/**
 * @description
 * Componente de formulário para exibir um input de entrada de texto com uma opção de checkbox de exibição de números.
 *
 * @component
 * @param {string} label - label associada ao input.
 * @param {string} icon - icone a ser mostrada junto com a label.
 * @param {string} id - O ID do campo de entrada.
 * @param {function} handleChange - Função de retorno de chamada para lidar com a alteração do valor do input.
 * @param {string} value - O valor atual do campo de entrada.
 * @param {string} idShowNumber - O ID da opção de checkbox para mostrar números.
 * @param {boolean} showNumber - Indica se a opção de exibir números está marcada.
 * @param {string} nameShowNumber - O nome da opção de checkbox para mostrar números.
 * @returns {JSX.Element} O componente de entrada de texto com a opção de exibição de números.
 */

export const NumbersInvSection = ({
    label,
    icon,
    id,
    handleChange,
    value,
    idShowNumber,
    showNumber,
    nameShowNumber,
}) => {
    const { t } = useTranslate();
    const { mode } = useThemeMode();

    return (
        <>
            <label htmlFor={id} className="form-label d-flex align-items-center gap-3">
                {icon === "Tidal" ? (
                    // Foi feito dessa forma pq não tem esse logo no fontawesome.
                    <TidalLogo color={mode === 'light' ? '#3F4254' : '#CDCDDE'} />
                ) : (
                    <FontAwesomeIcon size="xl" icon={icon} />
                )}
                {label}
            </label>
            <div className="row flex-column">
                <div className="col-12">
                    <input
                        id={id}
                        type="text"
                        name={id}
                        className="form-control form-control-lg form-control-solid"
                        onChange={handleChange}
                        maxLength={100}
                        value={value}
                    />
                </div>
                <div className="col-12 mt-3">
                    <div className="d-flex gap-3 justify-content-sm-start">
                        <input
                            id={idShowNumber}
                            className="form-check-input-sm mr-2"
                            type="checkbox"
                            name={nameShowNumber}
                            checked={showNumber}
                            onChange={(e) => handleChange(e)}
                        />
                        <label htmlFor={idShowNumber} className=" pb-0">
                            {t("FORMS.ONESHEET.SHOW-NUMBERS")}
                        </label>
                    </div>
                </div>
            </div>

            <hr className="my-5" />
        </>
    );
};
