import { Card, CardBody, CardHeader } from "next2";
import { CardTitle } from "../CardTitle";
import { Link } from "react-router-dom";
import PlaceholderImage from "../../Placeholder";
import { CardSubtitle } from "../CardSubtitle";

export const CardCourse = ({
    redirectTo,
    thumbnail,
    name,
    status,
    badgeColor,
    description,
}) => {
    const iconPlaceholder = "img";
    return (
        <div className="min-h-[200px]">
            <Link to={redirectTo} className="h-full">
                <Card className="p-8 bg-body min-h-[200px]">
                    <CardHeader className="p-0 items-start gap-8">
                        {thumbnail ? (
                            <img
                                src={thumbnail}
                                alt="card2"
                                className="h-[50px] w-[50px] align-self-center rounded-full object-cover"
                            />
                        ) : (
                            <PlaceholderImage
                                width="50"
                                height="50"
                                text={String(iconPlaceholder)}
                            />
                        )}
                        <div className="flex flex-col">
                            <CardTitle text={name} className={"mb-2"} />
                            <span
                                className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}
                            >
                                {status}
                            </span>
                        </div>
                    </CardHeader>
                    <CardBody className="px-0">
                        <CardSubtitle text={description} />
                    </CardBody>
                </Card>
            </Link>
        </div>
    );
};
