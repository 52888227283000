import { makeObservable, observable, action } from "mobx";

class FormVariables {
  fieldErrors = {};
  data = {};

  setForm = ({ name, value }) => {
    this.data[name] = value;
  };

  setFieldErrors = (value) => {
    this.fieldErrors = value;
  };

  getFielErrors = (field) => {
    // Usada para tratar o erro de quando o formulário não tem erros ou quando o campo não tem erro.
    if (this.fieldErrors !== undefined) {
      try {
        return this.fieldErrors[field];
      } catch (error) {
        return null;
      }
      return null;
    }
  };

  constructor() {
    makeObservable(this, {
      data: observable,
      setForm: action,
      fieldErrors: observable,
      setFieldErrors: action,
    });
  }
}

export const DefaultFormContext = FormVariables;
