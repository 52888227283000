import {Card, CardBody, CardHeader} from "next2";
import {CardTitle} from "../CardTitle";
import {Link} from "react-router-dom";
import React from "react";
import {useTranslate} from "../../../helpers/i18n/useTranslate";
import moment from "moment";
import 'moment/locale/pt-br';

export const CardTutorial = ({
                                 redirectTo,
                                 featured_image,
                                 title,
                                 category,
                                 created_at,
                             }) => {
    const {t} = useTranslate();

    const formattedDate = moment(created_at).locale('pt-br').format("DD [de] MMMM [de] YYYY");

    return (
        <div className="min-h-[100px]">
            <Card className="p-4 bg-body min-h-[380px] max-w-[350px]">
                <CardHeader>
                    {featured_image && (
                        <img
                            src={featured_image}
                            alt="card2"
                            className="aspect-video object-cover w-full rounded-lg"
                        />
                    )}
                </CardHeader>
                <CardBody className="flex justify-center">
                    <div className="d-flex justify-content-between">
                        <CardTitle text={title} className={"mb-2 line-clamp-2"}/>
                        <span
                            className={`badge badge-light fw-bolder max-h-[30px] ml-4`}
                        >
                                {category}
                            </span>
                    </div>
                    <div className="d-flex justify-content-between w-full mt-8">
                        <Link to={redirectTo} className="h-full">
                            <a
                                className="btn btn-primary w-30"
                                target="_blank noopener noreferrer"
                            >
                                {t("APPS.ACADEMY.READ.MORE")}
                            </a>
                        </Link>
                        <span
                            className={`text-gray-500 font-bold mt-4`}
                        >
                                {formattedDate}
                            </span>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};