import { Button, Container, Image, Link, Text } from "@nextui-org/react";
import styles from "./OnesheetHeroPreview.module.css";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { CustomFileInput } from "../../../components/Form/CustomFileInput";
import { useEffect, useState } from "react";
import { useOnesheetFileUploader } from "../../../helpers/Api/UploadOnesheetFileToBucket";
import { useParams } from "react-router-dom";
import "./OnesheetHeroPreview.css";
import { toast } from "react-toastify";

/**
 * Prévia para o elemento Hero do Onesheet
 * @param {object} data informações necessárias pra renderização da prévia
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetHeroPreview = ({ data }) => {
    const { t } = useTranslate();
    const [mainBgImage, setMainBgImage] = useState([]);
    const { uploadOnesheetFileToBucket } = useOnesheetFileUploader();
    const { onesheetId } = useParams();

    const gradientStyle = {
        margin: 0,
        background: "rgb(0,0,0)",
        background: `linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 70%)`,
    };

    /**
     * Setter do background no data quando a mainBgImage muda (ou seja, quando alguem sobe algum arquivo lá). Fazemos
     * isso pra mudar a preview na hora que a pessoa subir uma imagem nova, e aí com isso ainda tem o bônus de que
     * quando formos fazer o save, o background já salva a imagem no bucket e retorna o path pra ser salvo no backend
     */
    useEffect(() => {
        if (mainBgImage.length > 0) {
            // remove os espaços que há no nome do arquivo, para não dar mais erro
            const removeSpaceFromFilename = mainBgImage[0].file.name.replace(
                /\s/g,
                ""
            );
            uploadOnesheetFileToBucket(
                mainBgImage[0].file,
                removeSpaceFromFilename,
                `${process.env.REACT_APP_ENVIRONMENT}/onesheet/${onesheetId}/${data.id}/background/`
            ).then((filename) => {
                data.handleChange(data.id, "background", filename);
                data.handleSaveElement(data.id);
                toast.success(t('FORMS.ONESHEET.MAIN-BG-IMAGE-SUCCESS'))
            });
        }
    }, [mainBgImage]);

    return (
        <Container
            id={data.id}
            xl
            css={{
                h: "60vh",
                p: 0,
                backgroundImage: `url(${data.background})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
            }}
        >
            {data?.extraContent ? (
                <Image
                    src={data?.extraContent}
                    css={{
                        mh: "50px",
                        mt: "50px",
                    }}
                />
            ) : null}

            <Container
                xl
                css={{ h: "100%", pb: 16 }}
                display="flex"
                direction="column"
                justify="flex-end"
                alignContent="flex-start"
                style={gradientStyle}
            >
                {data.background.charAt(0) === "#" && (
                    <div className="d-flex align-items-center mb-15">
                        <span className="fs-4 fw-bold text-gray-400">
                            {t("APPS.ONESHEET.HERO-EMPTY")}
                        </span>
                    </div>
                )}
                <div className="flex flex-col w-full mw-[1300px] px-[6rem] py-[5rem]">
                    <Text
                        h4
                        color={data?.fontColor}
                        className={styles.title}
                        size="2.5rem"
                    >
                        {data?.title}
                    </Text>
                    <Text
                        h1
                        color={data?.secondary_color}
                        className={styles.subtitle}
                        size="4.5rem"
                        css={{ m: 0 }}
                    >
                        {data?.subtitle}
                    </Text>
                    {data?.value && (
                        <Link
                            href={data?.value}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.button}
                        >
                            <Button auto css={{ bc: data?.secondary_color }}>
                                <Text
                                    css={{
                                        color: data?.fontColor,
                                        fontWeight: "bold",
                                        fontSize: 16,
                                    }}
                                >
                                    {data?.typeSelected}
                                </Text>
                            </Button>
                        </Link>
                    )}
                </div>

                {data.edit ? (
                    <CustomFileInput
                        wrapperClassName={"w-[200px] cursor-pointer filepond-button"}
                        fieldName={"background"}
                        inputClassName={"cursor-pointer "}
                        uploadedFileSrc={data?.background}
                        files={mainBgImage}
                        setFiles={setMainBgImage}
                        buttonText={t("FORMS.FILEPOND.QUICK-EDIT-BG-IMAGE")}
                    />
                ) : null}
            </Container>
        </Container>
    );
};

export { OnesheetHeroPreview };
