import { Grid, Input, Textarea } from "@nextui-org/react";
import styles from "../onesheet/previewSections/OnesheetInviteForm.module.css";
import MaskedInput from "react-input-mask";
import { CustomSaveButton } from "../../components/CustomSaveButton";
import React, { useEffect, useState } from "react";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import api from "../../helpers/Api";
import { toast } from "react-toastify";
import { dynamicSort } from "../../helpers/usefulFunctions";
import { Subtitle } from "../onesheet/Components/Subtitle";
import { Title } from "../onesheet/Components/Title";
import { LoadingSpinner } from "../../components/LoadingSpinner";

const initialData = {
    producer_name: "",
    email: "",
    phone: "",
    event_type: "",
    event_name: "",
    event_public_type: "",
    event_timestamp: "",
    notes: "",
    event_address: "",
    event_coordinates: "",
    artist: "",
};

const InviteFormFields = ({ title, subtitle, color1, color2, bgColor }) => {
    const [data, setData] = useState(initialData);
    const [addrState, setAddrState] = useState("");
    const [addrCity, setAddrCity] = useState("");
    const [addrComplement, setAddrComplement] = useState("");
    const [addrStateOptions, setAddrStateOptions] = useState([]);
    const [addrCityOptions, setAddrCityOptions] = useState([]);
    const [filteredAddrCityOptions, setFilteredAddrCityOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [mask, setMask] = useState("(99) 99999-9999");

    const { t } = useTranslate();

    /**
     * Carrega a lista de estados e cidades brasileiras
     * @returns {Promise<void>}
     */
    const getCities = async () => {
        try {
            const response = await api.get("get-cities-list");
            setAddrStateOptions(
                response.data.states.sort(dynamicSort("label"))
            );
            setAddrCityOptions(response.data.cities.sort(dynamicSort("label")));
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ERROR-PAGE.TITLE"));
        }
    };

    /**
     * handler de alteracao do state de dados do formulario
     * @param {Object} evt
     */
    const handleChange = (evt) => {
        let name = evt.target.name;
        let value = evt.target.value;
        setData({
            ...data,
            [name]: value,
        });
    };

    /**
     * Carrega dados necessários assim que a tela carrega
     */
    useEffect(() => {
        getCities().then(() => setLoading(false));
    }, []);

    /**
     * Altera as opcoes de cidades a serem escolhidas toda vez que o estado mudar. Exibe nas opcoes de ciades apenas as
     * cidades daquele estado
     */
    useEffect(() => {
        let tempCityOptions = [...addrCityOptions].filter(
            (city) => city.uf === addrState
        );
        setFilteredAddrCityOptions(tempCityOptions);
    }, [addrState]);

    const customStyles = {
        // Estilos para o input do Next, precisa ser separado por causa da variável $$inputColor
        nextInputStyles: {
            $$inputColor: "#1B1B29",
            h: 46,
        },
        // Esitlos para os inputs em geral
        inputStyles: {
            color: "#929292",
            width: "100%",
            backgroundColor: "#1B1B29",
        },
        label: {
            color: color1,
            fontWeight: "bold",
        },
    };

    return (
        <>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <form className="form rounded">
                    <Grid.Container gap={2} css={{ mw: "1300px", m: "0 auto" }}>
                        <Grid css={{ mb: "1.5rem" }}>
                            <Title color={color1} text={title} />
                            <Subtitle color={color2} text={subtitle} />
                        </Grid>
                    </Grid.Container>

                    <div className="px-3">
                        <Grid.Container
                            gap={2}
                            css={{
                                w: "auto",
                                mw: "1300px",
                                m: "0 auto",
                                p: 0,
                            }}
                        >
                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="required form-label"
                                    htmlFor="producer_name"
                                >
                                    {t("FORMS.INVITES.P.PROD-NAME")}
                                </label>
                                <Input
                                    shadow={false}
                                    width="100%"
                                    size="xl"
                                    required
                                    name="producer_name"
                                    id="producer_name"
                                    value={data.producer_name}
                                    onChange={handleChange}
                                    css={customStyles.nextInputStyles}
                                    style={customStyles.inputStyles}
                                />
                            </Grid>

                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="required form-label"
                                    htmlFor="email"
                                >
                                    {t("FORMS.INVITES.P.EMAIL")}
                                </label>
                                <Input
                                    shadow={false}
                                    width="100%"
                                    size="xl"
                                    required
                                    name="email"
                                    id="email"
                                    value={data.email}
                                    onChange={handleChange}
                                    css={customStyles.nextInputStyles}
                                    style={customStyles.inputStyles}
                                />
                            </Grid>
                        </Grid.Container>

                        <Grid.Container
                            gap={2}
                            css={{
                                w: "auto",
                                mw: "1300px",
                                m: "0 auto",
                                p: 0,
                            }}
                        >
                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="form-label required"
                                    htmlFor="phone"
                                >
                                    {t("FORMS.INVITES.P.PHONE")}
                                </label>
                                {/* Créditos: https://pt.stackoverflow.com/a/463620/136860 */}
                                <MaskedInput
                                    mask={mask}
                                    onBlur={(e) => {
                                        if (
                                            e.target.value.replace("_", "")
                                                .length === 14
                                        ) {
                                            setMask("(99) 9999-9999");
                                        }
                                    }}
                                    onFocus={(e) => {
                                        if (
                                            e.target.value.replace("_", "")
                                                .length === 14
                                        ) {
                                            setMask("(99) 99999-9999");
                                        }
                                    }}
                                    placeholder="Fixo ou Celular"
                                    name="phone"
                                    id="phone"
                                    defaultValue={data.phone}
                                    onChange={handleChange}
                                    className={`${styles.customInput} ${styles.resetInput}`}
                                    style={customStyles.inputStyles}
                                />
                            </Grid>

                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="required form-label"
                                    htmlFor="event_type"
                                >
                                    {t("FORMS.INVITES.P.TYPE")}
                                </label>
                                <select
                                    required
                                    name="event_type"
                                    id="event_type"
                                    value={data.event_type}
                                    onChange={handleChange}
                                    className={`${styles.customInput} ${styles.customSelect}`}
                                    style={customStyles.inputStyles}
                                >
                                    <option value="" selected disabled>
                                        {t("FORMS.INVITES.TYPE-INPUT")}
                                    </option>
                                    <option value="TH">
                                        {t("FORMS.INVITES.P.TYPE-TH")}
                                    </option>
                                    <option value="CH">
                                        {t("FORMS.INVITES.P.TYPE-CH")}
                                    </option>
                                    <option value="PV">
                                        {t("FORMS.INVITES.P.TYPE-PV")}
                                    </option>
                                </select>
                            </Grid>
                        </Grid.Container>

                        <Grid.Container
                            gap={2}
                            css={{
                                w: "auto",
                                mw: "1300px",
                                m: "0 auto",
                                p: 0,
                            }}
                        >
                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="required form-label"
                                    htmlFor="event_name"
                                >
                                    {t("FORMS.INVITES.P.EVT-NAME")}
                                </label>
                                <Input
                                    shadow={false}
                                    width="100%"
                                    size="xl"
                                    maxLength={250}
                                    required
                                    name="event_name"
                                    id="event_name"
                                    value={data.event_name}
                                    onChange={handleChange}
                                    css={customStyles.nextInputStyles}
                                    style={customStyles.inputStyles}
                                />
                            </Grid>

                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="required form-label"
                                    htmlFor="event_public_type"
                                >
                                    {t("FORMS.INVITES.P.PUBLIC-TYPE")}:
                                </label>
                                <select
                                    required
                                    name="event_public_type"
                                    id="event_public_type"
                                    value={data.event_public_type}
                                    onChange={handleChange}
                                    className={`${styles.customInput} ${styles.customSelect}`}
                                    style={customStyles.inputStyles}
                                >
                                    <option value="" selected disabled>
                                        {t("FORMS.INVITES.TYPE-INPUT")}
                                    </option>
                                    <option value="OP">
                                        {t("FORMS.INVITES.P.PUBLIC-TYPE-OP")}
                                    </option>
                                    <option value="TK">
                                        {t("FORMS.INVITES.P.PUBLIC-TYPE-TC")}
                                    </option>
                                </select>
                            </Grid>
                        </Grid.Container>

                        <Grid.Container
                            gap={2}
                            css={{
                                w: "auto",
                                mw: "1300px",
                                m: "0 auto",
                                p: 0,
                            }}
                        >
                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="required form-label"
                                    htmlFor="event_timestamp"
                                >
                                    {t("FORMS.INVITES.P.TIMESTAMP")}
                                </label>
                                <input
                                    type="datetime-local"
                                    name="event_timestamp"
                                    id="event_timestamp"
                                    required
                                    value={data.event_timestamp}
                                    onChange={handleChange}
                                    className={`${styles.customInput} ${styles.resetInput}`}
                                    style={customStyles.inputStyles}
                                />
                            </Grid>

                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="form-label"
                                    htmlFor="notes"
                                >
                                    {t("FORMS.INVITES.P.DETAILS")}
                                </label>
                                <Textarea
                                    shadow={false}
                                    // css={{ w: "100%" }}
                                    name="notes"
                                    id="notes"
                                    value={data.notes}
                                    onChange={handleChange}
                                    css={customStyles.nextInputStyles}
                                    style={customStyles.inputStyles}
                                />
                            </Grid>
                        </Grid.Container>

                        <Grid.Container
                            gap={2}
                            css={{
                                w: "auto",
                                mw: "1300px",
                                m: "0 auto",
                                pt: "9px",
                                px: 0,
                            }}
                        >
                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="required form-label"
                                    htmlFor="state"
                                >
                                    {t("FORMS.INVITES.STATE")}
                                </label>
                                <select
                                    required
                                    name="state"
                                    id="state"
                                    value={addrState}
                                    onChange={(evt) =>
                                        setAddrState(evt.target.value)
                                    }
                                    className={`${styles.customInput} ${styles.customSelect}`}
                                    style={customStyles.inputStyles}
                                >
                                    <option value="" selected disabled>
                                        {t("FORMS.INVITES.STATE-INPUT")}
                                    </option>
                                    {addrStateOptions.map((option, index) => (
                                        <option
                                            key={`e${index}`}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>

                            <Grid
                                css={{ pb: 0 }}
                                xs={12}
                                md={6}
                                direction="column"
                            >
                                <label
                                    style={customStyles.label}
                                    className="required form-label"
                                    htmlFor="city"
                                >
                                    {t("FORMS.INVITES.CITY")}
                                </label>
                                <select
                                    required
                                    name="city"
                                    id="city"
                                    value={addrCity}
                                    onChange={(evt) =>
                                        setAddrCity(evt.target.value)
                                    }
                                    disabled={!addrState}
                                    className={`${styles.customInput} ${styles.customSelect}`}
                                    style={customStyles.inputStyles}
                                >
                                    <option value="" selected disabled>
                                        {t("FORMS.INVITES.CITY-INPUT")}
                                    </option>
                                    {filteredAddrCityOptions.map(
                                        (option, index) => (
                                            <option
                                                key={`m${index}`}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </option>
                                        )
                                    )}
                                </select>
                            </Grid>
                        </Grid.Container>

                        <Grid.Container
                            gap={2}
                            css={{
                                w: "auto",
                                mw: "1300px",
                                m: "0 auto",
                                p: 0,
                            }}
                        >
                            <Grid xs={12} md={12} css={{ w: "100%" }}>
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <label
                                        style={customStyles.label}
                                        className="required form-label"
                                    >
                                        {t("FORMS.INVITES.P.EVT-ADDRESS")}
                                    </label>
                                    <Input
                                        shadow={false}
                                        size="xl"
                                        name="complement"
                                        defaultValue={addrComplement}
                                        onChange={(evt) =>
                                            setAddrComplement(evt.target.value)
                                        }
                                        required
                                        disabled={!addrCity}
                                        css={customStyles.nextInputStyles}
                                        style={customStyles.inputStyles}
                                    />
                                </div>
                            </Grid>
                        </Grid.Container>
                        <Grid.Container
                            gap={2}
                            css={{
                                w: "auto",
                                mw: "1300px",
                                m: "0 auto",
                                p: 0,
                            }}
                        >
                            <Grid
                                css={{ w: "100%", display: "flex" }}
                                justify="flex-end"
                            >
                                <CustomSaveButton
                                    style={{
                                        backgroundColor: bgColor,
                                        color: color1,
                                        border: `solid 1px ${color2}`,
                                        fontWeight: "bold",
                                    }}
                                    disabled={true}
                                    buttonText="GENERAL.SUBMIT"
                                />

                                {/* <Button type="submit" auto size="xl" disabled={formSending} css={{ br: '0.475rem' }}>
                  {!formSending && t("GENERAL.SUBMIT")}
                  {formSending && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      {t("GENERAL.LOADING")}...{" "}
                      <div>
                        <Loading size="sm" />
                      </div>
                    </span>
                  )}
                </Button> */}
                            </Grid>
                        </Grid.Container>
                    </div>
                </form>
            )}
        </>
    );
};

export { InviteFormFields };
