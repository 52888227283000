import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { CompleteSignupFormContext } from "../CompleteSignupFormContext";
import { useTranslate } from "../../../../../helpers/i18n/useTranslate";

/**
 * PAsso inicial de completar cadastro. O usuário tem que informar se é artista ou gravadora
 * @type {React.FunctionComponent<object>}
 */
const ArtistOrRecorderStep = observer(() => {
  const form = useContext(CompleteSignupFormContext);
  const { t } = useTranslate();

  return (
    <div className="w-100">
      <div className="mb-10">
        <h2>{t("AUTH.REGISTER.USER-TYPE-TITLE")}:</h2>
      </div>
      <div className="mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">
          <input
            className="form-check-input"
            type="radio"
            name="userType"
            id={form.getArtistType()}
            onChange={(e) =>
              form.setForm({ name: e.target.name, value: e.target.id })
            }
            checked={form.data.userType === form.getArtistType()}
          />
          <span className="fw-bold ps-2 fs-6">{t("AUTH.REGISTER.ARTIST")}</span>
        </label>
      </div>
      <div className="mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">
          <input
            className="form-check-input"
            type="radio"
            name="userType"
            id={form.getRecorderType()}
            onChange={(e) =>
              form.setForm({ name: e.target.name, value: e.target.id })
            }
            checked={form.data.userType === form.getRecorderType()}
          />
          <span className="fw-bold ps-2 fs-6">
            {t("AUTH.REGISTER.RECORDER")}
          </span>
        </label>
      </div>
    </div>
  );
});

export { ArtistOrRecorderStep };
