import { ColorPicker } from "../../../components/ColorPicker";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { useEffect, useState } from "react";

/**
 * Formulário de metadados para onesheet
 * @param {String} tabId id unique da secao
 * @param {object} data valores do elemento
 * @param {function} setData setter do dado (handleChange)
 * @param {array} fieldErrors erros de validacao
 *
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetMetadataSection = ({ tabId, data, setData, fieldErrors }) => {
    const { t } = useTranslate();
    const [displayColorSelection, setDisplayColorSelection] = useState({
        fontColor: "none",
        secondary_color: "none",
        background: "none",
    });

    /**
     Função responsável por lidar com o evento de exibição do seletor de cores.
     @param {String} id - id do objeto do evento que disparou a função.
     @param {Boolean} show - indica se o display é flex ou none
     @description
     "Seta" determinado elemento como display flex de acordo com o id
     que pode ser: fontColor secondary_color ou background
     */
    const handleShowColorPicker = (id, show) => {
        setDisplayColorSelection((prevState) => ({
            ...prevState,
            [id]: show ? "flex" : "none",
        }));
    };

    return (
        <div className="px-5">
            <div className="row mb-6">
                <label className="form-label fs-6">
                    {t("FORMS.ONESHEET.COLOR-PRIMARY")}
                </label>
                <ColorPicker
                    idShow="fontColor"
                    color={data.fontColor}
                    handleShowColorPicker={() =>
                        handleShowColorPicker("fontColor", true)
                    }
                    displayColorSelection={displayColorSelection}
                    handleChange={(e) =>
                        setData(tabId, "fontColor", e.target.value)
                    }
                    idHide="hide-fontColor"
                    handleHideColorPicker={() =>
                        handleShowColorPicker("fontColor", false)
                    }
                    fieldErrors={fieldErrors}
                    error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                    helpText={t("FORMS.ONESHEET.COLOR-PRIMARY-HELP")}
                />
            </div>
            <div className="row mb-6">
                <label className="form-label fs-6">
                    {t("FORMS.ONESHEET.COLOR-SECONDARY")}
                </label>
                <ColorPicker
                    idShow="secondary_color"
                    color={data.secondary_color}
                    handleShowColorPicker={() =>
                        handleShowColorPicker("secondary_color", true)
                    }
                    displayColorSelection={displayColorSelection}
                    handleChange={(e) =>
                        setData(tabId, "secondary_color", e.target.value)
                    }
                    idHide="hide-secondary_color"
                    handleHideColorPicker={() =>
                        handleShowColorPicker("secondary_color", false)
                    }
                    fieldErrors={fieldErrors}
                    error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                    helpText={t("FORMS.ONESHEET.COLOR-SECONDARY-HELP")}
                />
            </div>
            <div className="row mb-6">
                <label className="form-label fs-6">
                    {t("FORMS.ONESHEET.COLOR-BACKGROUND")}
                </label>
                <ColorPicker
                    idShow="background"
                    color={data.background}
                    handleShowColorPicker={() =>
                        handleShowColorPicker("background", true)
                    }
                    displayColorSelection={displayColorSelection}
                    handleChange={(e) =>
                        setData(tabId, "background", e.target.value)
                    }
                    idHide="hide-background"
                    handleHideColorPicker={() =>
                        handleShowColorPicker("background", false)
                    }
                    fieldErrors={fieldErrors}
                    error={t("FORMS.GENERAL.MANDATORY-FIELD")}
                    helpText={t("FORMS.ONESHEET.COLOR-BACKGROUND-HELP")}
                />
            </div>
        </div>
    );
};

export { OnesheetMetadataSection };
