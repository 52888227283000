import { useTranslate } from "../../../helpers/i18n/useTranslate";
import React, { useEffect, useState } from "react";
import { InvInputDefault } from "../../../components/Form/InvInputDefault";
import { isNotEmpty } from "../../../helpers/usefulFunctions";
import { CustomFileInput } from "../../../components/Form/CustomFileInput";
import { useParams } from "react-router-dom";
import { useOnesheetFileUploader } from "../../../helpers/Api/UploadOnesheetFileToBucket";
import { InputDefault } from "../../../components/Form/InputDefault";

/**
 * Formulário de Links (comumente usado pra notícias) para onesheet
 * @param {String} tabId id unique da secao
 * @param {object} data valores do elemento
 * @param {function} setData setter do dado (handleChange)
 * @param {array} fieldErrors erros de validacao
 *
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetLinksSection = ({ tabId, data, setData, fieldErrors }) => {
    const { t } = useTranslate();
    const { onesheetId } = useParams();
    const { uploadOnesheetFileToBucket } = useOnesheetFileUploader();
    const [image1, setImage1] = useState([]);
    const [image2, setImage2] = useState([]);
    const [image3, setImage3] = useState([]);

    const imageState = [
        { index: 0, file: image1 },
        { index: 1, file: image2 },
        { index: 2, file: image3 },
    ];

    /**
     * Faz a lógica necessária pra alterar o array de objetos que contém os links.
     * Lógica de funcionamento:
     * 1. Temos um tamanho T necessário pro array pra lógica de atualização do campo funcionar sem quebrar.
     * 2. Se o array não tiver o objeto de informações do link naquela posição ainda (ex, só tem um link e eu to edi-
     *      tando o link número #3), vamos pushar objetos vazios nas posições em que for necessário até conseguir editar
     *      o link no index desejado. Usamos uma subtração de T por data.value.length (necessaryLength - newValue.length)
     *      para pushar apenas a quantidade necessária de objetos vazios. Por exemplo: se eu estiver alterando o link 2
     *      mas só tiver um link, eu preciso pushar apenas um objeto vazio. Se eu estiver alterando o 3 mas não tiver
     *      nenhum link, eu preciso pushar 2 objetos vazios.
     * @param {number} index índice do link
     * @param {String} field nome do campo
     * @param {*} value novo valor
     */
    const updateLinkAtIndex = (index, field, value) => {
        const necessaryLength = index + 1; // Tamanho T explicado acima
        let newValue = [...data.value]; // Coloca o value numa nova posição de memória

        // Garantindo que o array de objetos tem o objeto no índice necessário
        if (newValue.length < necessaryLength)
            // Pushando apenas a quantidade necessária de objetos vazios
            for (let i = 0; i <= necessaryLength - newValue.length; i++)
                newValue.push({
                    title_article: "",
                    image_article: "",
                    link_article: "",
                });

        // Atualiza o objeto no campo correto
        let articleData = newValue[index];
        articleData[field] = value;
        setData(tabId, "value", newValue);
    };

    /**
     * Handler de alteracao nos campos de imagens dos links. Do jeito que tá implementado, toda imagem nova que chega
     * altera o state de todas as outras, mesmo quando elas estão iguais.
     */
    useEffect(() => {
        imageState.forEach((image) => {
            if (image.file.length > 0) {
                uploadOnesheetFileToBucket(
                    image[0].file,
                    image[0].file.name,
                    `${process.env.REACT_APP_ENVIRONMENT}/onesheet/${onesheetId}/${tabId}/extraContent/`
                ).then((filename) => {
                    updateLinkAtIndex(image.index, "image_article", filename);
                });
            }
        });
    }, [image1, image2, image3]);

    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data.show}
                            onChange={(e) =>
                                setData(tabId, "show", e.target.checked)
                            }
                        />
                    </label>

                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>

            <hr className="mt-10 mb-5" />

            <div className="mb-6">
                <div className="d-flex justify-content-center mb-5">
                    <span className="fs-3 fw-semibold">
                        {t("FORMS.ONESHEET.ARTICLE")} #1
                    </span>
                </div>
                <div className="row mb-6">
                    <InputDefault
                        label={`${t("FORMS.ONESHEET.ARTICLE-TITLE")} #1`}
                        id="at1"
                        type="text"
                        handleChange={(e) => {
                            updateLinkAtIndex(
                                0,
                                "title_article",
                                e.target.value
                            );
                        }}
                        value={data?.value[0]?.title_article}
                    />
                </div>
                <div className="row mb-6">
                    <InputDefault
                        label={`${t("FORMS.ONESHEET.ARTICLE-LINK")} #1`}
                        id="al1"
                        type="url"
                        handleChange={(e) => {
                            updateLinkAtIndex(
                                0,
                                "link_article",
                                e.target.value
                            );
                        }}
                        value={data?.value[0]?.link_article}
                    />
                </div>
                <div className="mb-6">
                    <CustomFileInput
                        fieldName={`links_img_0`}
                        fieldLabel={`${t("FORMS.ONESHEET.ARTICLE-IMAGE")} #1`}
                        inputClassName={"w-100"}
                        uploadedFileSrc={data.value[0]?.image_article}
                        files={image1}
                        setFiles={setImage1}
                    />
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.NEWS.IMAGE.HELP")}
                    </div>
                </div>
            </div>

            <hr className="mt-10 mb-5" />

            <div className="mb-6">
                <div className="d-flex justify-content-center mb-5">
                    <span className="fs-3 fw-semibold">
                        {t("FORMS.ONESHEET.ARTICLE")} #2
                    </span>
                </div>
                <div className="row mb-6">
                    <InputDefault
                        label={`${t("FORMS.ONESHEET.ARTICLE-TITLE")} #2`}
                        id="at2"
                        type="text"
                        handleChange={(e) => {
                            updateLinkAtIndex(
                                1,
                                "title_article",
                                e.target.value
                            );
                        }}
                        value={data?.value[1]?.title_article}
                    />
                </div>
                <div className="row mb-6">
                    <InputDefault
                        label={`${t("FORMS.ONESHEET.ARTICLE-LINK")} #2`}
                        id="al2"
                        type="url"
                        handleChange={(e) => {
                            updateLinkAtIndex(
                                1,
                                "link_article",
                                e.target.value
                            );
                        }}
                        value={data?.value[1]?.link_article}
                    />
                </div>

                <div className="mb-6">
                    <CustomFileInput
                        fieldName={`links_img_2`}
                        // removable={() => removeImage(index)}
                        fieldLabel={`${t("FORMS.ONESHEET.ARTICLE-IMAGE")} #2`}
                        inputClassName={"w-100"}
                        uploadedFileSrc={data.value[1]?.image_article}
                        files={image2}
                        setFiles={setImage2}
                    />
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.NEWS.IMAGE.HELP")}
                    </div>
                </div>
            </div>

            <hr className="mt-10 mb-5" />

            <div className="mb-6">
                <div className="d-flex justify-content-center mb-5">
                    <span className="fs-3 fw-semibold">
                        {t("FORMS.ONESHEET.ARTICLE")} #3
                    </span>
                </div>
                <div className="row mb-6">
                    <InputDefault
                        label={`${t("FORMS.ONESHEET.ARTICLE-TITLE")} #3`}
                        id="at3"
                        type="text"
                        handleChange={(e) => {
                            updateLinkAtIndex(
                                2,
                                "title_article",
                                e.target.value
                            );
                        }}
                        value={data?.value[2]?.title_article}
                    />
                </div>
                <div className="row mb-6">
                    <InputDefault
                        label={`${t("FORMS.ONESHEET.ARTICLE-LINK")} #3`}
                        id="al3"
                        type="url"
                        handleChange={(e) => {
                            updateLinkAtIndex(
                                2,
                                "link_article",
                                e.target.value
                            );
                        }}
                        value={data?.value[2]?.link_article}
                    />
                </div>
                <div className="mb-6">
                    <CustomFileInput
                        fieldName={`links_img_3`}
                        // removable={() => removeImage(index)}
                        fieldLabel={`${t("FORMS.ONESHEET.ARTICLE-IMAGE")} #3`}
                        inputClassName={"w-100"}
                        uploadedFileSrc={data.value[2]?.image_article}
                        files={image3}
                        setFiles={setImage3}
                    />
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.NEWS.IMAGE.HELP")}
                    </div>
                </div>
            </div>
        </>
    );
};

export { OnesheetLinksSection };
