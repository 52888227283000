import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-light-svg-icons";
import { Spinner } from "next2";

const LoadingSpinner = ({hide=false, size = "1x"}) => {
    return (
        <div className={hide ? ("hidden"):("d-flex justify-content-center align-items-center h-100")}>
            {/* <FontAwesomeIcon icon={faSpinner} spin size={size}/> */}
            <Spinner />
        </div>
    )
};

export {LoadingSpinner};
