import { PageTitle } from "../../../layout/core";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faExclamationTriangle,
    faEye,
    faFilters,
} from "@fortawesome/pro-light-svg-icons";
import { useTranslate } from "../../../helpers/i18n/useTranslate";

import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { PagedListComponent } from "../../../components/PagedListComponent";
import { CustomSelect } from "../../../components/Form/CustomSelect";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import Popover from "react-bootstrap/Popover";
import DatePicker from "react-datepicker";
import { ptBR } from "date-fns/locale";
import clsx from "clsx";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { format } from "date-fns";
import { useAuth } from "../../auth";
import { useCreateButton } from "../../../helpers/subHeaderButtons/createButtonProvider";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { Intro } from "../../../components/Intro";
import { toast } from "react-toastify";
import { Card, CardHeader } from "next2";
import { Filter } from "../../../components/Filter";

const defaultInvite = {
    id: "",
    producer_name: "",
    email: "",
    phone: "",
    event_type: "",
    get_event_type_display: "",
    event_name: "",
    event_public_type: "",
    get_event_public_type_display: "",
    event_timestamp: "",
    notes: "",
    event_address: "",
    artist: [],
    artist_name: "",
    timestamp_for_humans: "",
    invitation_page_slug: "",
    status: "NEW",
    get_status_display: "Novo",
    is_cancelled: false,
};

const initialArtist = {
    name: "",
    avatar: "",
};

const InvitesList = observer(() => {
    const { GET } = useApi();
    const { t } = useTranslate();
    const { currentArtist } = useAuth();
    const [artists, setArtists] = useState([initialArtist]);
    const [invites, setInvites] = useState([defaultInvite]);
    const [filters, setFilters] = useState({});
    const [preFilters, setPreFilters] = useState({});
    const [filteredInvites, setFilteredInvites] = useState([defaultInvite]);
    const [showPopover, setShowPopover] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedEventType, setSelectedEventType] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedArtists, setSelectedArtists] = useState(null);
    const [selectedIsCancelled, setSelectedIsCancelled] = useState(null);
    const [periodFilterApplied, setPeriodFilterApplied] = useState(false);
    const [fieldErrors, setFieldErrors] = useState("");
    const [loading, setLoading] = useState(true);
    const { setHref } = useCreateButton();
    setHref("");

    const steps = [
        {
            element: ".invitepage",
            title: t("APPS.INVITES.INVITES"),
            intro: t("INTRO.INVITE-PAGE"),
            position: "bottom",
            tooltipClass: "bg-body",
        },
    ];

    /**
     * Carrega os artistas disponíveis para o filtro por artista
     * @returns {Promise<void>}
     */
    const getArtists = async () => {
        try {
            const artistsResponse = await GET("/core/artists");
            let tempArtists = artistsResponse.data.map((artist) => ({
                label: artist.name,
                value: artist.id,
            }));
            setArtists(tempArtists);
            setLoading(false);
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.CONNECTION-ERROR"));
        }
    };

    /**
     * Atualiza o tipo de evento selecionado e os filtros prévios.
     *
     * @param {Object} selected - O objeto selecionado que representa o tipo de evento.
     */
    const handleEventTypeChange = (selected) => {
        setSelectedEventType(selected);
        setPreFilters({
            ...preFilters,
            event_type: selected?.value,
        });
    };

    /**
     * Atualiza o estado do status selecionado e os pré-filtros com o novo status selecionado.
     *
     * @param {Object} selected - O objeto contendo o status selecionado.
     */
    const handleStatusChange = (selected) => {
        setSelectedStatus(selected);
        setPreFilters({
            ...preFilters,
            status: selected?.value,
        });
    };

    /**
     * Atualiza o estado dos artistas selecionados e os filtros prévios com o artista selecionado.
     *
     * @param {Object} selected - O objeto contendo as informações do artista selecionado.
     */
    const handleArtistChange = (selected) => {
        setSelectedArtists(selected);
        setPreFilters({
            ...preFilters,
            artist: selected?.value,
        });
    };

    /**
     * Atualiza o estado de "is_cancelled" e os pré-filtros com o valor selecionado.
     *
     * @param {Object} selected - O valor selecionado.
     */
    const handleIsCancelledChange = (selected) => {
        setSelectedIsCancelled(selected);
        setPreFilters({
            ...preFilters,
            is_cancelled: selected?.value,
        });
    };

    /**
     * Carrega dados necessários assim que a tela carrega
     */
    useEffect(() => {
        getArtists();
    }, []);

    /**
     * Mantém o estado de invites inalterado, usa filteredInvites pra renderizar os resultados filtrados
     */
    useEffect(() => {
        setFilteredInvites(invites);
    }, [invites]);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">
                {t("FORMS.GENERAL.OPTION-FILTER")}
            </Popover.Header>
            <Popover.Body>
                <div className="">
                    {!currentArtist && (
                        <>
                            <label className="form-label pb-1 mt-1">
                                {t("APPONI.LABELFORM.artists")}
                            </label>
                            <CustomSelect
                                placeholder={`${t("FILTER.FILTER-BY")} ${t(
                                    "FORMS.CORE.PROJECT.ARTIST"
                                )}`}
                                defaultValue={selectedArtists}
                                onChange={handleArtistChange}
                                options={artists}
                                isClearable={true}
                                isSearchable={true}
                            />
                        </>
                    )}
                    <label className="form-label">
                        {t("FORMS.INVITES.P.TYPE")}
                    </label>
                    <CustomSelect
                        placeholder={t("FORMS.INVITES.P.TYPE")}
                        options={[
                            {
                                value: "TH",
                                label: t("FORMS.INVITES.P.TYPE-TH"),
                            },
                            {
                                value: "CH",
                                label: t("FORMS.INVITES.P.TYPE-CH"),
                            },
                            {
                                value: "PV",
                                label: t("FORMS.INVITES.P.TYPE-PV"),
                            },
                        ]}
                        defaultValue={selectedEventType}
                        onChange={handleEventTypeChange}
                        isClearable={true}
                    />
                    <label className="form-label mt-1">
                        {t("APPS.INVITES.STATUS")}
                    </label>
                    <CustomSelect
                        placeholder={t("APPS.INVITES.STATUS")}
                        options={["NEW", "TDO", "PEN", "DON"].map((st) => ({
                            value: st,
                            label: t(`APPS.INVITES.STATUS.${st}`),
                        }))}
                        defaultValue={selectedStatus}
                        onChange={handleStatusChange}
                        isClearable={true}
                    />
                    <label className="form-label mt-1">
                        {t("APPS.INVITES.CANCELLED-LABEL")}
                    </label>
                    <CustomSelect
                        placeholder={`${t("APPS.INVITES.CANCELLED-FILTER")}`}
                        options={[
                            {
                                value: true,
                                label: t("GENERAL.YES"),
                            },
                            {
                                value: false,
                                label: t("GENERAL.NO"),
                            },
                        ]}
                        defaultValue={selectedIsCancelled}
                        onChange={handleIsCancelledChange}
                        isClearable={true}
                    />
                    <div className="d-flex gap-3 p-1 mt-1">
                        <label>
                            <span className="form-label">
                                {t("FORMS.GENERAL.START-DATE")}
                            </span>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => {
                                    setFieldErrors(""); // remove startDate de fieldErrors
                                    setStartDate(date);
                                    if (date && !endDate) {
                                        setFieldErrors(["endDate"]); // Adiciona 'endDate' aos erros se startDate for selecionado e endDate for nulo
                                    }
                                }}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText={t("FORMS.GENERAL.START-DATE")}
                                dateFormat="dd/MM/yyyy"
                                locale={ptBR}
                                className={clsx(
                                    "form-control form-control-sm form-control-white mt-2",
                                    {
                                        "is-invalid":
                                            fieldErrors.includes("startDate"),
                                    }
                                )}
                            />
                        </label>
                        <label>
                            <span className="form-label">
                                {t("FORMS.GENERAL.FINAL-DATE")}
                            </span>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => {
                                    setFieldErrors(""); // remove endDate do fieldErrors
                                    setEndDate(date);
                                }}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText={t("FORMS.GENERAL.FINAL-DATE")}
                                dateFormat="dd/MM/yyyy"
                                locale={ptBR}
                                disabled={startDate === null}
                                className={clsx(
                                    "form-control form-control-sm form-control-white mt-2",
                                    {
                                        "is-invalid":
                                            fieldErrors.includes("endDate"),
                                    }
                                )}
                            />
                        </label>
                    </div>
                </div>
                <div>
                    <div className="d-flex justify-content-between my-3 p-1 mb-2">
                        <button
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                                setFilters({
                                    ...filters,
                                    release_date__range: null,
                                    artist: null,
                                    event_type: null,
                                    status: null,
                                    is_cancelled: null,
                                    event_timestamp__range: null,
                                });
                                setStartDate(null);
                                setEndDate(null);
                                setSelectedEventType(null);
                                setSelectedStatus(null);
                                setShowPopover(false);
                                setSelectedIsCancelled(null);
                                setPeriodFilterApplied(false);
                            }}
                        >
                            {t("AUTH.GENERAL.FILTER-RESET")}
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                                // Verifica se startDate está definida e se endDate não está definida
                                if (startDate !== null && endDate === null) {
                                    // Exibe uma mensagem de erro informando que endDate é obrigatório
                                    toast.error(
                                        "Você deve selecionar uma data de término ao escolher uma data de início. "
                                    );
                                } else {
                                    // Lógica para aplicar os filtros, incluindo data se disponível
                                    const filters = { ...preFilters };

                                    if (
                                        startDate !== null &&
                                        endDate !== null
                                    ) {
                                        // Ambas as datas foram selecionadas
                                        const formatDate = (
                                            date,
                                            hours,
                                            minutes,
                                            seconds
                                        ) => {
                                            // Logica de datas
                                            let year = date.getFullYear();
                                            let month = String(
                                                date.getMonth() + 1
                                            ).padStart(2, "0");
                                            let day = String(
                                                date.getDate()
                                            ).padStart(2, "0");
                                            let formattedHours = String(
                                                hours
                                            ).padStart(2, "0");
                                            let formattedMinutes = String(
                                                minutes
                                            ).padStart(2, "0");
                                            let formattedSeconds = String(
                                                seconds
                                            ).padStart(2, "0");

                                            return `${year}-${month}-${day}T${formattedHours}:${formattedMinutes}:${formattedSeconds}-03:00`;
                                        };

                                        let stringedStartDate = formatDate(
                                            startDate,
                                            0,
                                            0,
                                            0
                                        );
                                        let stringedEndDate = formatDate(
                                            endDate,
                                            23,
                                            59,
                                            0
                                        );
                                        filters.event_timestamp__range = `${stringedStartDate}, ${stringedEndDate}`;
                                    }
                                    setFilters(filters);
                                    setPeriodFilterApplied(true);
                                    setShowPopover(false);
                                }
                            }}
                        >
                            {t("AUTH.GENERAL.FILTER-APPLY")}
                        </button>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            {/* Enquanto os dados não carregam, aparece esse loading spinner no meio da tela */}
            {loading ? (
                <LoadingSpinner size={"2xl"} />
            ) : (
                <>
                    <Intro steps={steps} />
                    <div className="row invitepage">
                        <Card className="p-8 h-full bg-body">
                            {/* begin::Header */}
                            <CardHeader className="flex justify-end">
                                <h3 className="card-title align-items-start flex-column">
                                    {/*  MANTER ESSE H3 VAZIO PORQUE CARD-HEADER TEM JUSTIFY-BETWEEN */}
                                </h3>
                                <Filter
                                    showPopover={showPopover}
                                    setShowPopover={setShowPopover}
                                    popover={popover}
                                />
                            </CardHeader>
                            {/* end::Header */}
                            {/* begin::Body */}
                            <div className="card-body py-3">
                                <PagedListComponent
                                    data={filteredInvites}
                                    setData={setInvites}
                                    filters={filters}
                                    baseUrl={"/invitations/invites"}
                                >
                                    <div className="table-responsive">
                                        <div className="d-flex justify-content-start">
                                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                                <thead>
                                                    <tr className="fw-bold text-muted">
                                                        <th className="min-w-150px text-center">
                                                            {t(
                                                                "FORMS.INVITES.P.EVT-NAME"
                                                            )}
                                                        </th>
                                                        <th className="min-w-150px text-center">
                                                            {t(
                                                                "APPS.INVITES.STATUS"
                                                            )}
                                                        </th>
                                                        {!currentArtist && (
                                                            <th className="min-w-140px text-center">
                                                                {t(
                                                                    "APPS.CORE.ARTIST"
                                                                )}
                                                            </th>
                                                        )}
                                                        <th className="min-w-140px text-center">
                                                            {t(
                                                                "FORMS.INVITES.P.PROD-NAME"
                                                            )}
                                                        </th>
                                                        <th className="min-w-120px text-center">
                                                            {t(
                                                                "FORMS.INVITES.P.TIMESTAMP"
                                                            )}
                                                        </th>
                                                        <th className="min-w-100px text-center">
                                                            {t(
                                                                "FORMS.INVITES.P.TYPE"
                                                            )}
                                                        </th>
                                                        <th className="min-w-100px text-center">
                                                            {t(
                                                                "FORMS.INVITES.PLACE"
                                                            )}
                                                        </th>
                                                        <th className="min-w-100px text-center">
                                                            {t(
                                                                "GENERAL.SEE-MORE"
                                                            )}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredInvites.map(
                                                        (invite, index) => (
                                                            <tr
                                                                key={`inv-tr-${index}`}
                                                                className={
                                                                    invite.status ===
                                                                    "NEW"
                                                                        ? "text-dark fw-bold"
                                                                        : `text-gray-${
                                                                              invite.is_cancelled
                                                                                  ? "400"
                                                                                  : "800"
                                                                          }`
                                                                }
                                                                style={
                                                                    invite.status ===
                                                                    "NEW"
                                                                        ? {
                                                                              backgroundColor:
                                                                                  "#1B1B29",
                                                                          }
                                                                        : {}
                                                                }
                                                            >
                                                                <td className="text-center">
                                                                    <span className="d-block fs-6">
                                                                        {invite.is_cancelled && (
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faExclamationTriangle
                                                                                }
                                                                                color="orange"
                                                                                size={
                                                                                    "sm"
                                                                                }
                                                                                className="me-2"
                                                                            />
                                                                        )}
                                                                        {
                                                                            invite.event_name
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="d-block fs-6">
                                                                        {invite.is_cancelled
                                                                            ? t(
                                                                                  "APPS.INVITES.IS_CANCELLED"
                                                                              )
                                                                            : invite.get_status_display}
                                                                    </span>
                                                                </td>
                                                                {!currentArtist && (
                                                                    <td className="text-center">
                                                                        <span className="d-block fs-6">
                                                                            {
                                                                                invite.artist_name
                                                                            }
                                                                        </span>
                                                                    </td>
                                                                )}
                                                                <td className="text-center">
                                                                    <span className="d-block fs-6">
                                                                        {
                                                                            invite.producer_name
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="d-block fs-6">
                                                                        {
                                                                            invite.timestamp_for_humans
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="d-block fs-6">
                                                                        {
                                                                            invite.get_event_type_display
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="d-block fs-6">
                                                                        {
                                                                            invite.event_address
                                                                        }
                                                                    </span>
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="d-flex justify-content-center flex-shrink-0">
                                                                        <Link
                                                                            to={`${invite.id}`}
                                                                            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faEye
                                                                                }
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </PagedListComponent>
                            </div>
                        </Card>
                    </div>
                </>
            )}
        </>
    );
});

const InvitesListWrapper = observer(() => {
    const { t } = useTranslate();
    return (
        <>
            <PageTitle description={t("INTRO.INVITE")}>
                {"APPS.INVITES.MY-INVITES"}
            </PageTitle>
            <InvitesList />
        </>
    );
});

export { InvitesListWrapper };
