/**
 * Dado um array, verifica se um elemento já está contido. Se estiver, remove este elemento. Se não estiver, adiciona
 * @param arr array desejado
 * @param elem elemento desejado
 * @returns {*}
 */
function insertOrRemoveFromArray(arr, elem) {
    // Verifica se o elem está no array
    const index = arr.indexOf(elem);

    // Se o elem estiver no array, remove-o
    if (index !== -1) {
        arr.splice(index, 1);
    } else {
        // Se o elem não estiver no array, adiciona-o
        arr.push(elem);
    }

    return arr;
}

/**
 * Indica se a data informada é futura
 * @param {String} dateString data no formato dd/mm/aaaa
 * @returns {boolean} true, se a data for futura. false caso contrário
 */
function isFutureDate(dateString) {
    // Split the date string into day, month, and year
    const [day, month, year] = dateString.split("/");

    // Create a date object using the appropriate format (month is zero-indexed)
    const date = new Date(year, month - 1, day);

    // Get the current date
    const today = new Date();

    // Compare the dates
    return date > today;
}

/**
 * Retorna a quantidade de bytes, convertida para megabytes
 * @param {number} bytes
 * @returns {string} quantidade convertida
 */
function bytesToMB(bytes) {
    if (isNotEmpty(bytes)) return (bytes / (1024 * 1024)).toFixed(2);
    else return bytes;
}

/**
 * Retorna a quantidade de megabytes, convertida para bytes
 * @param {number} megabytes
 * @returns {number} quantidade convertida
 */
function mBToBytes(megabytes) {
    if (isNotEmpty(megabytes)) return megabytes * 1024 * 1024;
    else return megabytes;
}

/**
 * Atualiza um array com base em um índice e, se não houver conteúdo nesse índice
 * (array[index] é indefinido), adiciona o conteúdo desejado a esse índice.
 *
 * @param {Array} array - O array a ser atualizado.
 * @param {number} index - O índice no qual o conteúdo deve ser atualizado ou adicionado.
 * @param {*} content - O conteúdo a ser atribuído ao índice especificado.
 */
function updateArrayAtIndex(array, index, content) {
    if (index < 0) {
        console.error("Index must be a non-negative number.");
        return;
    }

    if (index < array.length) {
        // If the index exists, update its content
        array[index] = content;
    } else {
        // If the index doesn't exist, push the content to the array
        array.push(content);
    }
}

/**
 * Pega um arquivo a partir de uma url e transforma ele em um objeto File
 * @param {String} url link para o arquivo
 * @param {String} filename nome do arquivo
 * @param {String} extension extensão do arquivo (.jpg, .png, .pdf...)
 * @returns {Promise<File>}
 */
async function urlToFile(url, filename, extension) {
    try {
        const response = await fetch(url, { mode: "no-cors" });
        const blob = await response.blob();

        return new File([blob], filename, { type: extension });
    } catch (e) {
        console.error(e);
        return url;
    }
}

/**
 * Calcula quanto tempo falta até uma data e hora determinados
 * @param {String} targetDate dd/mm/aaaa
 * @param {String} targetTime horário desejado, no formato HH:mm:ss
 * @returns {{hours: number, seconds: number, minutes: number, days: number}} objeto contendo quanto tempo falta pra chegar
 */
function calculateTimeUntil(targetDate, targetTime = "00:00:00") {
    // Parse a data e horário
    const closestFutureDate = new Date(
        `${targetDate.split("/").reverse().join("-")} ${targetTime}`
    );

    // Obtém a data e hora atual
    const currentDate = new Date();

    // Calcula a diferença em milissegundos
    const timeDifference = closestFutureDate - currentDate;

    // Calcula os dias, horas, minutos e segundos restantes
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    // Retorna um objeto com o tempo restante
    return {
        days,
        hours,
        minutes,
        seconds,
    };
}

/**
 * Converte uma data no formato dd/mm/yyyy para yyyy-mm-dd
 * @param {string} value
 * @returns {string}
 */
function dateStrToHtml(value) {
    let date = "";
    if (!value) return date;
    try {
        let dateParts = value.split("/");
        date = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    } catch (e) {
        console.error(e);
    }
    return date;
}

/**
 * Converte uma data no formato yyyy-mm-dd para dd/mm/yyyy
 * @param {string} value
 * @returns {string}
 */
function dateToBrazilianFormat(value) {
    let date = "";
    if (!value) return date;
    try {
        let dateParts = value.split("-");
        date = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
    } catch (e) {
        console.error(e);
    }
    return date;
}

/**
 * Indica se obj não é nulo
 * @param {any} obj
 * @returns {boolean} true se o objeto não for nulo. false se ele for nulo
 */
function isNotEmpty(obj) {
    return obj !== undefined && obj !== null && obj !== "";
}

function getFilenameFromStringPath(path) {
    let splittedPath = path.split("/");
    return splittedPath[splittedPath.length - 1].slice(12); // Os 12 primeiros caracteres são aleatórios, colocados pelo Django pra evitar conflito de nome de arquivo igual
}

function removeNullDataFromDict(dict) {
    Object.keys(dict).forEach((field) => {
        if (dict[field] === null || dict[field] === undefined) {
            dict[field] = "";
        }
    });
    return dict;
}

function dynamicSort(property) {
    /*
      Permite ordenar arrays de dicts por suas chaves dinamicamente.
       */
    let sortOrder = 1;
    // Se a string property comecar com "-", significa que ordenaremos em ordem decrescente
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        let result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
}

/**
 * Transforma uma string em um slug válido
 * @param {String} str string que vai ser transformada
 * @returns {string}
 */
function slugify(str) {
    return String(str)
        .normalize("NFKD") // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
        .replace(/\s+/g, "-") // replace spaces with hyphens
        .replace(/-+/g, "-"); // remove consecutive hyphens
}

/**
 * Retorna um cookie
 * Créditos: https://stackoverflow.com/questions/40893537/fetch-set-cookies-and-csrf
 * @param {String} name nome do cookie
 * @returns {string|null}
 */
function customGetCookie(name) {
    if (!document.cookie) {
        return null;
    }

    const xsrfCookies = document.cookie
        .split(";")
        .map((c) => c.trim())
        .filter((c) => c.startsWith(name + "="));

    if (xsrfCookies.length === 0) {
        return null;
    }
    return decodeURIComponent(xsrfCookies[0].split("=")[1]);
}

/**
 * Formata e reduz números grandes, colocando K, M e B no final, caso seja necessário
 * @param {number} number número para formatar
 * @returns {string} número formatado
 */
function formatNumberWithAbbreviation(number) {
    if (number >= 1000000) {
        return (number / 1000000).toFixed(1) + "M"; // Format as millions
    } else if (number >= 1000) {
        return (number / 1000).toFixed(1) + "K"; // Format as thousands
    } else {
        try {
            return number.toString(); // No abbreviation needed
        } catch {
            return undefined;
        }
    }
}

/**
 * Gerador de strings aleatório
 * @param {number} length tamanho da string
 * @returns {string}
 */
// function makeId(length = 7) {
//     var result = "";
//     var characters =
//         "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
//     var charactersLength = characters.length;
//     for (var i = 0; i < length; i++) {
//         result += characters.charAt(
//             Math.floor(Math.random() * charactersLength)
//         );
//     }
//     return result;
// }

export {
    dateStrToHtml,
    dateToBrazilianFormat,
    isNotEmpty,
    getFilenameFromStringPath,
    removeNullDataFromDict,
    dynamicSort,
    customGetCookie,
    formatNumberWithAbbreviation,
    // makeId,
    slugify,
    isFutureDate,
    calculateTimeUntil,
    insertOrRemoveFromArray,
    bytesToMB,
    mBToBytes,
    urlToFile,
    updateArrayAtIndex,
};
