import { useTranslate } from "../../../helpers/i18n/useTranslate";
import React from "react";

/**
 * Formulário de Lançamentos Recentes para Onesheet
 * @param {String} tabId id unique da secao
 * @param {object} data valores do elemento
 * @param {function} setData setter do dado (handleChange)
 * @param {array} fieldErrors erros de validacao
 *
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetLatestAlbumsSection = ({ tabId, data, setData, fieldErrors }) => {
    const { t } = useTranslate();
    return (
        <div className="row flex-column mb-6">
            <label className="col-lg-4 col-form-label fs-6">
                {t("FORMS.ONESHEET.SHOW-SECTION")}
            </label>
            <div className="col-12 fv-row">
                <label className="form-check form-check-custom form-check-solid form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="layout-builder[layout][header][fixed][desktop]"
                        checked={data?.show}
                        onChange={(evt) =>
                            setData(tabId, "show", evt.target.checked)
                        }
                    />
                </label>
                <div className="form-text text-muted">
                    {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                </div>
            </div>
        </div>
    );
};

export { OnesheetLatestAlbumsSection };
