import { useLayout } from "../../core";
import clsx from "clsx";
import { DefaultTitle } from "../header/page-title/DefaultTitle";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { useCreateButton } from "../../../helpers/subHeaderButtons/createButtonProvider";
import { useAuth } from "../../../modules/auth";

const Toolbar = () => {
    const { classes } = useLayout();
    const { t } = useTranslate();
    const { currentClient } = useAuth();
    const { href } = useCreateButton();

    return (
        <>
            <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
                {/* begin::Container */}
                <div
                    id="kt_toolbar_container"
                    className={clsx(
                        classes.toolbarContainer.join(" "),
                        "d-flex flex-stack"
                    )}
                >
                    <DefaultTitle />

                    {/* begin::Actions */}
                    <div className="d-flex align-items-center py-1">
                        {/* begin::Wrapper */}
                        {/*<div className="me-4">*/}
                        {/*  /!* begin::Menu *!/*/}
                        {/*  <a*/}
                        {/*    href="#"*/}
                        {/*    className="btn btn-custom btn-active-white btn-flex btn-color-white btn-active-color-primary fw-bold"*/}
                        {/*  >*/}
                        {/*    <FontAwesomeIcon icon={faFilter} className="me-2" />*/}
                        {/*    {String(t("FILTER.FILTER"))}*/}
                        {/*  </a>*/}
                        {/*  /!*todo filter usar esse exmplo aquio https://preview.keenthemes.com/metronic8/react/demo1/crafted/pages/profile/overview *!/*/}

                        {/*  /!* end::Menu *!/*/}
                        {/*</div>*/}
                        {/* end::Wrapper */}

                        {/* begin::Button */}
                        {currentClient?.canCreate && href ? (
                            <a
                                className="btn btn-bg-white btn-active-color-primary cursor-pointer"
                                style={{ color: "rgb(24, 28, 50)" }}
                                href={href}
                            >
                                {t("GENERAL.NEW")}
                            </a>
                        ) : null}
                        {/* end::Button */}
                    </div>
                    {/* end::Actions */}
                </div>
                {/* end::Container */}
            </div>
        </>
    );
};

export { Toolbar };
