import { Container, Grid } from "@nextui-org/react";
import React from "react";
import { Title } from "../Components/Title";
import ReactPlayer from "react-player";
import styles from "./OnesheetLastTourPreview.module.css";

/**
 * Componente de visualização para a seção "Última Tour" de uma página.
 *
 * @param {Object} data - Os dados da seção.
 * @returns {JSX.Element} Um elemento JSX que representa a visualização da seção "Última Tour".
 */

const OnesheetLastTourPreview = ({ data }) => {
    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container
                direction="column"
                justify="center"
                css={{ mw: "1300px", m: "0 auto" }}
            >
                <Grid css={{ mb: "1.5rem" }}>
                    <Title color={data?.fontColor} text="Última Tour" />
                </Grid>
                <div className={styles.wrapper}>
                    <div
                        className={styles.textTour}
                        dangerouslySetInnerHTML={{ __html: data?.value }}
                    />
                    {data?.extraContent.featuredVideo ? (
                        <div className={styles.videoTour}>
                            <ReactPlayer
                                width="100%"
                                url={String(data.extraContent.featuredVideo)}
                            />
                        </div>
                    ) : (
                        <>
                            {data?.featuredImage ? (
                                <img
                                    className={styles.imageTour}
                                    alt={data?.title_article}
                                    src={data?.featuredImage}
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </Grid.Container>
        </Container>
    );
};

export { OnesheetLastTourPreview };
