import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useTranslate} from "../../../helpers/i18n/useTranslate";
import {useCreateButton} from "../../../helpers/subHeaderButtons/createButtonProvider";
import {PagedListComponent} from "../../../components/PagedListComponent";
import {CardPostsBlog} from "../../../components/Cards/CardPostsBlog";
import {useApi} from "../../../helpers/Api/ApiWrapper";
import {toast} from "react-toastify";
import {slugify} from "../../../helpers/usefulFunctions";

/**
 * Lista de posts do blog
 * @type {React.FunctionComponent<object>}
 */
const PostsBlog = observer(() => {
    const {t} = useTranslate();
    const {GET} = useApi();
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updatedPosts, setUpdatedPosts] = useState([]);
    const {setHref} = useCreateButton();
    setHref("");

    /**
     * Pega todas as infos dos posts do blog
     * @returns {Promise<void>}
     */
    const getPostsBlog = async () => {
        setLoading(true);
        try {
            const blogResponse = await GET(`academy/posts`);
            setPosts(blogResponse.data);
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ERROR-PAGE.TITLE"));
        } finally {
            setLoading(false);
        }
    };

    /**
     * Chama a função que obtém dados do curso
     */
    useEffect(() => {
        getPostsBlog();
    }, []);

    /**
     * Armazena os posts na variável updatedPosts
     */
    useEffect(() => {
        setUpdatedPosts(posts)
    }, [posts]);


    return (
        <>
            <PagedListComponent
                data={updatedPosts}
                setData={setUpdatedPosts}
                filters={{}}
                baseUrl={"/academy"}
            >
                <div className="row flex-row g-6 g-xl-9">
                    {posts.map((post, index) => {
                        return (
                            <div
                                className="col-md-6 col-xl-4"
                                key={`pr-4${index}`}
                            >
                                <CardPostsBlog
                                    image_url={post.og_image_url}
                                    article_section={post.article_section}
                                    title={post.title}
                                    description={post.description}
                                    redirectTo={`/academy/posts/${slugify(post.title)}`}
                                />
                            </div>
                        );
                    })}
                </div>
            </PagedListComponent>
        </>
    );
});

export {PostsBlog};