import ReactQuill from "react-quill";
import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { CustomFileInput } from "../../../components/Form/CustomFileInput";
import { useParams } from "react-router-dom";
import { useOnesheetFileUploader } from "../../../helpers/Api/UploadOnesheetFileToBucket";

/**
 * Formulário de Bio para Onesheet
 * @param {String} tabId id unique da secao
 * @param {object} data valores do elemento
 * @param {function} setData setter do dado (handleChange)
 * @param {array} fieldErrors erros de validacao
 *
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetBioSection = ({ tabId, data, setData, fieldErrors }) => {
    const { onesheetId } = useParams();
    const { uploadOnesheetFileToBucket } = useOnesheetFileUploader();
    const { t } = useTranslate();
    const bioRef = useRef(null);
    const [featuredImage, setFeaturedImage] = useState([]);

    /**
     * Setter do extraContent no data quando a logoImage muda (ou seja, quando alguem sobe algum arquivo lá). Fazemos
     * isso pra mudar a preview na hora que a pessoa subir uma imagem nova, e aí com isso ainda tem o bônus de que
     * quando formos fazer o save, o background já salva a imagem no bucket e retorna o path pra ser salvo no backend
     */
    useEffect(() => {
        if (featuredImage.length > 0) {
            uploadOnesheetFileToBucket(
                featuredImage[0].file,
                featuredImage[0].file.name,
                `${process.env.REACT_APP_ENVIRONMENT}/onesheet/${onesheetId}/${tabId}/extraContent/`
            ).then((filename) => {
                setData(tabId, "extraContent", filename);
            });
        }
    }, [featuredImage]);

    /**
     * Opções da barra de ferramentas do ReactQuill.
     *
     * @description
     * Opções de formatação e funcionalidades que serão exibidas na barra de ferramentas
     * do editor de texto ReactQuill.
     *
     * @type {Array}
     */
    const toolbarOptions = [
        // Botões com estilos de negrito e itálico
        ["bold", "italic"],

        // Botões para definir os níveis de cabeçalho (h1, h2)
        [{ header: 1 }, { header: 2 }],

        // Botões para alinhar o texto (esquerda, centralizado, direita, justificado)
        [{ align: [] }],

        // Botões para adicionar links, imagens e vídeos
        ["link", "image", "video"],

        // Opções de cores e fundo do texto em um dropdown
        [{ color: [] }, { background: [] }],

        // Dropdown para escolher a fonte do texto
        [{ font: [] }],

        // Botão para remover a formatação do texto
        ["clean"],
    ];

    /**
     * Módulos do ReactQuill.
     *
     * @description
     * Configurações do módulo do editor de texto ReactQuill, incluindo as opções da barra
     * de ferramentas definidas anteriormente.
     *
     * @type {Object}
     */
    const modules = {
        toolbar: toolbarOptions,
    };

    return (
        <div className="row flex-column mb-6">
            <label className="col-lg-4 col-form-label fs-6">
                {t("FORMS.ONESHEET.SHOW-SECTION")}
            </label>
            <div className="col-12 fv-row">
                <label className="form-check form-check-custom form-check-solid form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="layout-builder[layout][header][fixed][desktop]"
                        checked={data.show}
                        onChange={(evt) =>
                            setData(tabId, "show", evt.target.checked)
                        }
                    />
                </label>
                <div className="form-text text-muted">
                    {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                </div>
            </div>

            <hr className="my-10" />

            <div className="mb-6">
                <label className="form-label fs-6">
                    {t("FORMS.ONESHEET.FEATURED-IMAGE")}
                </label>
                <CustomFileInput
                    fieldName={"extraContent"}
                    inputClassName={"w-50"}
                    uploadedFileSrc={data?.extraContent}
                    files={featuredImage}
                    setFiles={setFeaturedImage}
                />
                <div className="form-text text-muted">
                    {t("FORMS.ONESHEET.BIO.IMAGE.HELP")}
                </div>
            </div>

            <hr className="my-10" />

            <div className="row mb-6">
                <label htmlFor="biography" className="form-label fs-6">
                    {t("FORMS.ONESHEET.ARTIST-BIO")}
                </label>
                <div className="col-12">
                    <ReactQuill
                        ref={bioRef}
                        id="biography"
                        name="bio"
                        theme="snow"
                        value={data.value}
                        onChange={(val) => setData(tabId, "value", val)}
                        modules={modules}
                    />
                    {fieldErrors?.includes("bio") ? (
                        <div className="text-danger mt-2">
                            {t("FORMS.GENERAL.MANDATORY-FIELD")}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export { OnesheetBioSection };
