/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useLayout } from "../core";
import { useTranslate } from "../../helpers/i18n/useTranslate";

const Footer: FC = () => {
    const { classes } = useLayout();
    const { t } = useTranslate();
    return (
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            {/* begin::Container */}
            <div
                className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
            >
                {/* begin::Copyright */}
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-2">
                        {new Date().getFullYear()} &copy;
                    </span>
                    <a
                        target="_blank"
                        rel="noopener"
                        href="https://amplifyus.app/"
                        className="text-gray-800 text-hover-primary"
                    >
                        AmplifyUs
                    </a>
                </div>
                {/* end::Copyright */}

                {/* begin::Nav */}
                <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    {/*<li className="menu-item">*/}
                    {/*  <a href="auth#" className="text-muted text-hover-primary px-2">*/}
                    {/*    {String(t("GENERAL.LANDING-PAGE.CONTACT-US"))}*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                    <li className="menu-item">
                        <a
                            target="_blank"
                            rel="noopener"
                            href="https://amplifyus.app/politica-de-privacidade/"
                            className="text-muted text-hover-primary px-2"
                        >
                            {String(t("GENERAL.LANDING-PAGE.PRIVACY-POLICY"))}
                        </a>
                    </li>
                    {/*<li className="menu-item">*/}
                    {/*  <a href="auth#" className="text-muted text-hover-primary px-2">*/}
                    {/*    {String(t("GENERAL.LANDING-PAGE.TERMS-AND-CONDITIONS"))}*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                </ul>
                {/* end::Nav */}
            </div>
            {/* end::Container */}
        </div>
    );
};

export { Footer };
