import React from "react";
import { useNavigate } from "react-router-dom";

/**
 * @description
 * Componente de botão de voltar personalizado
 *
 * Em alguns casos será necessário utilizar uma rota específica, então foi adicionado o param de route
 *
 * @param {string} text - Texto do botão
 * @param {string} route - Rota personalizada para navegação
 * @param {function} customClick - Função personalizada caso não seja para voltar ou ir para alguma tela Ex.: Fechar modal
 * @returns
 */
export const CustomBackButton = ({ text, route, customClick }) => {
    const navigate = useNavigate();

    const goBack = (evt) => {
        // ao adicionar o preventDefault evita que formulários com campos obrigatórios acusem erro
        evt.preventDefault();
        if (customClick){
            customClick()
        } else {
            route ? navigate(route) : navigate(-1)
        }
    };

    return (
        <button
            onClick={(e) => goBack(e)}
            className="btn btn-danger"
        >
            {text}
        </button>
    );
};
