import { Route, Routes } from "react-router-dom";
import FeedPage from "../../modules/feedsocial/FeedPage";

const DatascienceRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<FeedPage />} />
            <Route path="feed" element={<FeedPage />} />
        </Routes>
    );
};

export { DatascienceRoutes };
