import { observer } from "mobx-react-lite";
import { useTranslate } from "../../../../../helpers/i18n/useTranslate";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import axios from "axios";
import qs from "qs";

/**
 * Renderiza a busca pelo nome do artista no spotify
 * @type {React.FunctionComponent<{readonly form?: *, readonly handleChange?: *}>}
 */
const ArtistSearchOnDsps = observer(({ form, handleChange }) => {
    const [accessToken, setAccessToken] = useState(null);
    const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
    const { t } = useTranslate();
    const [name, setName] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [hoverArtistId, setHoverArtistId] = useState(false);
    const [selectedArtist, setSelectedArtist] = useState("");

    /**
     * @description
     * Muda a cor do Background ao passar o mouse
     *
     * a cor do background vai ser de acordo com o tema (light/dark)
     */
    const style = {
        artistHover: {
            cursor: "pointer",
            backgroundColor: "#1B1B29",
            transition: ".3s",
        },
    };

    /**
     * Autentica no spotify quando a pagina carrega
     */
    useEffect(() => {
        handleAuthentication();
    }, []);

    /**
     * lógica para limpar o input e 'zerar' o artista selecionado
     */
    useEffect(() => {
        const inputElement = document.getElementById("searchArtist");

        const handleSearchClear = () => {
            setSearchResults([]);
            setSelectedArtist("");
        };

        inputElement.addEventListener("search", handleSearchClear);

        return () => {
            inputElement.removeEventListener("search", handleSearchClear);
        };
    }, []);

    /**
     * Realiza autenticação no Spotify pra conseguirmos buscar pelo artista
     * @returns {Promise<void>}
     */
    const handleAuthentication = async () => {
        try {
            let headers = {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/x-www-form-urlencoded",
                    // 'Authorization': `Basic ${btoa(`${clientId}:${clientSecret}`)}`
                },
                auth: {
                    username: clientId,
                    password: clientSecret,
                },
            };
            const data = {
                grant_type: "client_credentials",
            };
            const response = await axios.post(
                "https://accounts.spotify.com/api/token",
                qs.stringify(data),
                headers
            );
            setAccessToken(response.data.access_token);
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Faz a busca pelo nome do artista no spotify
     * @returns {Promise<void>}
     */
    const searchArtists = async () => {
        if (name.length >= 3) {
            try {
                const response = await fetch(
                    `https://api.spotify.com/v1/search?q=${name}&type=artist`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setSearchResults(data.artists.items);
                } else {
                    console.error("Failed to fetch artists:", response.status);
                }
            } catch (error) {
                console.error("Error searching artists:", error);
            }
        } else {
            setSearchResults([]);
        }
    };

    /**
     * Quando o nome muda (input do usuário), se tiver 3 ou mais caracteres, busca os artistas no spotify
     */
    useEffect(() => {
        if (name.length >= 3) {
            searchArtists();
        }
    }, [name]);

    /**
     * Handler de seleção do artista
     * @param artist
     */
    const selectArtist = (artist) => {
        setSelectedArtist(artist);

        handleChange({ name: "spotifyId", value: artist.id });
        handleChange({ name: "artistName", value: artist.name });
        if (!form.clientName)
            handleChange({ name: "clientName", value: artist.name || name });
        handleChange({ name: "artistPicture", value: artist.images[0].url });
    };

    return (
        <>
            <div className="d-flex justify-content-center">
                <div className="py-0 w-100">
                    <input
                        id="searchArtist"
                        type="search"
                        name="artistName"
                        className="form-control form-control-lg form-control-solid"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t("FORMS.CORE.ARTIST.NAME")}
                    />
                </div>
            </div>
            {searchResults.length > 0 && (
                <div className="py-0 d-flex flex-column gap-3 align-items-start mt-5 w-100">
                    <ul className="w-100" style={{ position: "relative" }}>
                        {selectedArtist ? (
                            <div
                                className="bg-body py-5 border-bottom mb-5"
                                style={{
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1000,
                                }}
                            >
                                <h3 className="mb-4 font-bold">
                                    {t("AUTH.REGISTER.ARTIST-SELECTED")}
                                </h3>

                                <li className="d-flex align-items-center mb-7">
                                    <div className="card card-bordered w-100">
                                        <div className="card-header ribbon">
                                            <div className="d-flex  w-100">
                                                <div className="symbol symbol-50px me-5 d-flex align-items-center ">
                                                    <img
                                                        src={
                                                            selectedArtist
                                                                .images[0].url
                                                        }
                                                        className=""
                                                        alt=""
                                                    />
                                                </div>

                                                <div className="d-flex justify-content-between align-items-center w-100 px-3 fw-bold">
                                                    {selectedArtist.name}
                                                    <FontAwesomeIcon
                                                        className="text-success"
                                                        icon={faCircleCheck}
                                                        size="lg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        ) : null}

                        <div>
                            <h3 className="mb-4 font-bold">
                                {t("AUTH.REGISTER.ARTIST-SELECT")}
                            </h3>
                        </div>
                        <div className="max-h-96 overflow-y-auto h-96">
                            {searchResults.map((artist) => {
                                return (
                                    <>
                                        {artist.images.length > 0 && (
                                            <>
                                                <li
                                                    className="d-flex align-items-center mb-7"
                                                    style={
                                                        hoverArtistId ===
                                                            artist.id ||
                                                        selectedArtist.id ===
                                                            artist.id
                                                            ? style.artistHover
                                                            : {}
                                                    }
                                                    onMouseOver={() =>
                                                        setHoverArtistId(
                                                            artist.id
                                                        )
                                                    }
                                                    key={artist.id}
                                                    onClick={() =>
                                                        selectArtist(artist)
                                                    }
                                                >
                                                    <div className="symbol symbol-50px me-5">
                                                        <img
                                                            src={
                                                                artist.images[0]
                                                                    .url
                                                            }
                                                            className=""
                                                            alt=""
                                                        />
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center w-100 px-3">
                                                        {artist.name}
                                                        {selectedArtist.id ===
                                                        artist.id ? (
                                                            <FontAwesomeIcon
                                                                className="text-success"
                                                                icon={
                                                                    faCircleCheck
                                                                }
                                                                size="lg"
                                                            />
                                                        ) : null}
                                                    </div>
                                                </li>
                                            </>
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </ul>
                </div>
            )}
        </>
    );
});

export default ArtistSearchOnDsps;
