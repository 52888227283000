/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useLayout } from "../../core";
import { Header } from "./Header";
import { DefaultTitle } from "./page-title/DefaultTitle";
import { Topbar } from "./Topbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { toAbsoluteUrl } from "../../../helpers/utils/toAbsoluteUrl";
import { useThemeMode } from "../../theme-mode/ThemeModeProvider";
import { useAuth } from "../../../modules/auth";

export function HeaderWrapper() {
    const { logout } = useAuth();
    const { mode } = useThemeMode();
    const { config, classes, attributes } = useLayout();
    const { header, aside } = config;

    return (
        <div
            id="kt_header"
            className={clsx(
                "header",
                classes.header.join(" "),
                "align-items-stretch"
            )}
            data-kt-sticky="true"
            data-kt-sticky-name="header"
            data-kt-sticky-offset="{default: '200px', lg: '300px'}"
            {...attributes.headerMenu}
        >
            <div
                className={clsx(
                    classes.headerContainer.join(" "),
                    "d-flex align-items-center"
                )}
            >
                {/* begin::Aside mobile toggle */}
                {aside.display && (
                    <div
                        className="d-flex align-items-center d-lg-none ms-n3 me-1"
                        title="Show aside menu"
                    >
                        <div
                            className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"
                            id="kt_aside_mobile_toggle"
                        >
                            <FontAwesomeIcon
                                className="svg-icon svg-icon-1"
                                icon={faBars}
                                size="lg"
                            />
                        </div>
                    </div>
                )}
                {/* end::Aside mobile toggle */}

                <div
                    className="d-flex topbar align-items-center d-lg-none ms-n2 me-3"
                    title="Show aside menu"
                >
                    {/*<div*/}
                    {/*    className="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px"*/}
                    {/*    id="kt_header_menu_mobile_toggle"*/}
                    {/*>*/}
                    {/*    <FontAwesomeIcon*/}
                    {/*        className="svg-icon svg-icon-1"*/}
                    {/*        icon={faBars}*/}
                    {/*        size="lg"*/}
                    {/*        color={"#fff"}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>

                <div className="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
                    <Link to="/">
                        <img
                            alt="Logo"
                            src={toAbsoluteUrl("/media/logos/default-dark.svg")}
                            className="logo-default h-25px"
                        />
                        <img
                            alt="Logo"
                            src={toAbsoluteUrl(
                                `${
                                    mode === "light"
                                        ? "/media/logos/default-light.svg"
                                        : "/media/logos/default-small-dark.svg"
                                }`
                            )}
                            className="logo-sticky h-25px"
                        />
                    </Link>
                </div>

                {/* begin::Wrapper */}
                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                    {/* begin::Navbar */}
                    <div
                        className="d-flex align-items-stretch"
                        id="kt_header_nav"
                    />
                    {/*{header.left === "menu" && (*/}
                    {/*    <div*/}
                    {/*        className="d-flex align-items-stretch"*/}
                    {/*        id="kt_header_nav"*/}
                    {/*    >*/}
                    {/*        <Header />*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    {/*{header.left === "page-title" && (*/}
                    {/*    <div*/}
                    {/*        className="d-flex align-items-center"*/}
                    {/*        id="kt_header_nav"*/}
                    {/*    >*/}
                    {/*        <DefaultTitle />*/}
                    {/*    </div>*/}
                    {/*)}*/}

                    <div className="d-flex align-items-stretch flex-shrink-0">
                        {/*<Topbar />*/}
                        <a onClick={logout}>Sair</a>
                    </div>
                </div>
                {/* end::Wrapper */}
            </div>
        </div>
    );
}
