import { createContext, useContext } from "react";
import axios from "axios";
import { useAlert } from "../utils/CustomAlert";
import { useAuth } from "../../modules/auth";
// let baseURL = "https://app.onimusic.com.br/catalog/";
let baseURL = process.env.REACT_APP_APPONI_API_URL;

const AppOniApiContext = createContext({
    apponi_get: (url) => {},
    apponi_post: (url, data) => {},
    apponi_delete: (url) => {},
    getHeaders: () => {},
});

const useAppOniApi = () => {
    // Definição do Hook
    return useContext(AppOniApiContext);
};

class NoCurrentArtistError extends Error {
    constructor(message) {
        super(message);
        this.name = "NoCurrentArtistError";
    }
}

const AppOniApiProvider = ({ children }) => {
    const { alert } = useAlert();
    const { currentArtist, currentClient } = useAuth();

    const getHeaders = () => {
        return {
            Apponitoken: process.env.REACT_APP_APPONI_API_TOKEN,
            staff: currentClient.is_global,
            Holderid: currentArtist?.appOniHolderId || "0",
        };
    };

    const apponi_get = async (url) => {
        let headers = getHeaders();
        try {
            return await axios.get(`${baseURL}${url}`, {
                headers: headers,
            });
        } catch (err) {
            console.error(err);
            alert.fire({ icon: "error", text: err.message });
        }
    };

    const apponi_post = async (url, data) => {
        let headers = getHeaders();
        try {
            return await axios.post(`${baseURL}${url}`, data, {
                headers: headers,
            });
        } catch (err) {
            console.error(err);
            alert.fire(err.message);
        }
    };

    const apponi_delete = async (url) => {
        let headers = getHeaders();
        try {
            return await axios.delete(`${baseURL}${url}`, {
                headers: headers,
            });
        } catch (err) {
            console.error(err);
            alert.fire(err.message);
        }
    };

    return (
        <AppOniApiContext.Provider
            value={{ apponi_get, apponi_post, apponi_delete, getHeaders }}
        >
            {children}
        </AppOniApiContext.Provider>
    );
};

export { AppOniApiProvider, useAppOniApi };
