import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { CustomFileInput } from "../../../components/Form/CustomFileInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { useOnesheetFileUploader } from "../../../helpers/Api/UploadOnesheetFileToBucket";
import { InputDefault } from "../../../components/Form/InputDefault";

/**
 * Componente de formulário para a seção "Última Tour".
 *
 * @param {string} tabId - id da tab.
 * @param {Object} data - Os dados da seção.
 * @param {function} setData - Função para atualizar os dados da seção.
 * @param {Array} fieldErrors - Um array de erros de campo.
 * @returns {JSX.Element} Um elemento JSX que representa a seção "Última Tour".
 */
const OnesheetLastTourSection = ({ tabId, data, setData, fieldErrors }) => {
    const { t } = useTranslate();
    const { onesheetId } = useParams();
    const { uploadOnesheetFileToBucket } = useOnesheetFileUploader();
    const lastTourRef = useRef(null);
    const [imageLastTour, setImageLastTour] = useState([]);

    /**
     * Setter do extraContent no data quando a imageLastTour muda (ou seja, quando alguem sobe algum arquivo lá). Fazemos
     * isso pra mudar a preview na hora que a pessoa subir uma imagem nova, e aí com isso ainda tem o bônus de que
     * quando formos fazer o save, o background já salva a imagem no bucket e retorna o path pra ser salvo no backend
     */
    useEffect(() => {
        if (imageLastTour.length > 0) {
            uploadOnesheetFileToBucket(
                imageLastTour[0].file,
                imageLastTour[0].file.name,
                `${process.env.REACT_APP_ENVIRONMENT}/onesheet/${onesheetId}/${tabId}/extraContent/`
            ).then((filename) => {
                setData(tabId, "featuredImage", filename);
            });
        }
    }, [imageLastTour]);

    /**
     * Opções da barra de ferramentas do ReactQuill.
     *
     * @description
     * Opções de formatação e funcionalidades que serão exibidas na barra de ferramentas
     * do editor de texto ReactQuill.
     *
     * @type {Array}
     */
    const toolbarOptions = [
        // Botões com estilos de negrito e itálico
        ["bold", "italic"],

        // Botões para definir os níveis de cabeçalho (h1, h2)
        [{ header: 1 }, { header: 2 }],

        // Botões para alinhar o texto (esquerda, centralizado, direita, justificado)
        [{ align: [] }],

        // Botões para adicionar links, imagens e vídeos
        ["link", "image", "video"],

        // Opções de cores e fundo do texto em um dropdown
        [{ color: [] }, { background: [] }],

        // Dropdown para escolher a fonte do texto
        [{ font: [] }],

        // Botão para remover a formatação do texto
        ["clean"],
    ];

    /**
     * Módulos do ReactQuill.
     *
     * @description
     * Configurações do módulo do editor de texto ReactQuill, incluindo as opções da barra
     * de ferramentas definidas anteriormente.
     *
     * @type {Object}
     */
    const modules = {
        toolbar: toolbarOptions,
    };

    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data.show}
                            onChange={(e) =>
                                setData(tabId, "show", e.target.checked)
                            }
                        />
                    </label>
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>

            <hr className="my-10" />

            <div className="row mb-6">
                <label htmlFor="bio" className="form-label">
                    {t("FORMS.ONESHEET.LAST-TOUR-TEXT")}
                </label>
                <div className="form-text text-muted">
                    {t("FORMS.ONESHEET.LAST-TOUR.BIO.HELP")}
                </div>
                <div className="">
                    <ReactQuill
                        ref={lastTourRef}
                        id="last-tour-text"
                        name="last-tour-text"
                        theme="snow"
                        value={data.value}
                        onChange={(e) => setData(tabId, "value", e)}
                        modules={modules}
                    />
                    {fieldErrors?.includes("text_tour") ? (
                        <div className="text-danger mt-2">
                            {t("FORMS.GENERAL.MANDATORY-FIELD")}
                        </div>
                    ) : null}
                </div>
            </div>
            {/* LINK DO VÍDEO DA TOUR */}
            <div className="row mb-6">
                <InputDefault 
                    label={t("FORMS.ONESHEET.LAST-TOUR-VIDEO")}
                    id="video_tour_link"
                    type="url"
                    handleChange={(e) =>
                        setData(tabId, "extraContent", {
                            featuredVideo: e.target.value,
                        })
                    }
                    maxLength={100}
                    value={data.extraContent?.featuredVideo}
                    helpText={t("FORMS.ONESHEET.LAST-TOUR-VIDEO.HELP")}
                />
                {/* <label htmlFor="video_tour_link" className="form-label">
                    {t("FORMS.ONESHEET.LAST-TOUR-VIDEO")}
                </label>
                <input
                    id="video_tour_link"
                    type="text"
                    name="video_tour_link"
                    className="form-control form-control-lg form-control-solid"
                    onChange={(e) =>
                        setData(tabId, "extraContent", {
                            featuredVideo: e.target.value,
                        })
                    }
                    maxLength={100}
                    value={data.extraContent?.featuredVideo}
                />
                <div className="form-text">
                    {t("FORMS.ONESHEET.LAST-TOUR-VIDEO.HELP")}
                </div> */}
            </div>
            {/* IMAGEM DA TOUR */}
            <div className="row mb-6">
                <CustomFileInput
                    fieldName="image_tour"
                    fieldLabel={t("FORMS.ONESHEET.LAST-TOUR-IMAGE")}
                    uploadedFileSrc={data.featuredImage}
                    files={imageLastTour}
                    setFiles={setImageLastTour}
                />
                <div className="form-text text-muted">
                    {t("FORMS.ONESHEET.LAST-TOUR.IMAGE.HELP")}
                </div>
            </div>
        </>
    );
};

export { OnesheetLastTourSection };
