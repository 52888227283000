/**
 * Pega o parâmetro 'name' da querystring da 'url' fornecida
 * @param {string} name
 * @param {string} url
 * @returns {string|null}
 */
function getUrlParams(name, url) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default getUrlParams;
