import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { I18nProvider } from "./helpers/i18n/i18nProvider";
import { LayoutProvider, LayoutSplashScreen } from "./layout/core";
import { MasterInit } from "./layout/MasterInit";
import { AuthInit } from "./modules/auth";
import { TranslateProvider } from "./helpers/i18n/useTranslate";
import { AlertsProvider } from "./helpers/utils/CustomAlert";

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        {/* Provider do template */}
        <TranslateProvider>
          {/* Provider custom do t() */}
          <AlertsProvider>
            {/* Provider custom do useAlert() */}
            <LayoutProvider>
              {/* Provider do template */}
              <AuthInit>
                {/* Provider do template */}
                <Outlet /> {/* ??? */}
                <MasterInit /> {/* ??? */}
              </AuthInit>
            </LayoutProvider>
          </AlertsProvider>
        </TranslateProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
