import { CustomFileInput } from "../../../components/Form/CustomFileInput";
import React, { useEffect, useState } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { useParams } from "react-router-dom";
import { useOnesheetFileUploader } from "../../../helpers/Api/UploadOnesheetFileToBucket";
import { InputDefault } from "../../../components/Form/InputDefault";

/**
 * Formulário de hero (cabecalho) para Onesheet
 * @param {String} tabId id unique da secao
 * @param {object} data valores do elemento
 * @param {function} setData setter do dado (handleChange)
 * @param {array} fieldErrors erros de validacao
 *
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetHeroSection = ({ tabId, data, setData, fieldErrors }) => {
    const { onesheetId } = useParams();
    const { uploadOnesheetFileToBucket } = useOnesheetFileUploader();
    const { t } = useTranslate();
    const [mainBgImage, setMainBgImage] = useState([]);
    const [logoImage, setLogoImage] = useState([]);

    /**
     * Setter do background no data quando a mainBgImage muda (ou seja, quando alguem sobe algum arquivo lá). Fazemos
     * isso pra mudar a preview na hora que a pessoa subir uma imagem nova, e aí com isso ainda tem o bônus de que
     * quando formos fazer o save, o background já salva a imagem no bucket e retorna o path pra ser salvo no backend
     */
    useEffect(() => {
        if (mainBgImage.length > 0) {
            // remove os espaços que há no nome do arquivo, para não dar mais erro
            const removeSpaceFromFilename = mainBgImage[0].file.name.replace(
                /\s/g,
                ""
            );
            uploadOnesheetFileToBucket(
                mainBgImage[0].file,
                removeSpaceFromFilename,
                `${process.env.REACT_APP_ENVIRONMENT}/onesheet/${onesheetId}/${tabId}/background/`
            ).then((filename) => {
                setData(tabId, "background", filename);
            });
        }
    }, [mainBgImage]);

    /**
     * Setter do extraContent no data quando a logoImage muda (ou seja, quando alguem sobe algum arquivo lá). Fazemos
     * isso pra mudar a preview na hora que a pessoa subir uma imagem nova, e aí com isso ainda tem o bônus de que
     * quando formos fazer o save, o background já salva a imagem no bucket e retorna o path pra ser salvo no backend
     */
    useEffect(() => {
        if (logoImage.length > 0) {
            uploadOnesheetFileToBucket(
                logoImage[0].file,
                logoImage[0].file.name,
                `${process.env.REACT_APP_ENVIRONMENT}/onesheet/${onesheetId}/${tabId}/extraContent/`
            ).then((filename) => {
                setData(tabId, "extraContent", filename);
            });
        }
    }, [logoImage]);

    return (
        <>
            <div>
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data?.show}
                            onChange={(evt) =>
                                setData(tabId, "show", evt.target.checked)
                            }
                        />
                    </label>
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>

            <hr className="my-10" />

            <div className="row flex-column mt-10">
                <div className="mb-10">
                    <CustomFileInput
                        showImage
                        fieldName={"background"}
                        fieldLabel={t("FORMS.ONESHEET.MAIN-BG-IMAGE")}
                        inputClassName={"w-100"}
                        uploadedFileSrc={data?.background}
                        files={mainBgImage}
                        setFiles={setMainBgImage}
                    />
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.HERO-BG-IMAGE.HELP")}
                    </div>
                </div>
                <div className="mb-6">
                    <CustomFileInput
                        showImage
                        fieldName={"extraContent"}
                        fieldLabel={t("FORMS.ONESHEET.LOGO-IMAGE")}
                        inputClassName={"w-100"}
                        uploadedFileSrc={data?.extraContent}
                        files={logoImage}
                        setFiles={setLogoImage}
                    />
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.LOGO-IMAGE.HELP")}
                    </div>
                </div>
            </div>

            <hr className="my-10" />

            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.PAGE-TITLE")}
                    id="title"
                    type="text"
                    handleChange={(e) =>
                        setData(tabId, "title", e.target.value)
                    }
                    maxLength={100}
                    value={data?.title}
                    fieldError={fieldErrors}
                />
            </div>
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.PAGE-SUBTITLE")}
                    id="subtitle"
                    type="text"
                    handleChange={(e) =>
                        setData(tabId, "subtitle", e.target.value)
                    }
                    maxLength={100}
                    value={data?.subtitle}
                    fieldError={fieldErrors}
                />
            </div>
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.CTA-BTN-LINK")}
                    id="value"
                    type="text"
                    handleChange={(e) =>
                        setData(tabId, "value", e.target.value)
                    }
                    maxLength={255}
                    value={data?.value}
                    fieldError={fieldErrors}
                />
            </div>
            <div className="row mb-6">
                <InputDefault
                    label={t("FORMS.ONESHEET.CTA-BTN-TEXT")}
                    id="typeSelected"
                    type="text"
                    handleChange={(e) =>
                        setData(tabId, "typeSelected", e.target.value)
                    }
                    maxLength={20}
                    value={data?.typeSelected}
                    fieldError={fieldErrors}
                />
            </div>
        </>
    );
};

export { OnesheetHeroSection };
