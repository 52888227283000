import { PageTitle } from "../../../layout/core";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { PagedListComponent } from "../../../components/PagedListComponent";
import { CustomSelect } from "../../../components/Form/CustomSelect";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import clsx from "clsx";
import { useCreateButton } from "../../../helpers/subHeaderButtons/createButtonProvider";
import { useAuth } from "../../auth";
import { ProjectCardWidget } from "./components/ProjectCardWidget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faFilters } from "@fortawesome/pro-light-svg-icons";
import { Filter } from "../../../components/Filter";

const projectBreadCrumbs = [];
const initialArtist = {
    name: "",
    avatar: "",
};

const ProjectsList = observer(() => {
    const { GET } = useApi();
    const { t } = useTranslate();
    const { setHref } = useCreateButton();
    const { currentArtist } = useAuth();
    const [artists, setArtists] = useState([initialArtist]);
    const [projects, setProjects] = useState([]);
    const [filters, setFilters] = useState({});
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [periodFilterApplied, setPeriodFilterApplied] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const [fieldErrors, setFieldErrors] = useState("");
    const [loading, setLoading] = useState(true);

    /**
     * Carrega os artistas disponíveis para o filtro por artista
     * @returns {Promise<void>}
     */
    const getArtists = async () => {
        const artistsResponse = await GET("/core/artists");
        if (artistsResponse.status === 200) {
            let tempArtists = artistsResponse.data.map((artist) => ({
                label: artist.name,
                value: artist.id,
            }));
            setArtists(tempArtists);
            setLoading(false);
        }
    };

    const handleButtonClean = () => {
        setFilters({
            ...filters,
            release_date__range: null,
        });
        setShowPopover(false);
        setStartDate(null);
        setEndDate(null);
        setPeriodFilterApplied(false);
    };
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">
                {t("FORMS.GENERAL.OPTION-FILTER")}
            </Popover.Header>
            <Popover.Body>
                {!currentArtist && (
                    <>
                        <label className="form-label">
                            {t("APPS.CORE.ARTIST")}
                        </label>
                        <CustomSelect
                            placeholder={`${t("FILTER.FILTER-BY")} ${t(
                                "FORMS.CORE.PROJECT.ARTIST"
                            )}`}
                            onChange={(selected) =>
                                setFilters({
                                    ...filters,
                                    artist: selected?.value,
                                })
                            }
                            options={artists}
                            isClearable={true}
                            isSearchable={true}
                        />
                    </>
                )}
                <div className="d-flex gap-3 p-1">
                    <label>
                        <span className="form-label fw-bold">
                            {t("FORMS.GENERAL.START-DATE")}
                        </span>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setFieldErrors(""); // remove startDate de fieldErrors
                                setStartDate(date);
                            }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText={t("FORMS.GENERAL.START-DATE")}
                            dateFormat="dd/MM/yyyy"
                            locale={ptBR}
                            className={clsx(
                                "form-control form-control-sm form-control-white mt-2",
                                {
                                    "is-invalid":
                                        fieldErrors.includes("startDate"),
                                }
                            )}
                        />
                    </label>
                    <label>
                        <span className="form-label fw-bold">
                            {t("FORMS.GENERAL.FINAL-DATE")}
                        </span>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                                setFieldErrors(""); // remove endDate do fieldErrors
                                setEndDate(date);
                            }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            placeholderText={t("FORMS.GENERAL.FINAL-DATE")}
                            dateFormat="dd/MM/yyyy"
                            locale={ptBR}
                            className={clsx(
                                "form-control form-control-sm form-control-white mt-2",
                                {
                                    "is-invalid":
                                        fieldErrors.includes("endDate"),
                                }
                            )}
                        />
                    </label>
                </div>
                <div>
                    <div className="d-flex justify-content-between my-3 p-1 mb-2">
                        <button
                            className="btn btn-secondary btn-sm"
                            onClick={handleButtonClean}
                        >
                            {t("AUTH.GENERAL.FILTER-RESET")}
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                                // val não vai ser usado pra fazer o filtro, pois o filtro de data é feito de maneira diferente dos
                                // demais. Mas pra fixar o filtro por data no state filters, precisamos de algum valor lá.
                                // se não for inserida nenhuma data em algum campo, erro é retornado
                                if (startDate === null) {
                                    setFieldErrors("startDate");
                                    return;
                                }
                                if (endDate === null) {
                                    setFieldErrors("endDate");
                                    return;
                                }
                                if (endDate < startDate) {
                                    setFieldErrors("startDateendDate");
                                    return;
                                }
                                //Faz a conversão das datas startDate e endDate para AAAA-MM-DD
                                const formatDate = (date) => {
                                    let year = date.getFullYear();
                                    let month = String(
                                        date.getMonth() + 1
                                    ).padStart(2, "0");
                                    let day = String(date.getDate()).padStart(
                                        2,
                                        "0"
                                    );
                                    return `${year}-${month}-${day}`;
                                };

                                let stringedStartDate = formatDate(startDate);
                                let stringedEndDate = formatDate(endDate);

                                setFilters({
                                    ...filters,
                                    release_date__range: `${stringedStartDate}, ${stringedEndDate}`,
                                });

                                setPeriodFilterApplied(true);
                                setShowPopover(false);
                            }}
                        >
                            {t("AUTH.GENERAL.FILTER-APPLY")}
                        </button>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    );

    /**
     * Carrega dados necessários assim que a tela carrega
     */
    useEffect(() => {
        setHref("/projects/ed");
        getArtists();
    }, []);

    /**
     * Mantém o estado de projects inalterado, usa filteredProjects pra renderizar os resultados filtrados
     */
    useEffect(() => {
        setFilteredProjects(projects);
    }, [projects]);

    return (
        <div className="p-3">
            <div className="card-body">
                <div className="d-flex flex-column align-items-end mb-8">
                    <div className="d-flex justify-content-start gap-5">
                        <Filter 
                            showPopover={showPopover}
                            setShowPopover={setShowPopover}
                            popover={popover}
                        />

                        {/* O FILTRO POR STATUS NAO TA FUNFANDO PQ STATUS VIROU PROPERTY NO BACKEND. TODO VOLTAR COM ESSE FILTRO */}
                        {/*<CustomSelect*/}
                        {/*    placeholder={`${t("FILTER.FILTER-BY")} ${t(*/}
                        {/*        "FORMS.CORE.PROJECT.STATUS"*/}
                        {/*    )}`}*/}
                        {/*    options={[*/}
                        {/*        {*/}
                        {/*            value: "PRE",*/}
                        {/*            label: t("FORMS.CORE.PROJECT.STATUS.PRE"),*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            value: "REL",*/}
                        {/*            label: t("FORMS.CORE.PROJECT.STATUS.REL"),*/}
                        {/*        },*/}
                        {/*        {*/}
                        {/*            value: "POS",*/}
                        {/*            label: t("FORMS.CORE.PROJECT.STATUS.POS"),*/}
                        {/*        },*/}
                        {/*    ]}*/}
                        {/*    onChange={(selected) =>*/}
                        {/*        setFilters({ ...filters, status: selected?.value })*/}
                        {/*    }*/}
                        {/*    isClearable={true}*/}
                        {/*/>*/}
                    </div>
                </div>
                <PagedListComponent
                    data={filteredProjects}
                    setData={setFilteredProjects}
                    filters={filters}
                    baseUrl={"/core/releases"}
                    descriptionScreen={t("INTRO.PROJECTS")}
                >
                    <div className="row g-6 g-xl-9">
                        {filteredProjects.map((project, index) => {
                            return (
                                <div
                                    className="col-md-6 col-xl-4"
                                    key={`pr-4${index}`}
                                >
                                    <ProjectCardWidget
                                        link={`dt/${project.id}`}
                                        image={project.cover_500_thumb}
                                        title={project.title}
                                        date={project.release_date}
                                        progress={project.checklist_progress}
                                    />
                                    {/*<Card2*/}
                                    {/*    icon={project.cover_thumb}*/}
                                    {/*    iconPlaceholder={t("APPS.CORE.PROJECT")}*/}
                                    {/*    badgeColor="primary"*/}
                                    {/*    // status={project.get_status_display}*/}
                                    {/*    statusColor="primary"*/}
                                    {/*    title={project.title}*/}
                                    {/*    // description="CRM App application to HR efficiency"*/}
                                    {/*    date={project.release_date}*/}
                                    {/*    datePlaceholder={t(*/}
                                    {/*        "FORMS.CORE.PROJECT.RDATE"*/}
                                    {/*    )}*/}
                                    {/*    budget={project.get_media_display}*/}
                                    {/*    budgetPlaceholder={t(*/}
                                    {/*        "FORMS.CORE.PROJECT.MEDIA"*/}
                                    {/*    )}*/}
                                    {/*    // progress={50}*/}
                                    {/*    //   progressPlaceholder={}*/}
                                    {/*    // users={project.artist}*/}
                                    {/*    redirectTo={`dt/${project.id}`}*/}
                                    {/*    avatar={project.profile_picture}*/}
                                    {/*/>*/}
                                </div>
                            );
                        })}
                    </div>
                </PagedListComponent>
            </div>
        </div>
    );
});

const ProjectsListWrapper = observer(() => {
    const { t } = useTranslate();
    return (
        <>
            <PageTitle
                description={t("INTRO.PROJECTS")}
                breadcrumbs={projectBreadCrumbs}
            >
                APPS.CORE.PROJECTS
            </PageTitle>
            <ProjectsList />
        </>
    );
});

export { ProjectsListWrapper };
