import {
    faFacebook,
    faInstagram,
    faTiktok,
    faXTwitter,
    faYoutube,
    faSoundcloud,
    faSpotify,
    faDeezer,
    faAmazon,
} from "@fortawesome/free-brands-svg-icons";
import { faMusic, faHeadphones } from "@fortawesome/pro-solid-svg-icons";
import { TidalLogo } from "../../components/TidalLogo";
import { useTranslate } from "../i18n/useTranslate";

/**
 * Gera um objeto com informações de redes sociais formatadas.
 *
 * @param {Object} data - Objeto contendo os números e informações das redes sociais.
 * @param {string} color - Cor a ser usada para ícones específicos.
 * @returns {Object} Um objeto contendo informações formatadas das redes sociais.
 */
function SocialUtils({ data, color }) {
    const { t } = useTranslate();
    return {
        YT: [
            {
                index: 0,
                name: t("APPS.ONESHEET.WIZARD.YOUTUBE"),
                label: t("FORMS.ONESHEET.YOUTUBE-SUBS"),
                numbers: data?.youtube_fanbase,
                show_numbers: data?.show_youtube_fanbase,
                icon: faYoutube,
            },
            {
                index: 0,
                name: t("APPS.ONESHEET.WIZARD.YOUTUBE"),
                label: t("FORMS.ONESHEET.YOUTUBE-VIEWS"),
                numbers: data?.youtube_views,
                show_numbers: data?.show_youtube_views,
                icon: faYoutube,
            },
        ],
        SP: [
            {
                index: 5,
                name: t("APPS.ONESHEET.WIZARD.SPOTIFY"),
                label: t("FORMS.ONESHEET.SPOTIFY-FANBASE"),
                numbers: data?.spotify_fanbase,
                show_numbers: data?.show_spotify_fanbase,
                icon: faSpotify,
            },
            {
                index: 5,
                name: t("APPS.ONESHEET.WIZARD.SPOTIFY"),
                label: t("FORMS.ONESHEET.SPOTIFY-STREAMS"),
                numbers: data?.spotify_streams,
                show_numbers: data?.show_spotify_streams,
                icon: faSpotify,
            },
            {
                index: 5,
                name: t("APPS.ONESHEET.WIZARD.SPOTIFY"),
                label: t("FORMS.ONESHEET.SPOTIFY-PLAYLIST-REACH"),
                numbers: data?.spotify_playlist_reach,
                show_numbers: data?.show_spotify_playlist_reach,
                icon: faSpotify,
            },
        ],
        TT: [
            {
                index: 1,
                name: t("APPS.ONESHEET.WIZARD.TIKTOK"),
                label: t("FORMS.ONESHEET.TIKTOK-FANBASE"),
                numbers: data?.tiktok_fanbase,
                show_numbers: data?.show_tiktok_fanbase,
                icon: faTiktok,
            },
            {
                index: 1,
                name: t("APPS.ONESHEET.WIZARD.TIKTOK"),
                label: t("FORMS.ONESHEET.TIKTOK-LIKES"),
                numbers: data?.tiktok_likes,
                show_numbers: data?.show_tiktok_likes,
                icon: faTiktok,
            },
        ],
        IG: [
            {
                index: 2,
                name: t("APPS.ONESHEET.WIZARD.INSTAGRAM"),
                label: t("FORMS.ONESHEET.INSTAGRAM-FANBASE"),
                numbers: data?.instagram_fanbase,
                show_numbers: data?.show_instagram_fanbase,
                icon: faInstagram,
            },
            {
                index: 2,
                name: t("APPS.ONESHEET.WIZARD.INSTAGRAM"),
                label: t("FORMS.ONESHEET.INSTAGRAM-LIKES"),
                numbers: data?.instagram_likes,
                show_numbers: data?.show_instagram_likes,
                icon: faInstagram,
            },
        ],
        TW: [
            {
                index: 4,
                name: t("APPS.ONESHEET.WIZARD.TWITTER"),
                label: t("FORMS.ONESHEET.TWITTER-FANBASE"),
                numbers: data?.twitter_fanbase,
                show_numbers: data?.show_twitter_fanbase,
                icon: faXTwitter,
            },
            {
                index: 4,
                name: t("APPS.ONESHEET.WIZARD.TWITTER"),
                label: t("FORMS.ONESHEET.TWITTER-LIKES"),
                numbers: data?.twitter_likes,
                show_numbers: data?.show_twitter_likes,
                icon: faXTwitter,
            },
        ],
        FB: [
            {
                index: 3,
                name: t("APPS.ONESHEET.WIZARD.FACEBOOK"),
                label: t("APPS.ONESHEET.WIZARD.FOLLOWERS"),
                numbers: data?.facebook_numbers,
                show_numbers: data?.show_facebook_numbers,
                icon: faFacebook,
            },
        ],
        DZ: [
            {
                index: 6,
                name: t("APPS.ONESHEET.WIZARD.DEEZER"),
                label: t("APPS.ONESHEET.WIZARD.LISTENERS"),
                numbers: data?.deezer_numbers,
                show_numbers: data?.show_deezer_numbers,
                icon: faDeezer,
            },
        ],
        AM: [
            {
                index: 7,
                name: t("APPS.ONESHEET.WIZARD.AMAZON"),
                label: t("APPS.ONESHEET.WIZARD.LISTENERS"),
                numbers: data?.amazon_numbers,
                show_numbers: data?.show_amazon_numbers,
                icon: faAmazon,
            },
        ],
        AP: [
            {
                index: 8,
                name: t("APPS.ONESHEET.WIZARD.APPLE"),
                label: t("APPS.ONESHEET.WIZARD.LISTENERS"),
                numbers: data?.apple_numbers,
                show_numbers: data?.show_apple_numbers,
                icon: faMusic,
            },
        ],
        TD: [
            {
                index: 9,
                name: t("APPS.ONESHEET.WIZARD.TIDAL"),
                label: t("FORMS.ONESHEET.YOUTUBE-SUBS"),
                numbers: data?.tidal_numbers,
                show_numbers: data?.show_tidal_numbers,
                icon: <TidalLogo color={color} />,
            },
        ],
        SC: [
            {
                index: 10,
                name: t("APPS.ONESHEET.WIZARD.SOUNDCLOUD"),
                label: t("FORMS.ONESHEET.YOUTUBE-SUBS"),
                numbers: data?.soundcloud_numbers,
                show_numbers: data?.show_soundcloud_numbers,
                icon: faSoundcloud,
            },
        ],
        TP: [
            {
                index: 11,
                name: t("FORMS.ONESHEET.SHOW-TOTAL-PLAYS"),
                label: t("FORMS.ONESHEET.SHOW-TOTAL-PLAYS"),
                numbers: data?.total_numbers,
                show_numbers: data?.show_total_numbers,
                icon: faHeadphones,
            },
        ],
    };
}

export { SocialUtils };
