import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

export const HeaderBackInvSection = ({ onClick, title }) => {
    const { mode } = useThemeMode();
    return (
        <>
            <div
                className="cursor-pointer p-3 rounded"
                onClick={onClick}
                role="button"
                style={{ backgroundColor: mode === 'dark' ? "#1b1b29" : "#f1f3f5" }}
            >
                <div className="d-flex gap-5 align-items-center">
                    <FontAwesomeIcon icon={faArrowLeft} />

                    <h6 className="m-0">{title}</h6>
                </div>
            </div>
            <hr className="mb-5" />
        </>
    );
};
