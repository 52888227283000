import React, { useEffect, useRef, useState } from "react";
import { InvInputDefault } from "../../../components/Form/InvInputDefault";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { CustomFileInput } from "../../../components/Form/CustomFileInput";
import { makeId } from "onipkg";
import { useParams } from "react-router-dom";
import { useOnesheetFileUploader } from "../../../helpers/Api/UploadOnesheetFileToBucket";
import { InputDefault } from "../../../components/Form/InputDefault";

/**
 * Formulário de galeria de imagens para Onesheet
 * @param {String} tabId id unique da secao
 * @param {object} data element.value (no caso, [{"id":"imagesGrid-abcd","title":"","link":"","image":""}])
 * @param {function} setData setter do dado (handleChange)
 * @param {array} fieldErrors erros de validacao
 *
 * @returns {JSX.Element}
 * @constructor
 */
const OneSheetImageGallerySection = ({ tabId, data, setData, fieldErrors }) => {
    const { t } = useTranslate();
    const { onesheetId } = useParams();
    const { uploadOnesheetFileToBucket } = useOnesheetFileUploader();
    const [galleryImg1, setGalleryImg1] = useState([]);
    const [galleryImg2, setGalleryImg2] = useState([]);
    const [galleryImg3, setGalleryImg3] = useState([]);
    const [galleryImg4, setGalleryImg4] = useState([]);
    const [galleryImg5, setGalleryImg5] = useState([]);
    const [galleryImg6, setGalleryImg6] = useState([]);
    const [galleryImg7, setGalleryImg7] = useState([]);
    const [galleryImg8, setGalleryImg8] = useState([]);
    const [galleryImg9, setGalleryImg9] = useState([]);
    const [galleryImg10, setGalleryImg10] = useState([]);
    const [galleryImg11, setGalleryImg11] = useState([]);
    const [galleryImg12, setGalleryImg12] = useState([]);
    const galleryStates = [
        { file: galleryImg1, index: 0 },
        { file: galleryImg2, index: 1 },
        { file: galleryImg3, index: 2 },
        { file: galleryImg4, index: 3 },
        { file: galleryImg5, index: 4 },
        { file: galleryImg6, index: 5 },
        { file: galleryImg7, index: 6 },
        { file: galleryImg8, index: 7 },
        { file: galleryImg9, index: 8 },
        { file: galleryImg10, index: 9 },
        { file: galleryImg11, index: 10 },
        { file: galleryImg12, index: 11 },
    ];
    const gallerySetterStates = [
        setGalleryImg1,
        setGalleryImg2,
        setGalleryImg3,
        setGalleryImg4,
        setGalleryImg5,
        setGalleryImg6,
        setGalleryImg7,
        setGalleryImg8,
        setGalleryImg9,
        setGalleryImg10,
        setGalleryImg11,
        setGalleryImg12,
    ];

    /**
     * Função que evita duplicação de código para alterar o value do elemento de galeria, que é um array
     * @param {number} index índice da imagem no array value
     * @param {String} fieldName nome do campo que vai ser alterado (pode ser link, image ou title)
     * @param {*} newValue valor a ser inserido no campo designado
     */
    const updateGalleryElementValue = (index, fieldName, newValue) => {
        let dataCopy = [...data.value];
        let updatedImageObject;
        // Caso ainda nao haja uma imagem naquela posicao do array de imagens, adicionaremos
        if (dataCopy[index]) updatedImageObject = dataCopy[index];
        else {
            updatedImageObject = {
                id: `imagesGrid-${makeId(7)}`,
                title: "",
                link: "",
                image: "",
            };
            dataCopy.push(updatedImageObject);
            index = dataCopy.indexOf(updatedImageObject);
        }
        updatedImageObject[fieldName] = newValue;
        dataCopy[index] = updatedImageObject;
        setData(tabId, "value", dataCopy);
    };

    /**
     * Remove um objeto de imagem do array value do elemento
     * @param {number} index índice do elemento que deve ser removido
     */
    const removeImage = (index) => {
        let dataCopy = [...data.value];
        // Caso haja uma imagem naquela posicao do array de imagens, retiraremos
        if (dataCopy[index]) {
            dataCopy.splice(index, 1);
            setData(tabId, "value", dataCopy);
        }
    };

    /**
     * Altera a preview quando uma imagem é alterada. Como temos várias imagens de galeria, nao tem como saber qual
     * especificamente que foi alterada. Por isso, atualizamos todas quando uma muda. Não é a melhor abordagem, mas
     * como são poucas imagens (apenas 12), não deve apresentar problemas.
     */
    useEffect(() => {
        galleryStates.forEach((stateObj) => {
            const { file, index } = stateObj;
            // Se houver alguma imagem, adiciona ela
            if (file.length > 0) {
                uploadOnesheetFileToBucket(
                    file[0].file,
                    file[0].file.name,
                    `${process.env.REACT_APP_ENVIRONMENT}/onesheet/${onesheetId}/${tabId}/extraContent/`
                ).then((filename) => {
                    updateGalleryElementValue(index, "image", filename);
                });
            }
        });
    }, [
        galleryImg1,
        galleryImg2,
        galleryImg3,
        galleryImg4,
        galleryImg5,
        galleryImg6,
        galleryImg7,
        galleryImg8,
        galleryImg9,
        galleryImg10,
        galleryImg11,
        galleryImg12,
    ]);

    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data.show}
                            onChange={(evt) =>
                                setData(tabId, "show", evt.target.checked)
                            }
                        />
                    </label>
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>

            {galleryStates.map((galleryImageStateObj) => {
                let index = galleryImageStateObj.index;
                return (
                    <div>
                        <hr className="my-10" />
                        <div key={index} className="mb-10">
                            <CustomFileInput
                                fieldName={`gallery_img_${index}`}
                                removable={() => removeImage(index)}
                                fieldLabel={`${t("GENERAL.PHOTO")} #${
                                    index + 1
                                }`}
                                inputClassName={"w-100"}
                                uploadedFileSrc={data.value[index]?.image}
                                files={galleryImageStateObj.file}
                                setFiles={gallerySetterStates[index]}
                            />
                            <div className="form-text text-muted mb-2">
                                {t("FORMS.ONESHEET.GALLERY.IMAGE.HELP")}
                            </div>
                            <InputDefault
                                className="mt-6"
                                label={t(
                                    "FORMS.ONESHEET.GALLERY-IMAGE-LINK-INPUT"
                                )}
                                id={`input-${index}`}
                                type="url"
                                handleChange={(e) =>
                                    updateGalleryElementValue(
                                        index,
                                        "link",
                                        e.target.value
                                    )
                                }
                                value={data.value[index]?.link}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export { OneSheetImageGallerySection };
