import { ProjectsListWrapper } from "../../modules/catalog/projects/ProjectsListWrapper";
import { Route, Routes } from "react-router-dom";
import { registerOptionalParams } from "../OptionalParamRoute";
import { lazy } from "react";
import SuspensedView from "../SuspensedView";
import LabelForm from "../../modules/catalog/labels/form";

const CatalogRoutes = () => {
    const ProjectDetails = lazy(() =>
        import("../../modules/catalog/projects/ProjectDetails")
    );
    const ProjectForm = lazy(() =>
        import("../../modules/catalog/projects/ProjectForm")
    );

    return (
        <Routes>
            <Route path="" element={<ProjectsListWrapper />} />
            <Route
                path="dt/:projectId/*"
                element={
                    <SuspensedView>
                        <ProjectDetails />
                    </SuspensedView>
                }
            />
            {/* O react-router-dom v6 não aceita parametros opcionais (id, por ex). Por isso foi usada a funcao
         registerOptionalParams, achada no stackOverflow, pra não ter que poluir o código adicionando uma rota
         com parametro e a outra sem que redirecionam pro mesmo componente */}
            {registerOptionalParams(
                "ed/:projectId?",
                <SuspensedView>
                    <ProjectForm />
                </SuspensedView>
            )}
            {registerOptionalParams(
                ":projectId/label/:labelId?",
                <SuspensedView>
                    <LabelForm />
                </SuspensedView>
            )}
        </Routes>
    );
};

export { CatalogRoutes };
