import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { CompleteSignupFormContext } from "../CompleteSignupFormContext";
import RecorderForm from "../formComponents/NewRecorderForm";
import ArtistSearchOnDsps from "../formComponents/ArtistSearchOnDsps";

/**
 * Renderiza o passo de busca de artista no Spotify.
 * @type {React.FunctionComponent<object>}
 */
const SearchOnSpotifyStep = observer(() => {
  const form = useContext(CompleteSignupFormContext);

  return (
    <>
      <div className="w-100">
        {form.data.userType === form.getRecorderType() && (
          <div className="mb-10">
            <RecorderForm
              form={form}
              handleChange={(e) => form.setForm(e.target)}
            />
          </div>
        )}
        <ArtistSearchOnDsps form={form.data} handleChange={form.setForm} />
      </div>
    </>
  );
});

export { SearchOnSpotifyStep };
