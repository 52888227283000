/**
 * @description
 * a doc desse componente foi escrita diferente pq tava quebrando em arquivo tsx
 * 
 * 
 * text - string - texto que será inserido no título
 * classname [opcional] - string - classe css para utilizar no titulo
 * @returns 
 */
export const CardTitle = ({ text, className }) => {
    return (
        <span className={`text-xl font-bold text-white ${className || ''}`}>{text}</span>
    );
};
