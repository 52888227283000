import React from "react";
import { Container, Grid } from "@nextui-org/react";
import { Title } from "../Components/Title";
import { useTranslate } from "../../../helpers/i18n/useTranslate";

/**
 * Componente de visualização do Spotify Embed para a seção de pré-visualização do Onesheet.
 * @module OnesheetSpotifyEmbedPreview
 * @param {Object} data - Dados do elemento.
 * @param {string} data.id - ID do elemento Container.
 * @param {string} data.background - Cor de fundo do Container.
 * @param {string} data.fontColor - Cor da fonte do título.
 * @returns {JSX.Element} Componente de visualização do Spotify Embed.
 */
export const OnesheetSpotifyEmbedPreview = ({ data }) => {
    const { t } = useTranslate();
    
    let url;
    try {
        url = new URL(data.value);
    } catch (error) {
        console.error(error);
    }

    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "9rem 6rem",
                bc: data?.background,
            }}
        >
            <Grid.Container gap={2} css={{ mw: "1300px", m: "0 auto" }}>
                <Grid xs={12} direction="column" css={{ w: "100%" }}>
                    <Title
                        color={data?.fontColor}
                        text={t("APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.SPOTIFY")}
                    />
                    <iframe
                        className="mt-5"
                        style={{ border: 0 }}
                        height="375"
                        title="spotify"
                        src={`https://open.spotify.com/embed${url?.pathname}/?utm_source=generator&theme=0`}
                        allowFullScreen
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="eager"
                    />
                </Grid>
            </Grid.Container>
        </Container>
    );
};
