import React, { useState, useEffect } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { InputDefault } from "../../../components/Form/InputDefault";
import { makeId } from "onipkg";

/**
 * Componente Links
 * Este componente representa uma seção de formulário para gerenciar links externos na Onesheet.
 *
 * @param {string} tabId - O ID da aba correspondente à seção.
 * @param {object} data - Os dados da seção que incluem os links externos.
 * @param {function} setData - Função para atualizar os dados da seção.
 * @returns {JSX.Element} - Retorna um conjunto de inputs para adicionar, editar e remover links externos.
 */
export const OnesheetExternalLinksSection = ({ tabId, data, setData }) => {
    const { t } = useTranslate();
    const initialLinksValues = {
        id: `link-${makeId(7)}`,
        link: "",
        title: "",
    };
    const [links, setLinks] = useState(data.value);

    /**
     * Adiciona um novo link à lista de links.
     *
     * @param {Event} e - O evento de clique que acionou a função.
     * @returns {void}
     */
    const handleAddNewLink = (e) => {
        e.preventDefault();
        setLinks((prevState) => [...prevState, initialLinksValues]);
    };

    /**
     * Remove um link da lista de links externos.
     *
     * @param {Event} e - O evento de clique.
     * @param {number} index - O índice do link a ser removido.
     */
    const removeLink = (e, index) => {
        e.preventDefault();
        let allLinks = [...links];
        allLinks.splice(index, 1);
        setLinks(allLinks);
    };

    /**
     * Manipula a alteração de um campo específico em um link externo.
     *
     * @param {Event} e - O evento de alteração.
     * @param {number} index - O índice do link no array.
     * @param {string} fieldName - O nome do campo a ser alterado.
     * @returns {Promise<void>} - Uma promessa vazia.
     */
    const handleChange = async (e, index, fieldName) => {
        const { value } = e.target;

        // Cria uma cópia do array de links
        const updatedLinks = [...links];

        // Verifica se o link atual já existe ou se é um novo
        const isExistingLink = updatedLinks[index];

        if (isExistingLink) {
            // Atualiza o valor do campo específico no objeto de índice 'index'
            updatedLinks[index] = {
                ...updatedLinks[index],
                [fieldName]: value,
            };
        } else {
            // Cria um novo link com os valores padrão
            const newLink = {
                id: `link-${makeId(7)}`,
                link: "",
                title: "",
            };

            // Atualiza o valor do campo específico do novo link
            newLink[fieldName] = value;

            // Adiciona o novo link ao array
            updatedLinks.push(newLink);
        }

        // Atualiza o estado 'links' com o array modificado
        setLinks(updatedLinks);
    };

    // Atualiza o estado 'links' com os dados da seção
    useEffect(() => {
        setData(tabId, "value", links);
    }, [links]);

    return (
        <>
            <div className="row flex-column mb-6">
                <label className="col-lg-4 col-form-label fs-6">
                    {t("FORMS.ONESHEET.SHOW-SECTION")}
                </label>
                <div className="col-12 fv-row">
                    <label className="form-check form-check-custom form-check-solid form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            name="layout-builder[layout][header][fixed][desktop]"
                            checked={data?.show}
                            onChange={(evt) =>
                                setData(tabId, "show", evt.target.checked)
                            }
                        />
                    </label>
                    <div className="form-text text-muted">
                        {t("FORMS.ONESHEET.SHOW-SECTION.HELP")}
                    </div>
                </div>
            </div>

            {links.map((link, index) => (
                <div className="row mb-6" key={index}>
                    <div className="mb-6 mx-0">
                        <InputDefault
                            type="url"
                            label={`Link #${index + 1}`}
                            id="link"
                            handleChange={(e) => handleChange(e, index, "link")}
                            value={link.link}
                        />
                    </div>
                    <div className="mb-6 mx-0">
                        <InputDefault
                            type="text"
                            label={`Title #${index + 1}`}
                            id="title"
                            handleChange={(e) =>
                                handleChange(e, index, "title")
                            }
                            value={link.title}
                        />
                    </div>
                    <button
                        className="btn btn-sm bg-danger-500 hover:bg-danger-600 ow mb-6 mx-0 font-bold"
                        onClick={(e) => removeLink(e, index)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} className="mr-3" />
                        Remover Link
                    </button>
                </div>
            ))}

            <button
                className="btn btn-primary"
                onClick={(e) => handleAddNewLink(e)}
            >
                {" "}
                <FontAwesomeIcon icon={faPlus} className="mr-3" />
                {t("FORMS.ONESHEET.SECTIONS.onipages_external-links.NEW")}
            </button>
        </>
    );
};
