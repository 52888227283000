import {createContext, useContext} from "react";
import {useIntl} from "react-intl";

const I18nContext = createContext({
    t: (message) => {
    }
})

const useTranslate = () => {
    return useContext(I18nContext);
}

const TranslateProvider = ({children}) => {
    const intl = useIntl();

    const t = (message) => {
        return intl.formatMessage({id: message})
    }
    return (
        <I18nContext.Provider value={{t}}>{children}</I18nContext.Provider>
    );
}

export {TranslateProvider, useTranslate};
