import React from "react";
import { Title } from "../Components/Title";
import { Container, Grid } from "@nextui-org/react";
import styles from "./OnesheetFilesPreview.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-light-svg-icons";

/**
 * Componente de visualização do Spotify Embed para a seção de pré-visualização do Onesheet.
 * @module OnesheetSpotifyEmbedPreview
 * @param {Object} data - Dados do elemento.
 * @param {string} data.id - ID do elemento.
 * @param {string} data.background - Cor de fundo.
 * @param {string} data.fontColor - Cor da fonte.
 * @param {array} data.value - Valor com os arquivos.
 * @returns {JSX.Element} Componente de visualização do Spotify Embed.
 */
export const OnesheetFilesPreview = ({ data }) => {
    /**
     * Escurece uma cor hexadecimal dinamicamente.
     *
     * @param {string} hex - O código hexadecimal da cor a ser escurecida.
     * @param {number} percentage - O percentual pelo qual a cor deve ser escurecida (0-100).
     * @returns {string} - O novo código hexadecimal da cor escurecida.
     * @throws {Error} - Lança um erro se o código hexadecimal for inválido.
     */
    function darkenColor(hex, percentage) {
        // Validating the hexadecimal code
        hex = hex.replace(/^#/, "");
        if (hex.length !== 6) {
            throw new Error("Invalid hexadecimal code");
        }

        // Converting the hexadecimal code to RGB values
        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);

        // Calculating the percentage to darken the color
        let darken = percentage / 100;

        // Applying darkening to the RGB components
        r = Math.round(r * (1 - darken));
        g = Math.round(g * (1 - darken));
        b = Math.round(b * (1 - darken));

        // Ensuring RGB values are within the valid range (0-255)
        r = Math.min(255, Math.max(0, r));
        g = Math.min(255, Math.max(0, g));
        b = Math.min(255, Math.max(0, b));

        // Converting RGB values back to hexadecimal code
        const componentR = r.toString(16).padStart(2, "0");
        const componentG = g.toString(16).padStart(2, "0");
        const componentB = b.toString(16).padStart(2, "0");

        return `#${componentR}${componentG}${componentB}`;
    }

    return (
        <Container
            id={data.id}
            xl
            css={{
                p: "8rem 5rem",
                bc: data?.background,
            }}
        >
            <Grid.Container gap={2} css={{ mw: "1300px", m: "0 auto" }}>
                <Grid xs={12} direction="column" css={{ w: "100%" }}>
                    <div className={styles.titleSection}>
                        <Title color={data?.fontColor} text="Arquivos" />
                    </div>
                    <Container
                        css={{
                            p: 0,
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "1rem",
                            flexWrap: "nowrap",
                        }}
                    >
                        {data?.value.map((file, index) => {
                            return (
                                <>
                                    {file.file || file.title ? (
                                        <div
                                            className={styles.fileWrapper}
                                            key={index}
                                            style={{
                                                backgroundColor:
                                                    data.secondary_color,
                                            }}
                                        >
                                            <a
                                                download
                                                href={file.file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={`${styles.btn}`}
                                                style={{
                                                    color: data.fontColor,
                                                }}
                                            >
                                                <div
                                                    className={
                                                        styles.iconWrapper
                                                    }
                                                    style={{
                                                        backgroundColor: darkenColor(data.secondary_color, 10),
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faDownload}
                                                        className={styles.icon}
                                                    />
                                                </div>
                                                <span className={styles.title}>
                                                    {file.title}
                                                </span>
                                            </a>
                                        </div>
                                    ) : null}
                                </>
                            );
                        })}
                    </Container>
                </Grid>
            </Grid.Container>
        </Container>
    );
};
