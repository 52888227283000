import {PageTitle} from "../../../layout/core";
import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPencil,
    faCheck,
    faXmark,
    faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { useAlert } from "../../../helpers/utils/CustomAlert";
import { PagedListComponent } from "../../../components/PagedListComponent";
import { useCreateButton } from "../../../helpers/subHeaderButtons/createButtonProvider";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useAuth } from "../../auth";

const breadcrumbs = [];


/**
 * @description
 * Componente que exibe a lista de parceiros e permite ações como busca, edição e exclusão.
 * 
 * @returns {JSX.Element} Componente de lista de parceiros.
 */
const PartnersList = observer(() => {
    const { t } = useTranslate();
    const { dialogs } = useAlert();
    const { currentClient, currentArtist } = useAuth();
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const {setHref} = useCreateButton();
    setHref("/core/partners/ed");

    const [searchTerm, setSearchTerm] = useState("");

    const handleDeletePartner = (id, deleteConfirmString) => {
        // no front não tem campo de nome, e para apagar é preciso digitar o nome do parceiro, 
        // mas em alguns casos, alguns parceiros não possuem nome
        // então é passado um deleteConfirmString default para resolver isso por enquanto
        if (deleteConfirmString === '') {
            deleteConfirmString = 'Confirmar'
        }

        dialogs.deleteDoubleConfirm(
            `/core/partners/${id}`,
            deleteConfirmString,
            t("APPS.CORE.PARTNER.DELETED"),
            () => window.location.reload()
        );
    };
    return (
        <>
            {/* Enquanto os dados não carregam, aparece esse loading spinner no meio da tela */}
            {loading ? (
                <LoadingSpinner size={"2xl"}/>
            ) : (
                <div className="card mb-5 mb-xl-10">
                    <div className="card-body pt-9 pb-0">
                        <div className="d-flex flex-column mb-3">
                            {/* CABEÇALHO */}
                            <div className="me-4">
                                <input
                                    type="text"
                                    className="search-input form-control form-control-solid ps-10 mb-3"
                                    name="search"
                                    placeholder={`${t("GENERAL.SEARCH-BY")} ${t(
                                        "FORMS.CORE.PARTNER.NAME"
                                    )}...`}
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        //pega o valor que está sendo digitado no campo de busca e envia para o backend
                                        setFilters({...filters, search: e.target.value})
                                    }
                                    }
                                />
                            </div>
                            {/* CONTEÚDO */}
                            <PagedListComponent
                                searchTerm
                                data={partners}
                                setData={setPartners}
                                filters={filters}
                                // Se busca tiver sendo feita, é retornado o nome buscado, se não, retorna lista com todos os parceiros
                                baseUrl={`/core/partners`}
                            >
                                <div className="g-6 g-xl-9">
                                    <div className="table-responsive">
                                        <table
                                            className="table table-row-dashed table-striped table-row-gray-300 gy-7 gs-5">
                                            <thead>
                                                <tr className="fw-bold text-muted">
                                                    <th>
                                                        {t(
                                                            "FORMS.CORE.PARTNER.NAME"
                                                        )}
                                                    </th>
                                                    <th>
                                                        {t(
                                                            "FORMS.CORE.PARTNER.EMAIL"
                                                        )}
                                                    </th>
                                                    {!currentArtist && (
                                                        <th className="text-center">
                                                            {t(
                                                                "FORMS.CORE.PARTNER.IS-GLOBAL"
                                                            )}
                                                        </th>
                                                    )}
                                                    <th>
                                                        {t(
                                                            "FORMS.CORE.PARTNER.ROLE-TYPE"
                                                        )}
                                                    </th>
                                                    <th className="min-w-100px text-end">
                                                        {t("GENERAL.ACTIONS")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {partners.map(
                                                    (partner, index) => (
                                                        <tr key={`pr-${index}`}>
                                                            <td className="text-dark fw-bold text-hover-primary fs-6">
                                                                {partner.name}
                                                            </td>
                                                            <td>
                                                                {partner.email}
                                                            </td>
                                                            {!currentArtist && (
                                                                <td className="text-center">
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            partner.is_global
                                                                                ? faCheck
                                                                                : faXmark
                                                                        }
                                                                    />
                                                                </td>
                                                            )}
                                                            <td>
                                                                {
                                                                    partner.get_allowed_actions_display
                                                                }
                                                            </td>
                                                            <td className="d-flex justify-content-end flex-shrink-0">
                                                                {currentClient?.canUpdate && (
                                                                    <Link
                                                                        to={`ed/${partner.id}`}
                                                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faPencil
                                                                            }
                                                                        />
                                                                    </Link>
                                                                )}
                                                                {currentClient?.canDelete && (
                                                                    <button
                                                                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
                                                                        onClick={() =>
                                                                            handleDeletePartner(
                                                                                partner.id,
                                                                                partner.name
                                                                            )
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faTrashAlt
                                                                            }
                                                                        />
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </PagedListComponent>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

const PartnersListWrapper = observer(() => {
    const {t} = useTranslate();
    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs}>
                {t("APPS.CORE.PARTNERS")}
            </PageTitle>
            <PartnersList/>
        </>
    );
});

export {PartnersListWrapper};
